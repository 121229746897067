[data-theme-color="color_1"] {
    --primary: #EE3131;
    --secondary: #FF9446;
    --primary-hover: #de2929;
    --primary-dark: #DB2020;
    --rgba-primary-1: rgba(238, 49, 49, 0.1);
    --rgba-primary-2: rgba(238, 49, 49, 0.2);
    --rgba-primary-3: rgba(238, 49, 49, 0.3);
    --rgba-primary-4: rgba(238, 49, 49, 0.4);
    --rgba-primary-5: rgba(238, 49, 49, 0.5);
    --rgba-primary-6: rgba(238, 49, 49, 0.6);
    --rgba-primary-7: rgba(238, 49, 49, 0.7);
    --rgba-primary-8: rgba(238, 49, 49, 0.8);
    --rgba-primary-9: rgba(238, 49, 49, 0.9);
    --title: #000;
}

[data-theme-color="color_2"] {
    --primary: #0D3DE5;
    --secondary: #FF9446;
    --primary-hover: #0c38d2;
    --primary-dark: #0D3D90;
    --rgba-primary-1: rgba(13, 61, 229, 0.1);
    --rgba-primary-2: rgba(13, 61, 229, 0.2);
    --rgba-primary-3: rgba(13, 61, 229, 0.3);
    --rgba-primary-4: rgba(13, 61, 229, 0.4);
    --rgba-primary-5: rgba(13, 61, 229, 0.5);
    --rgba-primary-6: rgba(13, 61, 229, 0.6);
    --rgba-primary-7: rgba(13, 61, 229, 0.7);
    --rgba-primary-8: rgba(13, 61, 229, 0.8);
    --rgba-primary-9: rgba(13, 61, 229, 0.9);
    --title: #000;
}

[data-theme-color="color_3"] {
    --primary: #2a7e19;
    --secondary: #323232;
    --primary-hover: #227d10;
    --primary-dark: #226b13;
    --rgba-primary-1: rgba(42, 126, 25, 0.1);
    --rgba-primary-2: rgba(42, 126, 25, 0.2);
    --rgba-primary-3: rgba(42, 126, 25, 0.3);
    --rgba-primary-4: rgba(42, 126, 25, 0.4);
    --rgba-primary-5: rgba(42, 126, 25, 0.5);
    --rgba-primary-6: rgba(42, 126, 25, 0.6);
    --rgba-primary-7: rgba(42, 126, 25, 0.7);
    --rgba-primary-8: rgba(42, 126, 25, 0.8);
    --rgba-primary-9: rgba(42, 126, 25, 0.9);
    --title: #212529;
}

[data-theme-color="color_4"] {
    --primary: #a10559;
    --secondary: #212529;
    --primary-hover: #bb2375;
    --primary-dark: #9c1a60;
    --rgba-primary-1: rgba(161, 5, 89, 0.1);
    --rgba-primary-2: rgba(161, 5, 89, 0.2);
    --rgba-primary-3: rgba(161, 5, 89, 0.3);
    --rgba-primary-4: rgba(161, 5, 89, 0.4);
    --rgba-primary-5: rgba(161, 5, 89, 0.5);
    --rgba-primary-6: rgba(161, 5, 89, 0.6);
    --rgba-primary-7: rgba(161, 5, 89, 0.7);
    --rgba-primary-8: rgba(161, 5, 89, 0.8);
    --rgba-primary-9: rgba(161, 5, 89, 0.9);
    --title: #212529;
}

[data-theme-color="color_5"] {
    --primary: #f77900;
    --secondary: #212529;
    --primary-hover: #e2740b;
    --primary-dark: #cc6c12;
    --rgba-primary-1: rgba(226, 116, 11, 0.1);
    --rgba-primary-2: rgba(226, 116, 11, 0.2);
    --rgba-primary-3: rgba(226, 116, 11, 0.3);
    --rgba-primary-4: rgba(226, 116, 11, 0.4);
    --rgba-primary-5: rgba(226, 116, 11, 0.5);
    --rgba-primary-6: rgba(226, 116, 11, 0.6);
    --rgba-primary-7: rgba(226, 116, 11, 0.7);
    --rgba-primary-8: rgba(226, 116, 11, 0.8);
    --rgba-primary-9: rgba(226, 116, 11, 0.9);
    --title: #212529;
}

[data-theme-color="color_6"] {
    --primary: #D7B65D;
    --secondary: #212529;
    --primary-hover: #cca744;
    --primary-dark: #9c8033;
    --rgba-primary-1: rgba(215, 182, 93, 0.1);
    --rgba-primary-2: rgba(215, 182, 93, 0.2);
    --rgba-primary-3: rgba(215, 182, 93, 0.3);
    --rgba-primary-4: rgba(215, 182, 93, 0.4);
    --rgba-primary-5: rgba(215, 182, 93, 0.5);
    --rgba-primary-6: rgba(215, 182, 93, 0.6);
    --rgba-primary-7: rgba(215, 182, 93, 0.7);
    --rgba-primary-8: rgba(215, 182, 93, 0.8);
    --rgba-primary-9: rgba(215, 182, 93, 0.9);
    --title: #212529;
}

[data-theme-color="color_7"] {
    --primary: #22abc3;
    --secondary: #212529;
    --primary-hover: #129eb7;
    --primary-dark: #21889a;
    --rgba-primary-1: rgba(34, 171, 195, 0.1);
    --rgba-primary-2: rgba(34, 171, 195, 0.2);
    --rgba-primary-3: rgba(34, 171, 195, 0.3);
    --rgba-primary-4: rgba(34, 171, 195, 0.4);
    --rgba-primary-5: rgba(34, 171, 195, 0.5);
    --rgba-primary-6: rgba(34, 171, 195, 0.6);
    --rgba-primary-7: rgba(34, 171, 195, 0.7);
    --rgba-primary-8: rgba(34, 171, 195, 0.8);
    --rgba-primary-9: rgba(34, 171, 195, 0.9);
    --title: #212529;
}

[data-theme-color="color_8"] {
    --primary: #8669d4;
    --secondary: #212529;
    --primary-hover: #7559be;
    --primary-dark: #533a97;
    --rgba-primary-1: rgba(134, 105, 212, 0.1);
    --rgba-primary-2: rgba(134, 105, 212, 0.2);
    --rgba-primary-3: rgba(134, 105, 212, 0.3);
    --rgba-primary-4: rgba(134, 105, 212, 0.4);
    --rgba-primary-5: rgba(134, 105, 212, 0.5);
    --rgba-primary-6: rgba(134, 105, 212, 0.6);
    --rgba-primary-7: rgba(134, 105, 212, 0.7);
    --rgba-primary-8: rgba(134, 105, 212, 0.8);
    --rgba-primary-9: rgba(134, 105, 212, 0.9);
    --title: #212529;
}