// video-bx
.video-bx{
	position: relative;
	&.style-1{
		position: relative;
		.video-btn {
			.popup-youtube{
				font-size:18px;
				color:#575757;
				display: flex;
				text-decoration: underline;
				align-items: center;
			}
			.popup-youtube .icon{
				position:relative;
				height: 66px;
				width: 66px;
				line-height: 66px;
				text-align: center;
				border-radius: 66px;
				background-color: var(--primary);
				display: block;
				color: $white;
				font-size: 22px;
				margin-right: 30px;
				transition: all 0.5s;
				box-shadow: 0 0 0 8px rgba(#fff,0.5);
				@include respond('phone'){
					height: 50px;
					width: 50px;
					line-height: 50px;
					font-size: 14px;
				}
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid var(--primary);
					-webkit-border-radius: 50%;
					-khtml-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					border-radius: 100%;
					animation: animationSignal1;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal1;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid var(--primary);
					-webkit-border-radius: 100%;
					-khtml-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border-radius: 100%;
					animation: animationSignal2;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal2;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
			}
		}
	}
	&.style-2{
		height:500px;
		&.large{
			height:615px;
			border-radius:var(--border-radius-base);
			overflow:hidden;
			img{
				width:100%;
				height:100%;
				object-fit:cover;
			}
		}
		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			&.sm{
				.popup-youtube{
					height:72px;
					width:72px;
					min-width:72px;
					line-height:72px;
					font-size: 22px;
				}
			}
			.popup-youtube{
				display:inline-block;
				height:100px;
				width:100px;
				min-width:100px;
				line-height:100px;
				font-size: 30px;
				border-radius:100px;
				background-color:var(--primary);
				color:#fff;
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid var(--primary);
					-webkit-border-radius: 50%;
					-khtml-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					-o-border-radius: 50%;
					border-radius: 100%;
					animation: animationSignal1;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal1;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
				&:after{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					border: 1px solid var(--primary);
					-webkit-border-radius: 100%;
					-khtml-border-radius: 100%;
					-moz-border-radius: 100%;
					-ms-border-radius: 100%;
					-o-border-radius: 100%;
					border-radius: 100%;
					animation: animationSignal2;
					animation-iteration-count: infinite;
					animation-duration: 3s;
					-webkit-animation: animationSignal2;
					-webkit-animation-iteration-count: infinite;
					-webkit-animation-duration: 3s;
					z-index: -1;
					transform:  scale(1);
					-moz-transform:  scale(1);
					-webkit-transform:  scale(1);
					-ms-transform:  scale(1);
					-o-transform:  scale(1);
					-moz-transition:all 0.5;
					-o-transition:all 0.5;
					-webkit-transition:all 0.5;
					-ms-transition:all 0.5;
					transition:all 0.5;
				}
			}
		}
		@include respond ('tab-port'){
			height:330px;
			&.large{
				height:380px;
			}
		}
		@include respond ('phone'){
			height:200px;
			&.large{
				height:250px;
			}
			.video-btn,
			.video-btn.sm{
				.popup-youtube{
					height: 60px;
					width: 60px;
					min-width: 60px;
					line-height: 60px;
					font-size: 20px;
				}
			}
		}
	}
}
@keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.3);
		-moz-transform:  scale(1.3);
		-ms-transform:  scale(1.3);
		-o-transform:  scale(1.3);
		-webkit-transform:  scale(1.3);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.3);
		-moz-transform:  scale(1.3);
		-ms-transform:  scale(1.3);
		-o-transform:  scale(1.3);
		-webkit-transform:  scale(1.3);
		opacity: 0;
	}
}
@keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.7);
		-moz-transform:  scale(1.7);
		-webkit-transform:  scale(1.7);
		-ms-transform:  scale(1.7);
		-o-transform:  scale(1.7);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.7);
		-moz-transform:  scale(1.7);
		-webkit-transform:  scale(1.7);
		-ms-transform:  scale(1.7);
		-o-transform:  scale(1.7);
		opacity: 0;
	}
}