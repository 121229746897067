// site-header
.site-header{
	&.style-1{
		.extra-cell{
			display: flex;
			align-items: center;
			
			& > a,
			& > div{
				margin-left:60px;
				@include respond('wide-desktop'){
					margin-left:15px;
				}
			}
			.login-btn{
				@include respond('phone'){
					display:none;
				}
				i{
					font-size: 18px;
					margin-right: 10px;
					line-height: 18px;
				}
			}
		}
		.header-nav{
			.nav{
				& > li{ 
					font-family:$font-family-title;
					&> a{
						font-weight:500;
						@include respond ('tab-land'){
							font-size:15px;
						}
						@include respond ('tab-port'){
							&:before{
								content:none;
							}
						}
					}
					@include respond('tab-port-min'){
						&:last-child a:after{
							content:none;
						}
					}
				}
			}
		}
		
		.header-nav .nav > li:hover > a,
		.header-nav .nav > li.active > a{
			color: var(--primary);
			&:before{
				height:6px;
			}
		}
		
		@include respond('tab-port'){
			padding-top: 0;
			position: relative;
		}
		.phone-no{
			/* color:var(--primary);
			background-color:var(--rgba-primary-1);
			font-weight:600; */
			i{
				font-size: 24px;
				margin-right: 15px;
			}
			@include respond ('laptop'){
				font-size:0;
				padding: 16px 20px;
				i{
					margin-right:0;
				}
			}
			@include respond ('tab-port'){
				padding: 12px 17px;
			}
			@include respond ('phone'){
				display:none;
			}
		}
		.logo-header img{
			max-width:181px;
		}
	}
	&.center{
		.logo-header {
			position: absolute;
			transform: translate(-50%);
			-moz-transform: translate(-50%);
			-webkit-transform: translate(-50%);
			-o-transform: translate(-50%);
			position: absolute;
			left: 50%;
			text-align: center;
			@include respond('tab-port'){	
				position: unset;
				transform:unset;
				-moz-transform:unset;
				-webkit-transform: unset;
				-o-transform: unset;
				position: unset;
				left: auto;
				text-align: left;
			}
		}
		.header-nav .nav {
			display: inline-block;
			float: left;
			width: 40%;
			@include respond('tab-port'){
				width: 100%;
				float: none;
			}
			& > li{
				display: inline-block;
			}
			&.navbar-left {
				text-align: right;
				margin-right:100px;
				@include respond('tab-port'){
					text-align: left;
					margin-right:0;
				}
			}
			&.navbar-right {
				float: right;
				margin-left:100px;
				@include respond('tab-port'){
					float: none;
					margin-left:0;
				}
				
			}
		}
	}
}
.extra-icon-box{
	padding-left: 65px;
    min-height: 50px;
    position: relative;
    display: flex;
    align-items: start;
    width: 270px;
    flex-direction: column;
	@include respond('wide-desktop'){
		width: 240px;
	}
	@include respond('tab-land'){	
		display:none;
	}
	i{
		width: 50px;
		height: 50px;
		background: var(--primary);
		text-align: center;
		line-height: 50px;
		color: #fff;
		position: absolute;
		border-radius: var(--border-radius-base);
		left: 0;
		box-shadow: 5px 5px 30px -15px var(--primary);
		font-size: 22px;
	}
	&:hover i{
		-webkit-animation-name: pulse;
		animation-name: pulse;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-duration: 1.2s;		
		animation-duration: 1.2s;	
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
	span{
		font-size: 18px;
		line-height: 1;
		margin-bottom: 5px;
	}
	.title{
		margin: 0;
		font-weight: 600;
		line-height: 1.2;	
		@include respond('wide-desktop'){
			font-size: 20px;
		}
	}
}

.menu-btn{
    background: transparent;
    width: 45px;
    height: 45px;
	box-shadow: none;
	border-radius:$border-radius;
	@include respond('wide-desktop'){	
		margin-left:20px;
	}
	@include respond('tab-port'){	
		display:none;
	}
	span{
		background: #212529;
		left:10px;
		
		&:nth-child(1) {
			top: 14px;
			width: 25px;
		}

		&:nth-child(2) {
			top: 21.5px;
			width: 20px;
		}
		&:nth-child(3) {
			top: 29px;
			width: 15px;
		}
	}
	&.open span:nth-child(1) {
	  top: 22px;
	}
	&.open span:nth-child(2) {
	  opacity: 0;
	  left: -60px;
	}
	&.open span:nth-child(3) {
		top: 22px;
		width: 25px;
	}
}
.contact-sidebar{
	padding:30px;
	position:fixed;
	height:100%;
	width:300px;
	z-index:99999;
	background:#fff;
	top:0;
	left:-300px;
	overflow-y:auto;
	@include transitionMedium;
	&.active{
		left:0;
	}
	.logo-contact{
		margin-bottom:40px;
		display:block;
		img{
			width:180px;	
		}
	}
	.dlab-title{
		margin-bottom: 10px;
		h4{
			font-size: 24px;
			font-family: var(--font-family-title);
			margin-bottom: 10px;
			line-height: 1;
		}
	}
	.contact-text{
		p{
			font-weight:500;
			font-size: 16px;
		}
	}
	.icon-bx-wraper{
		margin-bottom: 20px;
			
		.icon-md{
			padding: 0;
			margin-right: 15px;
			background: var(--primary);
			box-shadow: 0px 5px 10px 0 var(--rgba-primary-1);
			color: #fff;
			width: 50px;
			height: 50px;
			line-height: 50px;
			margin-top: 5px;
			border-radius: var(--border-radius-base);
			
			i{
				font-size: 28px;
			}
		}	
		.tilte{
			font-family: var(--font-family-title);
			margin-bottom: 5px;
		}
		p{
			font-size: 16px;
			font-weight: 500;
		}
	}
}
.menu-close{
	width: 0;
	position: fixed;
	height: 100%;
	background: #333;
	top: 0;
	opacity: 0.90;
	left: 0;
	@include transitionMedium;
	z-index:99998;
}
.contact-sidebar.active + .menu-close{
	width: 100%;
}






.header-creative-one{
	position: absolute;
    width: 100%;
	.main-bar {
		background-color: #fff;
		padding-left: 80px;
		padding-right: 100px;
		@include custommq ($max:1200px){
			padding-right:80px;
		}
		@include respond ('phone-land'){
			padding-left:20px;
		}
	}
	.extra-nav {
		padding: 0 40px 0 0;
	}
	.header-nav .nav{
		&> li .sub-menu li > a > i{
			display:inline-block;
			float:unset;
			transform: rotate(90deg);
			margin-left: 14px!important;
			font-size: 18px!important;
			background:transparent;
			color:#000!important;
			height:auto;
			line-height: 1;
		}
		@include respond ('tab-port'){
			&> li{
				border:0;
				& > a{
					i{
						height:auto;
						width:auto;
						color:#000;
						background-color:transparent;
						float:unset;
						padding-left:10px;
					}
				} 
				.sub-menu,
				.mega-menu{
					border-top:0;
				}
			}
		}
	}
	&.site-header{
		@include custommq ($max:1200px){
			.extra-nav{
				height:80px;
				@include respond ('phone'){
					height:70px;
					padding-right:10px;
				}
			}
			.logo-header{
				height:80px;
				img{
					height:80px;
				}
				@include respond ('phone'){
					width:140px;
					height:70px;
					img{
						height:70px;
						max-width:140px;
					}
				}
			}
		}
	}
	@include respond ('tab-port'){
		.phone-no{
			font-size:0;
		}
	}
	@include respond ('phone'){
		.phone-no{
			display:none;
		}
	}
}


/* SideNav ======= */
.header-sidenav .full-sidenav{
	position: fixed;
	right: -300px;
	width: 300px;
	top: 0;
	z-index: 999988;
	background: #fff;
	height: 100vh;
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	justify-content: space-between;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	left: auto;
	padding-top: 90px;
}
.header-sidenav .full-sidenav .mCSB_container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
}
.header-sidenav .full-sidenav.active{
	right:0;
}
.header-sidenav .full-sidenav .nav {
	margin-bottom:auto;
}
.header-sidenav .header-nav.full-sidenav .nav > li > a{
	color:#000;
}
.header-sidenav .header-nav.full-sidenav .nav > li > a:after{
	content:none;
}
.header-sidenav .header-nav.full-sidenav .social-menu,
.header-sidenav .header-nav.full-sidenav .logo-header,
.header-sidenav .header-nav.full-sidenav .nav{
    float: none;
    width: 100%;
}
.header-sidenav .header-nav.full-sidenav .nav{
	height:100%;
	display:block;
}
.header-sidenav .header-nav.full-sidenav .logo-header{
	height: auto;
	padding: 30px;
}
.header-sidenav.header-nav.full-sidenav .logo-header a{
	width: 170px;
	display: block;
}
.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu, 
.header-sidenav .header-nav.full-sidenav .nav > li:hover > .sub-menu, 
.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu,
.header-sidenav .header-nav.full-sidenav .nav > li:hover > .mega-menu{
	opacity: 1;
	visibility: visible;
	position:unset;
}
.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu, 
.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu{
	box-shadow: none;
	-webkit-transition: unset;
	-moz-transition: unset;
	-ms-transition: unset;
	-o-transition: unset;
	transition: unset;
}
.header-sidenav .header-nav.full-sidenav .nav > li .sub-menu{
	width: 100%;
	padding: 0;
	margin-top: 0;
	box-shadow:none;
	transition:none!important;
	padding-left: 30px
}
.header-sidenav .header-nav.full-sidenav .nav > li .sub-menu li a {
	color: rgb(80, 78, 78);
	font-size:15px;
	padding: 10px 25px;
	line-height:1;
}
.header-sidenav .header-nav.full-sidenav .nav > li{
	width: 100%;	
	&.active{
		&>a{
			color:var(--primary);
		}
	}
}
.header-sidenav .header-nav.full-sidenav .nav > li > a{
	padding: 12px 30px;
	width: 100%;
	font-weight: 600;
	font-size: 18px;
	line-height:1;
}
.header-sidenav .social-menu{
	padding:40px;
}
.header-sidenav .social-menu ul{
	margin:0;
	padding:0;
	list-style:none;
	margin-bottom:15px;
}
.header-sidenav .social-menu .copyright-head{
	margin-bottom:0;
	color:#cccccc;
}
.header-sidenav .social-menu ul li{
	display:inline-block;
}
.header-sidenav .social-menu ul li a{
	color: #bbc7cd;
	font-size: 18px;
	padding-right: 15px;
}
.header-sidenav .social-menu ul li a:hover{
	color: #8799a3;
}
.header-sidenav .header-nav.full-sidenav .mCustomScrollBox{
	width:100%;
}
.header-sidenav .menu-close i{
	color: #fff;
	font-size: 24px;
	position: absolute;
	right: 320px;
	top: 20px;
}
.header-sidenav .menu-close{
	position:fixed;
	height:100vh;
	width:0;
	background:#3a3a3a;
	top:0;
	left:auto;
	right:0;
	opacity:0;
	transition:all 0.5s;
	-webkit-transition:all 0.5s;
	-ms-transition:all 0.5s;
	-moz-transition:all 0.5s;
	-o-transition:all 0.5s;
	filter: blur(300px);
	cursor:pointer;
	overflow: hidden;
	z-index: 9999;
}
.header-sidenav .menu-close.active{
	width:100%;
	opacity:0.8;
	filter: blur(0);
}
.header-sidenav .header-transparent .is-fixed .main-bar {
    position: fixed;
    background-color: rgba(255,255,255,0.9);
}
.header-sidenav .header-full .container-fluid{
	padding-left:30px;
	padding-right:30px;
}
.header-sidenav .site-header ul, .site-header ol {
    margin-bottom: 0;
    list-style: none;
}
.header-sidenav .extra-nav ul li{
	list-style: none;
}
.header-sidenav .extra-nav .extra-cell{
	margin:0;
}
.header-sidenav{
	.menu-aside {
		right: 0;
		position: absolute;
		width: 100px;
		height: 100vh;
		z-index: 100000;
		display: flex;
		display: -ms-flexbox;
		-ms-flex-align: center;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		background-color: #fff;
		@include custommq($max:1200px){
			width:80px;
		}
		@include respond ('phone'){
			width:70px;
		}
	}
}
.menu-aside {
	.menu-btn {
		padding: 0;
		width: 100px;
		height: 100px;
		text-align: center;
		display: flex;
		margin: 0;
		background-color: var(--primary);
		border: 0;
		justify-content: center;
		align-items: center;
		position: fixed;
		z-index: 48;
		top: 0;
		border-radius:0;
		@include custommq ($max:1200px){
			height:80px;
			width:80px;
		}
		@include respond ('phone'){
			height:70px;
			width:70px;
		}
	}
	
	.menu-icon-in {
		width: 30px;
		height: 22px;
		position: relative;
		-webkit-transform: rotate(0);
		-moz-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		cursor: pointer;
		display: inline-block;
		span {
			display: block;
			position: absolute;
			height: 3px;
			width: 100%;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0);
			-moz-transform: rotate(0);
			-o-transform: rotate(0);
			transform: rotate(0);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
			background-color: #fff;
			border-radius: 10px;
			&:nth-child(1){
				top: 0;
				width: 60%;
			}
			&:nth-child(2){
				top: 50%;
				transform: translateY(-50%);
				-moz-transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				-o-transform: translateY(-50%);
			}
			&:nth-child(3){
				bottom: 0;
				top:auto;
				width: 85%;
			}
		}
	}
}


.header-nav.full-sidenav .nav li > .sub-menu,
 .header-nav.full-sidenav .nav li:hover > .sub-menu,
 .header-nav.full-sidenav .nav li > .mega-menu,
 .header-nav.full-sidenav .nav li:hover > .mega-menu {
    opacity: 1;
    visibility: visible;
    position: unset;
}

.header-sidenav .header-nav.full-sidenav .nav{
	@include respond ('tab-port'){
		padding:0;
	}
}
