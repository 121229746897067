// dlab-accordion
.dlab-accordion{
	.accordion-item{
		box-shadow: 1px 0px 60px 0 rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: 20px;
	}
	.accordion-body{
		padding: 25px 30px;
		border-radius: 0;
	}
	.accordion-collapse{
		border: 0;
	}
	.accordion-header{
		.accordion-button{
			font-size: 18px;
			border: 0;
			border-radius: 8px;
			padding: 22px 65px 22px 30px;
			transition: all 0.5s;
			color: $white;
			box-shadow: 5px 0 15px var(--rgba-primary-4);
			background-color: var(--primary);
			
			&:after{
				content: none;
			}
			
			.toggle-close{
				background-color: $white;
				font-family: themify;
				height: 55px;
				width: 55px;
				display: block;
				position: absolute;
				right: 5px;
				top: 5px;
				border-radius: $border-radius;
				line-height: 57px;
				text-align: center;
				font-size: 24px;
				@include transitionMedium;
				z-index:1;
				overflow:hidden;
				
				&:after{
					content: "";
					position:absolute;
					z-index:-1;
					background-color:var(--primary);
					@include transitionMedium;
					width:100%;
					height:100%;
					left:0;
					top:0;
					opacity:0;
				}
				
				&:before{
					content: "\e61a";
					color: var(--primary);
					font-size: 24px;
					font-family: 'themify';
					z-index: 99;
					opacity: 1;
					transform: rotate(45deg);
					transition: all 0.5s;
					line-height: 55px;
					display:block;
				}
			}
			
			&.collapsed{
				background: $white;
				color: inherit;
				&:after{
					opacity:0;
				}
				.toggle-close{
					content: "";
					&:after{
						opacity:1;
					}
					&:before{
						opacity: 1;
						transform: rotate(0);
						color:$white;
					}
				}
				
			}
		}
		@include respond('phone'){
			.accordion-header{
				.accordion-button{
					padding: 15px 65px 15px 15px;
				}
			}
		}
	}
	&.accordion-sm{
		.accordion-body {
			padding: 25px;
		}
		.accordion-header{
			.accordion-button{
				padding: 15px 55px 15px 20px;
				.toggle-close{
					height: 40px;
					width: 40px;
					&:before{
						font-size: 18px;
						line-height: 40px;
					}
				}
			}
			@include respond('phone'){
				.accordion-header{
					.accordion-button{
						padding: 15px 65px 15px 15px;
					}
				}
			}
		}
	}
}