/* Error Page */
.error-page{
	position:relative;
	.dz_error {
		font-size: 180px;
		font-weight: 700;
		line-height: 150px;
		margin: auto;
		color: var(--primary);
		font-family: var(--font-family-title);
		@include respond ('tab-port'){
			font-size: 130px;
			line-height: 1;
		}
		@include respond ('phone-land'){
			font-size: 90px;
		}
		@include respond ('phone'){
			font-size: 75px;
		}
	}
	.error-head {
		font-size: 32px;
		margin: 15px 0 25px;
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
	}
	@include respond('phone'){
		.error-head{
			font-size: 18px;
			line-height: 1.4;
		}
	}
	&:after {
		content: attr(data-text);
		position: absolute;
		color: var(--secondary);
		font-size: 18vw;
		line-height: 1;
		font-weight: 800;
		opacity: 0.05;
		z-index:-1;
		transform: translate(-50%,-50%);
		left: 50%;
		top: 50%;
		font-family: var(--font-family-title);
	}
}