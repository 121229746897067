#bg {
    background-attachment: fixed;
    background-size: cover;
}
.main-bar-wraper.sticky-no{
	.main-bar {
		position: unset;
	}
}

.boxed{
	.page-wraper{
		max-width: 1200px;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		box-shadow: 0 0 30px rgba(0, 17, 77, .2);
	}
	.footer-fixed .site-footer {
		left: 50%;
		width: 1200px;
		margin: 0 -600px;
		
	}
	.is-fixed{
		.main-bar {
			transition: none;
			-moz-transition: none;
			-ms-transition: none;
			-webkit-transition: none;
			-o-transition: none;
			position: unset;
		}
	}
	
	// site-header
	.site-header{
		&.style-1{
			.extra-cell{
				& > a, 
				& > div{
					margin-left: 15px;
				}
			}
			.extra-icon-box{
				@include respond('wide-desktop-min'){
					display:none;
				}
			}
		}
		&.style-2{
			.extra-cell{
				.login-btn{
					i{
						@include respond('wide-desktop-min'){	
							margin:0;
						}
					}
					@include respond('wide-desktop-min'){		
						margin-left: 20px;
						width: 45px;
						height: 45px;
						padding: 12px;
						span{
							display:none;
						}
					}
				}
			}
			.social-list.style-2 {
				@include respond('wide-desktop-min'){
					display: none;
				}
			}
			
			&.center{
				.header-nav{
					.nav{
						&.navbar-left {
							margin-right: 120px;
						}
						&.navbar-right {
							margin-left: 120px;
						}
					}
				}
				.extra-icon-box{
					padding-left: 0;
					width: 50px;
					.title,
					span{
						display: none;
					}
				}
				.extra-nav-left,
				.extra-nav{
					width: 120px;
					padding: 0;
				}				
				.extra-cell{
					.login-btn{
						display: none;
					}
					& > a,
					& > div {
						margin-left: 15px;
					}
				}
			}
		}
	}
	@include respond ('desktop'){
		h2, .h2 {
			font-size: 2.6rem;
		}
	}
	
	.no-of-item-dtl h2, .no-of-item-dtl .h2{
		font-size:80px;
	}
	.no-of-item-dtl h3, .no-of-item-dtl .h3{
		font-size:24px;
	}
	
	.form-head h2, .form-head .h2{
		font-size:24px;
	}
	
	.header-nav {
		.nav{
			&>li {
				&>a {
					@include respond('wide-desktop-min'){
						padding-left:15px;
						padding-right:15px;
					}
				}
			}
		}
	}
	
	
	.banner-one{
		&>.row{
			margin-left:0;
			margin-right:0;
		}
	}
	
	//banner-slider
	
	.banner-one .banner-content .title {
		font-size: 56px;
		@include respond ('phone'){
			font-size: 32px;		
		}
	}
	.container-fluid{
		@include respond('wide-desktop-min'){
			padding-left:15px;
			padding-right:15px;
		}
	}
	.under-construct{
		@include respond('wide-desktop-min'){
			padding:50px;
		}
		.dlab-content{
			.dlab-title{
				@include respond('wide-desktop-min'){
					font-size:70px;
				}
			}
			p{
				@include respond('wide-desktop-min'){
					font-size:28px;
				}
			}
		}
	}
	
	.about-bx1 .dlab-media{
		width: calc(100% - 20px);
	}
	.car-list-box.box-sm .feature-list > div:not(:last-child) {
		margin-right: 13px;
	}
	.side-bar {
		padding-left: 0;
	}
	.newsletter-bx {
		padding: 40px;
	}
	.portfolio-area2 .setResizeMargin {
		padding-right: 15px;
		margin-left: 0!important;
		padding-left: 15px;
	}
	
	.sidenav-menu{
		position: absolute;
	}
	.dlab-coming-soon{
		.dlab-title{
			font-size: 42px;
		}
		.countdown{
			.date{
				span {
					font-size: 14px;
				}
				.time {
					font-size: 70px;
					line-height: 70px;
				}
			}
		}
		.dlab-coming-bx {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

/* Frame */
.frame{
	padding:30px;
	.page-wraper{
		margin: 0 auto;
		position: relative;
		box-shadow: 0 0 30px rgba(0, 17, 77, .2);
		height: auto!important;
	}
	// site-header
	.site-header{
		&.style-1{
			.extra-icon-box{
				@include respond('wide-desktop'){
					display:none;
				}
			}
		}
	}
	
	.is-fixed{
		.main-bar {
			left:0;
			width: 100%;
			margin: 0;
			position: unset;
		}
	}
	button.scroltop{
		right:50px;
		bottom:50px;
	}
	.sidenav-menu{
		position: absolute;
	}
	.banner-slider .dlab-slide-item .inner-content{
		@include respond ('tab-land'){
			padding: 50px 35px;
		}
	}
	.banner-slider .dlab-slide-item .inner-content .car-name,
	.banner-slider .dlab-slide-item .inner-content .car-price{
		@include respond ('tab-land'){
			font-size: 26px;
		}
	}
	.banner-slider .slider-one-pagination{
		@include respond ('tab-land'){
			right: 10px;
			bottom: 60px;
		}
	}
	.banner-slider .dlab-slide-item .discover-link{
		@include respond ('tab-land'){
			left:30px;
		}
	}
	.header-nav .nav > li > a{
		@include respond ('tab-land'){
			padding-left:7px;
			padding-right:7px;
		}
	}
	@include respond('wide-desktop'){
		.dlab-coming-bx{
			padding-left: 30px;
			padding-right: 30px;
		}
		.countdown .date .time{
			font-size: 70px;
			line-height: 70px;
		}
	}
}
@media only screen and (max-width: 1024px) {
	.frame {
		padding:0;
	}
	.frame button.scroltop{
		right:15px;
		bottom:15px;
	}

}

.theme-sharped{
	--border-radius-base: 0;
	@include transitionNone;
}
.theme-rounded{
	--border-radius-base: 7px;
	@include transitionNone;
}