.sub-menu-down {
    .active {
        position: relative;
    }

    .active::after {
        content: "";
        width: 100%;
        background-color: red;
        bottom: 0;
        left: 0;
        border-radius: 5px 5px 0 0;
        height: 6px;
        position: absolute;
        transition: all 0.2s;
    }
}





.dlab-tabs {
    .accordion-item {
        border: none !important;
    }

    .accordion-button {
        background-color: rgb(241, 241, 241) !important;
        border: none !important;
        margin-top: 2px;

    }

    .accordion-body {
        padding: 0 !important;
        margin-top: 2px;
    }

    .active {
        border: none;
    }

    .accordion-button:focus .collapsed {
        color: red;
    }

    .color {
        color: black !important;
    }

}


.st0 {
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;

}


.car-search-box {
    &.sp15 {
        padding: 15px 5px;
    }

    .selected-box {
        .form-select {
            background-color: transparent;
            text-transform: uppercase;
            padding: 14px 15px;
            font-weight: 500;
            font-size: 15px;
            color: #504f4f;
            cursor: pointer !important;
        }
    }
}

.form-control.sm {
    font-size: 16px;
    background-color: #EBEBEB;
    font-weight: 500;
    color: #595c59;
    cursor: pointer !important;
}

.form-group {
    >.form-control {
        font-size: 18px;
        background-color: #EBEBEB;
        font-weight: 500;
        color: #575757;
    }
}

.car-search-box .selected-box .customSelect {
    background-color: #EBEBEB !important;
}

#BudgetCheck,
#BrandCheck {
    cursor: pointer !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.cursorUnsate {
    cursor: unset !important;
}



.table-dl {
    .table-head {
        background: var(--primary) !important;
    }
}

.price-slider {
    overflow-x: hidden;
}

.range-container {

    .slider {
        position: relative;
        width: 100%;
    }

    .slider__track,
    .slider__range,
    .slider__left-value,
    .slider__right-value {
        position: absolute;
    }

    .slider__track,
    .slider__range {
        border-radius: 3px;
        height: 6px;
    }

    .slider__track {
        background-color: #d6d6d6;
        width: 100%;
        z-index: 1;
    }

    .slider__range {
        background-color: #ff0000;
        z-index: 2;
        position: relative;
    }

    .slider__left-value,
    .slider__right-value {
        color: #dee2e6;
        font-size: 12px;
        margin-top: 20px;
    }

    .slider__left-value {
        left: 6px;
    }

    .slider__right-value {
        right: -4px;
    }

    /* Removing the default appearance */
    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
    }

    .thumb--left {
        z-index: 3;
    }

    .thumb--right {
        z-index: 4;
    }

    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
        background-color: #ff0000;
        border: none;
        border-radius: 30px;
        box-shadow: 0 0 1px 1px #ff0000;
        cursor: pointer;
        height: 30px;
        width: 30px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }

    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
        background-color: #ff0000;
        border: none;
        border-radius: 30px;
        box-shadow: 0 0 1px 1px #ff0000;
        cursor: pointer;
        height: 30px;
        width: 30px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
}

@media screen and (max-width:1700) {
    .thumb {
        width: 90%;
    }
}

@media screen and (max-width:991px) {
    .thumb {
        width: 700px !important;
    }
}

@media screen and (max-width:775px) {
    .thumb {
        width: 510px !important;
    }
}

@media screen and (max-width:575px) {
    .thumb {
        width: 95% !important;
    }
}


// Home-2 slider style


.rev-slider-title {
    position: absolute;
    right: 0%;
    top: 50% !important;
    transform: translate(-10%, -50%);
    display: flex;
    flex-direction: column;
    align-items: end;
    z-index: 7;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    font-weight: 700;
    visibility: inherit;
    transition: none 0s ease 0s;
    letter-spacing: 2px;
    font-size: 1.616vw;
    opacity: 1;
    font-family: Lato;
    padding-bottom: 50px;

    >div {
        white-space: nowrap;
        font-family: Poppins, sans-serif;
        font-weight: 800;
        line-height: 120px;
        visibility: inherit;
        font-size: 8.433vw;
        color: white;
    }

    >.btn {
        margin-top: 30px;
        font-size: 0.984vw !important;
        font-family: sans-serif;
    }
}

#home-slider-form {
    background-color: #1a1919cc !important;
}

@media screen and (max-width:1200px) {
    .rev-slider-title {
        font-size: 2.616vw;

        >div {
            font-size: 6.433vw;
        }

        >.btn {
            margin-top: 10px;
            font-size: 1vw !important;
            margin-bottom: 15px;
        }
    }
}

@media screen and (max-width:480px) {
    .rev-slider-title {
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0;
        padding-bottom: 10px;
        font-size: 3.616vw;

        >div {
            font-size: 11.433vw;
            line-height: 50px;
        }

        >.btn {
            margin-top: 10px;
            font-size: 2vw !important;
            margin-bottom: 10px;
            padding: 7px;
        }
    }
}

#about-title {
    color: #1a1919 !important;
}

.icon-bx-wraper:hover #about-title {
    color: #ffffff !important;
}



.icon-bx-wraper:hover .text-effect-1 {
    color: #ffffff !important;
}

.borderNone {
    border: none;
}


.nav-tabs {
    .tab-one {
        border-right-color: #dee2e6 !important;
    }

    .tab-one:hover {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
    }

    .tab-two {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-bottom-color: transparent !important;
    }

}

// // ===
// .product-gallery .thumb-slider .swiper-slide{
//     width: 179px !important;
// }
.boxed {
    padding: 0px 100px;
}

.skin-1 {
    background-color: #EE3131 !important;
}

.skin-2 {
    background-color: #0D3DE5 !important;
}

.skin-3 {
    background-color: #2a7e19 !important;
}

.skin-4 {
    background-color: #a10559 !important;
}

.skin-5 {
    background-color: #f77900 !important;
}

.skin-6 {
    background-color: #D7B65D !important;
}

.skin-7 {
    background-color: #22abc3 !important;
}

.skin-8 {
    background-color: #8669d4 !important;
}

.styleswitcher-inner {
    min-height: 100vh !important;
    position: absolute !important;
    top: 0 !important;
}

.switch-btn {
    background-color: white !important;
    color: var(--primary) !important;
}

.pattern-switcher {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 10px;

    li {
        height: 36px !important;
        width: 36px !important;
    }

    img {
        width: 100%;
    }
}

//masonary

.masonaryContainer {
    width: 100%;
    display: flex;

    img {
        width: 100% !important;
        display: block;
    }

    .figure {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        margin-bottom: 20px;
        break-inside: avoid;
    }

    .figure>img {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .figure a {
        color: black;
        text-decoration: none;
    }

    figcaption {
        grid-row: 2;
        grid-column: 1;
        background-color: rgba(255, 255, 255, .5);
        padding: .2em .5em;
        justify-self: start;
    }

    .container {
        column-count: 3;
        column-gap: 20px;
        margin: auto;
    }
}

@media screen and (max-width:775px) {
    .masonaryContainer {
        .container {
            column-count: 2;
        }
    }
}

@media screen and (max-width:580px) {
    .masonaryContainer {
        .container {
            column-count: 1;
        }
    }
}

.masonaryContainer2 {
    width: 100%;
    display: flex;

    img {
        width: 100% !important;
        display: block;
    }

    .figure {
        margin: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        break-inside: avoid;
    }

    .figure>img {
        grid-row: 1 / -1;
        grid-column: 1;
    }

    .figure a {
        color: black;
        text-decoration: none;
    }

    figcaption {
        grid-row: 2;
        grid-column: 1;
        background-color: rgba(255, 255, 255, .5);
        justify-self: start;
    }

    .container {
        column-count: 4;
        column-gap: 10px;
        margin: auto;
    }
}

@media screen and (max-width:775px) {
    .masonaryContainer2 {
        .container {
            column-count: 3;
        }
    }
}

@media screen and (max-width:580px) {
    .masonaryContainer2 {
        .container {
            column-count: 2;
        }
    }
}

@media screen and (max-width:300px) {
    .masonaryContainer2 {
        .container {
            column-count: 1;
        }
    }
}

.home_three_custom_pagination {
    position: absolute;
    bottom: 25px;
    left: 50%;
    display: flex;
    gap: 10px;
    z-index: 999999;
}

.home_three_custom_pagination>span {
    width: 17px;
    height: 17px;
    background-color: transparent;
    border: 2px solid white;
}

.home_three_custom_pagination>.swiper-pagination-bullet-active {
    width: 17px;
    height: 17px;
    background-color: white;
}



// select option

.hoverNone {
    position: relative;
}

.hoverNone:hover {
    background-color: unset;
}

.hoverNone:focus {
    background-color: unset;
    color: unset;
}

.hoverNone::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 10px;
    border-radius: 2px;
    transform: rotate(45deg) !important;
    background: rgb(43, 42, 42);
    top: 11px;
    right: 10px;
}

.hoverNone::before {
    position: absolute;
    content: "";
    width: 2px;
    height: 10px;
    border-radius: 2px;
    transform: rotate(-45deg) !important;
    background: rgb(43, 42, 42);
    top: 11px;
    right: 16px;
    font-weight: bold;
}

.focusBtn:focus {
    color: white;
}

// form select style
.form-option-select.form-select {
    font-size: 16px !important;
    border: none !important;
    background-color: white;
    line-height: 2.5;
    // >option {
    //     padding: 15px !important;
    // }
}