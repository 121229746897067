// Modal Box
.inquiry-modal{
	.modal-dialog{
		max-width: 700px;
		display: flex;
		min-height: auto;
		justify-content: stretch;
		align-items: stretch;
	}
	.form-control{
		font-size: 15px;
		font-weight: 300;
		color: #000;
	}
	.inquiry-adv{
		flex: 0 0 50%;
		max-width:50%;
		@include respond('phone-land'){
			display:none;
		}
		img{
			height:100%;
			object-fit:cover;
			width:100%;
		}
	}
	.modal-content{
		border: 0;
		border-radius: 0;
		background: #fff;
		padding: 30px;
		flex: 0 0 50%;
		max-width:50%;
		@include respond('phone-land'){
			flex: 0 0 100%;
			max-width:100%;
			padding: 20px;
		}
		.modal-header{
			border:0;
			padding:0;
			display: block;
			text-align: center;
			
			.modal-title{
				font-size: 24px;
				margin-bottom: 15px;
				line-height: 1.3;
				font-family: var(--font-family-title);
				text-align: center;
				width: 100%;
			}
			i{
				color: var(--primary);
				font-size: 54px;
				line-height: 1;
				display: inline-block;	
			}
		}
		.modal-body{
			padding:0;
		}
	}
	.btn-close {
		margin: 0;
		position: absolute;
		right: -40px;
		top: -40px;
		color: #fff;
		font-weight: 100;
		text-shadow: none;
		opacity: 1;
		font-size: 40px;
		padding: 0;
		height: 40px;
		line-height: 40px;
		width: 40px;
		border: 0;
		background: transparent;
		@include respond('phone-land'){
			right: 0;
		}
	}
}