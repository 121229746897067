.about-bx2{
	.year-exp{
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		bottom: 0;
		background-color: #fff;
		padding: 30px 40px 1px 1px;
	
		.year{
			height: 105px;
			width: 105px;
			text-align: center;
			border: 5px solid;
			line-height: 98px;
			font-size: 45px;
			margin: 0;
			border-color: var(--primary);
			margin-right: 20px;
		}
		.text{			
			font-size: 25px;
			margin: 0;
		}
	}
	.list-square-check{
		li{
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	.about-media{
		border-radius: var(--border-radius-base);
		img{
			width: 100%;
			object-fit: cover;
			height: 100%;
		}
	}
	@include respond('tab-port'){
		.about-media{
			height: 450px;
		}
	}
	@include respond('phone-land'){
		.list-square-check li {
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
	@include respond('phone'){
		.year-exp{
			padding: 15px 30px 1px 1px;
			.year{
				height: 80px;
				width: 80px;
				line-height: 74px;
				font-size: 35px;
				margin-right: 15px;
			}
			.text{			
				font-size: 20px;
			}
		}
	}
}