// Widget
.widget{
	margin-bottom: 50px;
	@include respond('phone-land'){
		margin-bottom: 30px;
	}
	&.style-1{
		box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
		padding: 35px;
		border-bottom: 4px solid;
		border-radius: var(--border-radius-base);
		border-color: var(--primary);
		@include respond('phone'){
			padding: 25px;
		}
	}
}
.widget-title{
	margin-bottom: 20px;
	@include respond('phone-land'){
		margin-bottom: 15px;
	}
	.title{
		position: relative;
		margin-bottom:5px;
		@include respond('phone-land'){
			font-size: 24px;	
		}
	}
}


// search-bx
.search-bx{
	form{
		margin-bottom:10px;
	}
	.form-control{
		padding: 10px 25px 10px 25px;
		height: 70px;
		border-radius: var(--border-radius-base) 0 0px var(--border-radius-base) !important;
	}
	.input-group-prepend{
		border-right: 0;
		top: 50%;
		.input-group-text{
			i {
				color: #828282;
			}
		}
	}
	button{
		height: 70px;
		font-size: 24px;
		padding: 0;
		border-radius: 0;
		z-index:4;
		width: 70px;
		background-color: var(--primary);
		color: $white;
		box-shadow: none;
		justify-content: center;
		border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0 !important;
		i{
			font-size:22px;
		}
	}
	&.style-1{
		padding: 30px;
		background-color: $secondary;
		border-radius: $border-radius;
		.input-group{
			margin-bottom: 0;
		} 
		button{
			border-radius: 0 $border-radius $border-radius 0;
			padding: 0px 10px;
		}
	}
}


// download-file
.download-file {
	background-image: var(--gradient);
    color: $white;
    padding: 25px 25px 20px 20px;
    border-radius: 4px;
	.title {
		color: $white;
		margin-top: 0;
		margin-bottom: 20px;
		text-align: center;
		font-size: 20px;
		font-weight: 600;
	}
	ul{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			a {
				background-color: $white;
				color: $black;
				display: block;
				margin-bottom: 10px;
				border-radius: 4px;
				width: 100%;
				padding: 15px;
				text-transform: uppercase;
				font-size: 14px;
				font-weight: 600;				
				text-align: left;
				align-items: center;
				display: flex;
				justify-content: space-between;
				i {
					width: 18px;
					height: 18px;
					position: relative;
					&:after, 
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						background-size: contain;
						background-repeat: no-repeat;
						left: 0;
						top: 0;
					}
					&:before {
						-webkit-animation: download1 2s ease infinite;
						animation: download1 2s ease infinite;
					}
				}
				.text {
					display: inline;
					position: relative;
				}
				&:hover{
					.text {
						color: var(--primary);
					}				
				}				
			}
		}
	}
}
@-webkit-keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {
  0%, 10%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
// Contact Box
.widget_contact{
	position: relative;
    padding: 50px 30px;
    border-radius: 6px;
    background-size: cover;
    text-align: center;
	position:relative;
	z-index:1;
	overflow:hidden;
	&:after{
		background:var(--gradient-sec);
		content:"";
		position:absolute;
		left:0;
		top:0;
		width:100%;
		height:100%;
		opacity:0.85;
		z-index:-1;
		background-size:200%;
	}
	img {
		margin-bottom: 20px;
	}
	h4 {
		font-size: 20px;
		font-weight: 600;
		color: #fff;
		padding-bottom: 25px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		margin-bottom: 30px;
	}
	.phone-number {
		position: relative;
		font-size: 24px;
		color: #fff;
		font-weight: 500;
		margin-bottom: 11px;
		a{
			color: #fff;
		}
	}
	.email {
		position: relative;
		font-size: 18px;
		color: #fff;
		font-weight: 500;
		margin-bottom: 30px;
		a {
			color: #fff;
		}
	}
}
.service-carouse{
	.item{
		overflow:hidden;
		border-radius:6px;
	}	
}
.list-2{
	display: table;
	li{
		width: 50% !important;
		float: left;
	}
}

// WP Widget Start
/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
	
	li{
		padding-bottom: 1.125rem;
		margin-bottom: 0.8125rem;
		position: relative;
		padding: 0.5rem 0rem 0.5rem 1.5rem;
		margin-bottom: 0;
		line-height: 1.25rem;
		a{
			color: inherit;
			text-transform: capitalize;
			@include transitionMedium;
			position:relative;
			
			
			&:before{
				content: "\f101";
				font-family: "Font Awesome 5 Free";
				position: absolute;
				display: block;
				left: -1.5rem;
				top: 0.063rem;
				font-size: 14px;
				font-weight: 900;
			}
			&:hover{
				color:var(--primary);
				transform:translateX(10px);
			}
		}
		
		li{
			border-bottom: none;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			line-height: 1.375rem;
			&:last-child{
				border-bottom:0;
				padding-bottom:0;
			}
			&:before {
				top: 0.5rem;
				left: 0;
			}
		}
	}
	ul{
		padding-left: 1.125rem;
		margin-top:0.5rem;
		margin-left: -0.9375rem;
	}
}
/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul{
    margin-bottom: -0.625rem;
}
.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
    text-align: right;
    display: table;
    width: 100%;
    padding: 0.5rem 0rem 0.5rem 1.5rem !important;
    line-height: 1.3 !important;	
}
.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before{
	content:none !important;
}
.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
    float: left;
	text-transform: capitalize;
    text-align: left;	
}
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list{
    margin-bottom: 2.5rem;
	padding:0 !important;
}

// WIDGET RECENT COMMENT
.widget_recent_comments{
	
	ul{
		margin-top:-0.625rem;
		li{
			padding-left: 1.875rem;
			line-height: 1.625rem;
			a{
				font-weight:500;	
				&:before {
					content:none;
				}
			}
			&:before {
				content:"\f27a";
				font-family: "Font Awesome 5 Free";
				background: transparent;
				width: auto;
				height: auto;
				position:absolute;
				left: 0;
				top: 0.625rem;
				font-size: 1.125rem;
				font-weight:500;
			}
		}
	}
}

// WIDGET META
.widget_meta ul li a abbr[title] {
    color: #333;
    border-bottom: none;
}

// Widget Calender
.widget_calendar{
	
	th,
	td{
		text-align: center;
		border: 0.0625rem solid var(--rgba-primary-1);
		padding: 0.4em;
	}
	th, 
	tfoot td {
		border-color: rgba(0,0,0,0.05);
		&:last-child{
			border:0;
		}
	}
	tr {
		border-bottom: 0.0625rem solid var(--rgba-primary-1);
		&:nth-child(2n+2){
			background-color:var(--rgba-primary-05);
		}
	} 
	table {
		border-collapse: collapse;
		margin: 0;
		width: 100%;
	}
	.calendar_wrap th,
	tfoot td a {
		color:#fff;
			background: #1f2471;
	}
	.calendar_wrap{
		td{
			a {
				font-weight: 600;
				text-transform: uppercase;
				color: inherit;
			}
		}
	}
	caption {
		text-align: center;
		font-weight: 600;
		caption-side: top;
		background:#1f2471;
		border-bottom: 1px solid rgba(255,255,255,0.2);
		color: #fff;
		text-transform: uppercase;
		font-size: 0.9375rem;
	}
}
footer{
	.widget_calendar{
		th,
		td{
			border-color:rgba(255,255,255,0.1);
		}
		tr {
			border-bottom: 0.0625rem solid rgba(255,255,255,0.1);
			&:nth-child(2n+2){
				background-color:rgba(255,255,255,0.05);
			}
		} 
	} 
}
.wp-calendar-nav {
    background: var(--title);
    color: #fff;
    display: flex;
    justify-content: space-between;
	text-align:center;
	span {
		padding: 0.3125rem 1.25rem;
		width: 44%;
		border-right:0.0625rem solid rgba(255,255,255,0.2);
		a {
			color: #fff;
		}
	}
}




// Widget Archive 
.widget_archive select {
    width: 100%;
    padding: 0.3125rem;
    border: 0.0625rem solid #CCC;
}

// WIDGET TEXT 
.widget_text{
	select {
		width: 100%;
		border: 0.0625rem solid #CCC;
		 option {
			width: 100%;
		}
	}
	p{
		font-size: 0.9375rem;
		line-height: 1.75rem;
	}
	
}

/*widget rss  */
.rsswidget img {
    display: inherit;
}
.widget-title .rsswidget{
	display: inline-block;
}

ul a.rsswidget,
ul cite{
    font-weight: 600;
	color: var(--title);
}
ul {
	a.rsswidget {
		font-size: 1.125rem;
		@include transitionMedium;
		&:hover{
			color:var(--primary);
		}
	}
}
.rssSummary {
    margin: 1.25rem 0;
    line-height: 1.7;
	font-size: 15px;
}
.rss-date{
    display: block;
    margin-top: 0.3125rem;
    font-weight: 400;
}
.widget_rss ul li {
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom:0.0625rem solid rgba(0,0,0,0.1);
    padding-top: 0;
	&:last-child {
		margin-bottom: 0;
		border-bottom: 0;
		padding-bottom: 0;
	}
}


// WIDGET GALLERY
.widget_gallery{
	ul {
		padding-left: 0;
		display: table;
		margin-left: -0.625rem;
		margin-right: -0.625rem;
	}
	&.gallery-grid-2 li {
		width:50%;
	}
	&.gallery-grid-4 li {
		width:25%;
	}
	li {
		display: inline-block;
		width: 33.33%;
		float:left;
		@include transitionMedium;
		padding: 0.625rem;
		img {
			display: inline-block;
			width:100%;
			border-radius: var(--border-radius-base);
		}
		&:nth-child(3n-3) {
			margin-right: 0;
		}
		&:hover {
			opacity:0.7;
		}
		
		a{
			display: inline-block;
		}
	}
}


// widget_tag_cloud
.widget_tag_cloud{
	.tagcloud{
		clear: both;
		display: table;
		a{
			position: relative;
			border: 1px solid;
			border-radius: $border-radius;
			padding: 6px 15px;
			display: inline-block;
			margin: 0 15px 15px 0;
			font-size: 15px;
			color: inherit;
			line-height: 1.4;
			&:hover {
				background-color: var(--primary-hover);
				border-color:var(--primary-hover);
				color:#fff;
			}
		}
	}
}


// recent-posts-entry
.recent-posts-entry{

	.widget-post-bx{
		.widget-post{
			display: flex;
			align-items: center;
			border-radius: $border-radius;
			margin-bottom: 20px;
			transition: all 0.5s;
			.dlab-media{
				width: 90px;
				min-width:90px;
				border-radius:var(--border-radius-base);
			}
			.dlab-info{
				padding-left: 15px;
			}
			.dlab-meta{
				margin-bottom:0;
				ul{
					line-height: 1;
					li{
						color:#575757;
						text-transform: uppercase;
						position:relative;
						font-weight: 600;
						font-size:14px;
					}	
				}	
			}
			.title{
				font-weight: 600;
				font-size: 16px;
				margin-bottom: 12px;
				line-height: 1.5;
			}
		}
	}
}
// widget_services
.service_menu_nav{
    background: #fff;
    padding: 30px 30px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0,0.1);
    border-radius: 4px;
	@include respond('tab-land'){
		padding: 15px;
	}
	ul{
		li{
			padding:0;
			
			a{
				background-color: #fff;
				display: block;
				border-radius: 6px;
				padding: 15px 25px;
				margin-bottom: 10px;
				color: #000;
				font-weight: 600;
				border: 1px solid rgba(0, 0, 0, 0.1);
				@include transitionMedium;
				background-size: 200%;
				position:relative;
				z-index:1;
				overflow:hidden;
				@include respond('tab-land'){
					padding: 15px;
				}
				&:before{
					content: "\f30b";
					font-weight: 900;
					font-family: 'Line Awesome Free';
					position: absolute;
					right: 50px;
					left:auto;
					top: 50%;
					font-size: 30px;
					opacity: 0;
					@include transitionMedium;
					@include translateZ;
					@include respond('tab-land'){
						font-size: 22px;
					}
				}
				&:after{
					background-image: var(--gradient);
					position:absolute;
					width:100%;
					height:100%;
					left:0;
					top:0;
					@include transitionMedium;
					content:"";
					background-size:200%;
					opacity:0;
					z-index:-1;
				}
				
			}
			&:hover,
			&.current-menu-item,
			&.active{
				
				a{
					padding-left: 20px;
					border-color:transparent;
					transform: translateX(0);
					-moz-transform: translateX(0);
					-webkit-transform: translateX(0);
					-ms-transform: translateX(0);
					-o-transform: translateX(0);
					&:before{
						opacity:1;
						right:10px;
						color:$white;
						@include respond('tab-land'){
							font-size: 22px;
							right:5px;
						}
					}
						&:after{
						opacity:1;
					}
					color: $white;
				}
			}
		}
	}
}
.wp-block-categories-dropdown,
.wp-block-archives-dropdown{
	margin-bottom:20px;
	select{
		@extend .form-control;
		&:after{
			content:"\f078";
			font-weight: 900;
			font-family: 'Line Awesome Free';
			position: absolute;
			right: 50px;
			left:auto;
			top: 50%;
		}
	}
}
.post-tags{
	strong{
		font-weight:700;
		color:#212529;
		margin-right:10px;
	}	
	a{
		margin-right:10px;
		display: inline-block; 
	}
}


//widget_product_tag
.widget_product_tag{
	.product-tag-group{
		display: block;
		.btn-check:checked + .btn-outline-primary{
			background-color:var(--rgba-primary-1);
			color:var(--primary);
		} 
		.btn{
			box-shadow:none;
			padding: 10px 25px;
			margin-right:15px;
			margin-bottom:15px;
			border-color:var(--rgba-primary-3);
			border-radius:var(--border-radius-base)!important;
			&:hover{
				background-color:var(--rgba-primary-1);
				color:var(--primary);
			}
		}
	}
}

//widget_price_range
.widget_price_range{
	.price-slide .amount {
		border: 0;
		font-size: 14px;
		font-weight:600;
		color:#575757;
		margin-bottom: 20px;
	}
	.ui-widget-header{
		background:var(--primary);
	}
	.ui-slider-horizontal{
		height:6px;
		margin: 13px 20px 13px 11px;
	}
	.ui-slider-horizontal .ui-slider-handle{
		top:-0.65em;
	}
	.ui-slider .ui-slider-handle{
		height:30px;
		width:30px;
		border-radius:30px;
	}
	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default{
		background:var(--primary);
		border-color:var(--primary);
	}
	
	.price-slider{
		padding-bottom: 12px;
		#input-with-keypress-0,
		#input-with-keypress-1{
			font-weight: 600;
			margin-bottom: 20px;
			display: inline-block;
			color:#7c7c7c;
			
		}
		#input-with-keypress-0{
			position:relative;
			padding-right: 20px;
			margin-right: 10px;
			&:after{
				content:"";
				height:2px;
				width:8px;
				background:#7c7c7c;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.noUi-horizontal .noUi-handle{
			height: 30px;
			width: 30px;
			border-radius: 30px;
			background: var(--primary);
			border-color: var(--primary);
			box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, .3);
			&:after,
			&:before{
				content:none;
			}
		}
		.noUi-horizontal {
			height: 6px;
		}
		.noUi-target{
			border:0;
			background: #e5e5e5;
			padding: 0 12px;
		}
		.noUi-connect{
			background: var(--primary);
		}
		.noUi-horizontal .noUi-handle{
			top:-12px;
		}
	}
	
}

//client-logo-wg
.client-logo-wg{
	ul{
		display:flex;
		flex-flow:wrap;
		.brand-logo{
			float:left;
			width:33.33%;
			padding: 5px 5px;
			a{
				position:relative;
				display:inline-block;
				background-color:#fff;
				padding: 12px 5px;
				&:after{
					position:absolute;
					content:"";
					top:-1px;
					left:-1px;
					background-color:#dbdbdb;
					height:45px;
					width:45px;
					z-index: -1;
					@include transitionFast;
				}
				&:before{
					position:absolute;
					content:"";
					bottom:-1px;
					right:-1px;
					background-color:#dbdbdb;
					height:45px;
					width:45px;
					z-index: -1;
					@include transitionFast;
				}
			}
			img{
				width:100%;
			}
			&:hover{
				a{
					&:after,
					&:before{
						height:calc(100% + 2px);
						width:calc(100% + 2px);
					}
				}
			}
		}
	}
}


.dropdown-item.active, .dropdown-item:active{
	background-color:var(--primary);
}