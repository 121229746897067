.map-iframe{
	iframe{
		height:500px;	
	}
}
.contact-area1{
	padding: 60px 40px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: relative;
    z-index: 1;
    background: #fff;
	label{
		margin-bottom:20px;
	}
	.form-group{
		margin-bottom:30px;
	}
	@include respond('phone'){
		padding: 40px 15px;
	}
}

.bottom-shape{
	position:relative;
	&:after{
		content:"";
		position:absolute;
		width:100%;
		height:370px;
		background-color:#fff;
		bottom:0;
		left:0;
		z-index: -1;
	}
}