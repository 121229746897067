.creative-banner-one {
	position:relative;
	.swiper-slide {
		height: 100vh;
		padding-top: 100px;
		@include custommq($max:1200px){
			padding-top: 80px;
		}
		@include respond ('phone'){
			padding-top:70px;
		}
	}
	
	.bottom-aside {
		height: 150px;
		position: absolute;
		bottom: 0;
		right: 100px;
		z-index: 99;
		left: 0;
		.video-box {
			width: 250px;
			position: absolute;
			right: 0;
			bottom: 0;
			.popup-youtube {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: 999;
				background-color: rgba(255,255,255,.8);
				height: 40px;
				width: 40px;
				border-radius: 50px;
				color: #000;
				text-align: center;
				line-height: 43px;
			}
		}
		@include custommq($max:1200px){
			right:80px;
			.video-box{
				width:180px;
			}
		}
		@include respond ('phone'){
			.video-box{
				display:none;
			}
		}
	}
	
	.swiper-button-arrow {
		position: absolute;
		width: 350px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		.swiper-button-next,
		.swiper-button-prev{
			&:before{
				content: "";
				height: 2px;
				width: 100px;
				position: absolute;
				border-radius: 50px;
				background-color: #fff;
				top: 50%;
				transform: translateY(-50%);
			}
			&:after{
				font-family: poppins;
				text-transform: uppercase!important;
				color: var(--primary);
				font-weight: 600;
				font-size: 16px;
				line-height: 16px;
			}
		}
		.swiper-button-next{
			right: 10px;
			left: auto;
			&:before {
				right: 50px;
			}
		}
		.swiper-button-prev{
			left: 10px;
			right: auto;
			&:before {
				left: 50px;
			}
		}
		@include respond ('phone-land'){
			left: 20px;
			width:250px;
			transform: translate(20px,-50%);
			.swiper-button-next,
			.swiper-button-prev{
				&:before{
					width:50px;
				}
			}
		}
		@include respond ('phone'){
			width:230px;
		}
	}
}



.vehicle-box.style1 {
    background-position: center;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
	.left-info {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 80px 0 150px 80px;
		z-index: 9;
		position: relative;
		
		&:after{
			content:"";
			position:absolute;
			height:100%;
			width:500px;
			top:0;
			left:0;
			z-index:-1;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.58+0,0+100 */
			background: -moz-linear-gradient(left,  rgba(0,0,0,0.7) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(0,0,0,0.7) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(0,0,0,0.7) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#94000000', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
		}
		
		&:before{
			content:"";
			position:absolute;
			height:100%;
			width:500px;
			top:0;
			right:0;
			z-index:-1;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0+0,0.58+100 */
			background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 1%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#94000000',GradientType=1 ); /* IE6-9 */

		}
		
		.bottom-info {
			border-left: 4px solid var(--primary);
			padding: 5px 0 5px 20px;
			margin-top: auto;
			h3 {
				color: #fff;
				margin-bottom: 0;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 18px;
				line-height: 30px;
				@include respond('phone'){
					font-size:16px;
				}
			}
		}
		.title {
			font-size: 80px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			line-height: 80px;
			margin-bottom: 0;
		}
		.main-title {
			font-size: 28px;
			color: var(--primary);
			text-transform: uppercase;
			font-weight: 800;
			line-height: 28px;
			margin-bottom: 0;
		}
		@include respond ('tab-port'){
			.title{
				font-size:60px;
			}
		}
		@include respond ('phone-land'){
			padding: 30px 0 80px 20px;
			.bottom-info{
				margin-bottom: 30px;
			}
		}
		@include respond ('phone'){
			.title{
				font-size:40px;
				line-height:50px;
			}
			.main-title {
				font-size:20px;
			}
		}
	}
	.right-info {
		position: absolute;
		right: 100px;
		z-index: 10;
		top: 50%;
		transform: translate(-100px,-50%)!important;
		@include respond ('phone'){
			display:none;
		}
	}
	.vehicle-services li {
		display: block;
		padding: 20px 0;
		h3 {
			color: var(--primary);
			font-size: 28px;
			margin-bottom: 0;
			line-height: 28px;
			font-weight: 800;
		}
		p {
			margin-bottom: 0;
			text-transform: uppercase;
			color: rgba(255,255,255,.8);
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 1px;
		}
	}
}

.swiper-pagination.style1 {
    top: 50%;
    transform: translateY(-50%)!important;
	.swiper-pagination-bullet {
		display: block;
		border: 2px solid rgba(0,0,0,.5);
		background-color: transparent;
		height: 10px;
		width: 10px;
		transition: all .5s;
		margin-bottom: 30px;
		position: relative;
		&:after {
			content: "";
			width: 2px;
			height: 0;
			background-color: #231e22;
			position: absolute;
			top: 30px;
			left: 50%;
			transform: translateX(-50%);
			transition: all .5s;
		}
		&.swiper-pagination-bullet-active{
			background-color: #231e22;
			border-color: #231e22;
			margin-bottom: 150px;
			transition: all .5s;
			position: relative;
			&:after{
				height:100px;
			}
		}
	}
}


.service-box{
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	@include transitionMedium;
	.dlab-info{
		padding: 30px 30px;
	}
	&:hover{
		transform: translateY(-10px);
	}
}

.icon-bx-xs.check-km {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    cursor: pointer;
	color:var(--primary);
}