@charset "UTF-8";
/*
    Name: Mobhil 
    Author: Dexignlabs
    Portfolio : https://themeforest.net/user/dexignlabs/portfolio
   
    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    7. Pages
    
*/
@import url("./../vendor/animate/animate.min.css");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/all.min.css");
@import url("../icons/flaticon/flaticon.css");
@import url("../icons/themify/themify-icons.css");
:root {
  --gradient: linear-gradient(to right, #7355f7 0, #5f3bff 51%, #7355f7 100%);
  --gradient-sec: linear-gradient(
    to right,
    #130065 0,
    #2b0ead 51%,
    #130065 100%
  );
  --primary: #ee3131;
  --secondary: #ff9446;
  --primary-hover: #da1212;
  --primary-dark: #7c0a0a;
  --rgba-primary-1: rgba(238, 49, 49, 0.1);
  --rgba-primary-2: rgba(238, 49, 49, 0.2);
  --rgba-primary-3: rgba(238, 49, 49, 0.3);
  --rgba-primary-4: rgba(238, 49, 49, 0.4);
  --rgba-primary-5: rgba(238, 49, 49, 0.5);
  --rgba-primary-6: rgba(238, 49, 49, 0.6);
  --rgba-primary-7: rgba(238, 49, 49, 0.7);
  --rgba-primary-8: rgba(238, 49, 49, 0.8);
  --rgba-primary-9: rgba(238, 49, 49, 0.9);
  --font-family-base: Montserrat, sans-serif;
  --border-radius-base: 4px;
  --border-radius-lg: 1rem;
  --font-family-title: Montserrat, sans-serif;
  --title: #000;
  --quote: url(../images/quote-1.png);
  --quote-2: url(../images/quote-1.png);
}

/*!
 * Bootstrap v5.0.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-primary: #ee3131;
  --bs-secondary: #ff9446;
  --bs-success: #029e76;
  --bs-info: #00aeff;
  --bs-warning: #fea500;
  --bs-danger: #ff5269;
  --bs-light: #faf8f2;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212621;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: #000;
}

h1,
.h1 {
  font-size: calc(1.525rem + 3.3vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 4rem;
  }
}

h2,
.h2 {
  font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 3rem;
  }
}

h3,
.h3 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  h5,
  .h5 {
    font-size: 1.375rem;
  }
}

h6,
.h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ee3131;
  text-decoration: underline;
}
a:hover {
  color: #be2727;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #aeaed5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1300px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212621;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212621;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212621;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212621;
  vertical-align: top;
  border-color: #e1e1f0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fcd6d6;
  --bs-table-striped-bg: #efcbcb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3c1c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9c6c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3c1c1;
}

.table-secondary {
  --bs-table-bg: #ffeada;
  --bs-table-striped-bg: #f2decf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d3c4;
}

.table-success {
  --bs-table-bg: #ccece4;
  --bs-table-striped-bg: #c2e0d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d4cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddad3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d4cd;
}

.table-info {
  --bs-table-bg: #ccefff;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e6;
}

.table-warning {
  --bs-table-bg: #ffedcc;
  --bs-table-striped-bg: #f2e1c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d5b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdbbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d5b8;
}

.table-danger {
  --bs-table-bg: #ffdce1;
  --bs-table-striped-bg: #f2d1d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c6cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecccd0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6c6cb;
}

.table-light {
  --bs-table-bg: #faf8f2;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1dfda;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #aeaed5;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212621;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select,
  .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p textarea {
    transition: none;
  }
}
.form-control[type="file"],
.wp-block-categories-dropdown select[type="file"],
.wp-block-archives-dropdown select[type="file"],
.comment-respond .comment-form p.comment-form-author input[type="file"],
.comment-respond .comment-form p.comment-form-email input[type="file"],
.comment-respond .comment-form p.comment-form-url input[type="file"],
.comment-respond .comment-form p textarea[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not(:read-only),
.wp-block-categories-dropdown
  select[type="file"]:not(:disabled):not(:read-only),
.wp-block-archives-dropdown select[type="file"]:not(:disabled):not(:read-only),
.comment-respond
  .comment-form
  p.comment-form-author
  input[type="file"]:not(:disabled):not(:read-only),
.comment-respond
  .comment-form
  p.comment-form-email
  input[type="file"]:not(:disabled):not(:read-only),
.comment-respond
  .comment-form
  p.comment-form-url
  input[type="file"]:not(:disabled):not(:read-only),
.comment-respond
  .comment-form
  p
  textarea[type="file"]:not(:disabled):not(:read-only) {
  cursor: pointer;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus {
  color: #212621;
  background-color: #fff;
  border-color: #f79898;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.form-control::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-date-and-time-value,
.comment-respond .comment-form p textarea::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled,
.comment-respond .comment-form p.comment-form-author input:disabled,
.comment-respond .comment-form p.comment-form-email input:disabled,
.comment-respond .comment-form p.comment-form-url input:disabled,
.comment-respond .comment-form p textarea:disabled,
.form-control:read-only,
.wp-block-categories-dropdown select:read-only,
.wp-block-archives-dropdown select:read-only,
.comment-respond .comment-form p.comment-form-author input:read-only,
.comment-respond .comment-form p.comment-form-email input:read-only,
.comment-respond .comment-form p.comment-form-url input:read-only,
.comment-respond .comment-form p textarea:read-only {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input::file-selector-button,
.comment-respond .comment-form p.comment-form-email input::file-selector-button,
.comment-respond .comment-form p.comment-form-url input::file-selector-button,
.comment-respond .comment-form p textarea::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212621;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button,
  .comment-respond
    .comment-form
    p.comment-form-author
    input::file-selector-button,
  .comment-respond
    .comment-form
    p.comment-form-email
    input::file-selector-button,
  .comment-respond .comment-form p.comment-form-url input::file-selector-button,
  .comment-respond .comment-form p textarea::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not(:read-only)::file-selector-button,
.wp-block-categories-dropdown
  select:hover:not(:disabled):not(:read-only)::file-selector-button,
.wp-block-archives-dropdown
  select:hover:not(:disabled):not(:read-only)::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input:hover:not(:disabled):not(:read-only)::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input:hover:not(:disabled):not(:read-only)::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input:hover:not(:disabled):not(:read-only)::file-selector-button,
.comment-respond
  .comment-form
  p
  textarea:hover:not(:disabled):not(:read-only)::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button,
.wp-block-categories-dropdown select::-webkit-file-upload-button,
.wp-block-archives-dropdown select::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-file-upload-button,
.comment-respond .comment-form p textarea::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212621;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button,
  .wp-block-categories-dropdown select::-webkit-file-upload-button,
  .wp-block-archives-dropdown select::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-author
    input::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-email
    input::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-url
    input::-webkit-file-upload-button,
  .comment-respond .comment-form p textarea::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.wp-block-categories-dropdown
  select:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.wp-block-archives-dropdown
  select:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p
  textarea:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #212621;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
.comment-respond .comment-form p textarea {
  min-height: calc(1.6em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not(:read-only) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212621;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.form-select:focus {
  border-color: #f79898;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212621;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #f79898;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.form-check-input:checked {
  background-color: #ee3131;
  border-color: #ee3131;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #ee3131;
  border-color: #ee3131;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f79898'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check[disabled] + .wp-block-button__link,
.btn-check:disabled + .btn,
.btn-check:disabled + .wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ee3131;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fac1c1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ee3131;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fac1c1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.comment-respond .comment-form p.comment-form-author .form-floating > input,
.comment-respond .comment-form p.comment-form-email .form-floating > input,
.comment-respond .comment-form p.comment-form-url .form-floating > input,
.comment-respond .comment-form p .form-floating > textarea,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::placeholder,
.wp-block-categories-dropdown .form-floating > select::placeholder,
.wp-block-archives-dropdown .form-floating > select::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input::placeholder,
.comment-respond .comment-form p .form-floating > textarea::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.wp-block-categories-dropdown .form-floating > select:focus,
.wp-block-archives-dropdown .form-floating > select:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:focus,
.comment-respond .comment-form p.comment-form-url .form-floating > input:focus,
.comment-respond .comment-form p .form-floating > textarea:focus,
.form-floating > .form-control:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:-webkit-autofill,
.comment-respond .comment-form p .form-floating > textarea:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.wp-block-categories-dropdown .form-floating > select:focus ~ label,
.wp-block-archives-dropdown .form-floating > select:focus ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:focus
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:focus
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:focus
  ~ label,
.comment-respond .comment-form p .form-floating > textarea:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.wp-block-categories-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.wp-block-archives-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:not(:placeholder-shown)
  ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:-webkit-autofill
  ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.wp-block-categories-dropdown .input-group > select,
.wp-block-archives-dropdown .input-group > select,
.comment-respond .comment-form p.comment-form-author .input-group > input,
.comment-respond .comment-form p.comment-form-email .input-group > input,
.comment-respond .comment-form p.comment-form-url .input-group > input,
.comment-respond .comment-form p .input-group > textarea,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.wp-block-categories-dropdown .input-group > select:focus,
.wp-block-archives-dropdown .input-group > select:focus,
.comment-respond .comment-form p.comment-form-author .input-group > input:focus,
.comment-respond .comment-form p.comment-form-email .input-group > input:focus,
.comment-respond .comment-form p.comment-form-url .input-group > input:focus,
.comment-respond .comment-form p .input-group > textarea:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn,
.input-group .wp-block-button__link {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .wp-block-button__link:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212621;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.comment-respond .comment-form p.comment-form-author .input-group-lg > input,
.comment-respond .comment-form p.comment-form-email .input-group-lg > input,
.comment-respond .comment-form p.comment-form-url .input-group-lg > input,
.comment-respond .comment-form p .input-group-lg > textarea,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.input-group-sm > .form-control,
.wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.comment-respond .comment-form p.comment-form-author .input-group-sm > input,
.comment-respond .comment-form p.comment-form-email .input-group-sm > input,
.comment-respond .comment-form p.comment-form-url .input-group-sm > input,
.comment-respond .comment-form p .input-group-sm > textarea,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #029e76;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(2, 158, 118, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated select:valid,
.was-validated .comment-respond .comment-form p.comment-form-author input:valid,
.comment-respond .comment-form p.comment-form-author .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-email input:valid,
.comment-respond .comment-form p.comment-form-email .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-url input:valid,
.comment-respond .comment-form p.comment-form-url .was-validated input:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
.form-control.is-valid,
.wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid,
.comment-respond .comment-form p.comment-form-author input.is-valid,
.comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p textarea.is-valid {
  border-color: #029e76;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated select:valid:focus,
.was-validated .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated select:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-url
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  input:valid:focus,
.was-validated .comment-respond .comment-form p textarea:valid:focus,
.comment-respond .comment-form p .was-validated textarea:valid:focus,
.form-control.is-valid:focus,
.wp-block-categories-dropdown select.is-valid:focus,
.wp-block-archives-dropdown select.is-valid:focus,
.comment-respond .comment-form p.comment-form-author input.is-valid:focus,
.comment-respond .comment-form p.comment-form-email input.is-valid:focus,
.comment-respond .comment-form p.comment-form-url input.is-valid:focus,
.comment-respond .comment-form p textarea.is-valid:focus {
  border-color: #029e76;
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
textarea.form-control.is-valid,
.comment-respond .comment-form p textarea.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #029e76;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23029e76' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #029e76;
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #029e76;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #029e76;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #029e76;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated .input-group select:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:valid,
.was-validated .input-group .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated .input-group textarea:valid,
.input-group .form-control.is-valid,
.input-group .wp-block-categories-dropdown select.is-valid,
.wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid,
.wp-block-archives-dropdown .input-group select.is-valid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-valid,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-email .input-group input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-valid,
.input-group .comment-respond .comment-form p textarea.is-valid,
.comment-respond .comment-form p .input-group textarea.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff5269;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(255, 82, 105, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated select:invalid,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:invalid,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:invalid,
.was-validated .comment-respond .comment-form p.comment-form-url input:invalid,
.comment-respond .comment-form p.comment-form-url .was-validated input:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
.form-control.is-invalid,
.wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid,
.comment-respond .comment-form p.comment-form-author input.is-invalid,
.comment-respond .comment-form p.comment-form-email input.is-invalid,
.comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p textarea.is-invalid {
  border-color: #ff5269;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5269'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5269' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated select:invalid:focus,
.was-validated .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated select:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-url
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  input:invalid:focus,
.was-validated .comment-respond .comment-form p textarea:invalid:focus,
.comment-respond .comment-form p .was-validated textarea:invalid:focus,
.form-control.is-invalid:focus,
.wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown select.is-invalid:focus,
.comment-respond .comment-form p.comment-form-author input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-email input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-url input.is-invalid:focus,
.comment-respond .comment-form p textarea.is-invalid:focus {
  border-color: #ff5269;
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
textarea.form-control.is-invalid,
.comment-respond .comment-form p textarea.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #ff5269;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5269'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5269' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #ff5269;
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #ff5269;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #ff5269;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff5269;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated .input-group select:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:invalid,
.was-validated .input-group .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated .input-group textarea:invalid,
.input-group .form-control.is-invalid,
.input-group .wp-block-categories-dropdown select.is-invalid,
.wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid,
.wp-block-archives-dropdown .input-group select.is-invalid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-invalid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input.is-invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .input-group
  input.is-invalid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-invalid,
.input-group .comment-respond .comment-form p textarea.is-invalid,
.comment-respond .comment-form p .input-group textarea.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

.btn,
.wp-block-button__link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #212621;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .wp-block-button__link {
    transition: none;
  }
}
.btn:hover,
.wp-block-button__link:hover {
  color: #212621;
}
.btn-check:focus + .btn,
.btn-check:focus + .wp-block-button__link,
.btn:focus,
.wp-block-button__link:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}
.btn:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary,
.wp-block-button__link {
  color: #000;
  background-color: #ee3131;
  border-color: #ee3131;
}
.btn-primary:hover,
.wp-block-button__link:hover {
  color: #000;
  background-color: #f15050;
  border-color: #f04646;
}
.btn-check:focus + .btn-primary,
.btn-check:focus + .wp-block-button__link,
.btn-primary:focus,
.wp-block-button__link:focus {
  color: #000;
  background-color: #f15050;
  border-color: #f04646;
  box-shadow: 0 0 0 0rem rgba(202, 42, 42, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-primary,
.btn-check:active + .wp-block-button__link,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary.active,
.active.wp-block-button__link,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.wp-block-button__link {
  color: #000;
  background-color: #f15a5a;
  border-color: #f04646;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:active + .wp-block-button__link:focus,
.btn-primary:active:focus,
.wp-block-button__link:active:focus,
.btn-primary.active:focus,
.active.wp-block-button__link:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(202, 42, 42, 0.5);
}
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary.disabled,
.disabled.wp-block-button__link {
  color: #000;
  background-color: #ee3131;
  border-color: #ee3131;
}

.btn-secondary {
  color: #000;
  background-color: #ff9446;
  border-color: #ff9446;
}
.btn-secondary:hover {
  color: #000;
  background-color: #ffa462;
  border-color: #ff9f59;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #000;
  background-color: #ffa462;
  border-color: #ff9f59;
  box-shadow: 0 0 0 0rem rgba(217, 126, 60, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #ffa96b;
  border-color: #ff9f59;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 126, 60, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #000;
  background-color: #ff9446;
  border-color: #ff9446;
}

.btn-success {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}
.btn-success:hover {
  color: #000;
  background-color: #28ad8b;
  border-color: #1ba884;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: #28ad8b;
  border-color: #1ba884;
  box-shadow: 0 0 0 0rem rgba(2, 134, 100, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #35b191;
  border-color: #1ba884;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(2, 134, 100, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}

.btn-info {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-info:hover {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #33beff;
  border-color: #1ab6ff;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-warning {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-warning:hover {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #feb733;
  border-color: #feae1a;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-danger {
  color: #000;
  background-color: #ff5269;
  border-color: #ff5269;
}
.btn-danger:hover {
  color: #000;
  background-color: #ff6c80;
  border-color: #ff6378;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #ff6c80;
  border-color: #ff6378;
  box-shadow: 0 0 0 0rem rgba(217, 70, 89, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff7587;
  border-color: #ff6378;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 70, 89, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
  color: #000;
  background-color: #ff5269;
  border-color: #ff5269;
}

.btn-light {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-light:hover {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #fbf9f5;
  border-color: #fbf9f3;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: #ee3131;
  border-color: #ee3131;
}
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  color: #000;
  background-color: #ee3131;
  border-color: #ee3131;
}
.btn-check:focus + .btn-outline-primary,
.is-style-outline .btn-check:focus + .wp-block-button__link,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.is-style-outline .btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-outline-primary,
.is-style-outline .btn-check:active + .wp-block-button__link,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary.active,
.is-style-outline .active.wp-block-button__link,
.btn-outline-primary.dropdown-toggle.show,
.is-style-outline .dropdown-toggle.show.wp-block-button__link {
  color: #000;
  background-color: #ee3131;
  border-color: #ee3131;
}
.btn-check:checked + .btn-outline-primary:focus,
.is-style-outline .btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-outline-primary:focus,
.is-style-outline .btn-check:active + .wp-block-button__link:focus,
.btn-outline-primary:active:focus,
.is-style-outline .wp-block-button__link:active:focus,
.btn-outline-primary.active:focus,
.is-style-outline .active.wp-block-button__link:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.is-style-outline .dropdown-toggle.show.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.5);
}
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link {
  color: #ee3131;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #ff9446;
  border-color: #ff9446;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #ff9446;
  border-color: #ff9446;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0rem rgba(255, 148, 70, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #ff9446;
  border-color: #ff9446;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 148, 70, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #ff9446;
  background-color: transparent;
}

.btn-outline-success {
  color: #029e76;
  border-color: #029e76;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #029e76;
  border-color: #029e76;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(2, 158, 118, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #029e76;
  background-color: transparent;
}

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #00aeff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fea500;
  border-color: #fea500;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fea500;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ff5269;
  border-color: #ff5269;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #ff5269;
  border-color: #ff5269;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #ff5269;
  border-color: #ff5269;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 82, 105, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #ff5269;
  background-color: transparent;
}

.btn-outline-light {
  color: #faf8f2;
  border-color: #faf8f2;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #faf8f2;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #ee3131;
  text-decoration: underline;
}
.btn-link:hover {
  color: #be2727;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 1rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212621;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ee3131;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #ee3131;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group > .wp-block-button__link,
.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:checked + .wp-block-button__link,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .wp-block-button__link,
.btn-group > .btn:hover,
.btn-group > .wp-block-button__link:hover,
.btn-group > .btn:focus,
.btn-group > .wp-block-button__link:focus,
.btn-group > .btn:active,
.btn-group > .wp-block-button__link:active,
.btn-group > .btn.active,
.btn-group > .active.wp-block-button__link,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .wp-block-button__link,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .wp-block-button__link,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .wp-block-button__link:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .wp-block-button__link:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .wp-block-button__link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > .wp-block-button__link:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-group-sm > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-lg > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .wp-block-button__link:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical
  > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .wp-block-button__link ~ .btn,
.btn-group-vertical > .btn ~ .wp-block-button__link,
.btn-group-vertical > .wp-block-button__link ~ .wp-block-button__link,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ee3131;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #be2727;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ee3131;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212621;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #d62c2c;
  background-color: #fdeaea;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d62c2c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212621'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #f79898;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #ee3131;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #be2727;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #be2727;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ee3131;
  border-color: #ee3131;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #8f1d1d;
  background-color: #fcd6d6;
  border-color: #fac1c1;
}
.alert-primary .alert-link {
  color: #721717;
}

.alert-secondary {
  color: #99592a;
  background-color: #ffeada;
  border-color: #ffdfc8;
}
.alert-secondary .alert-link {
  color: #7a4722;
}

.alert-success {
  color: #015f47;
  background-color: #ccece4;
  border-color: #b3e2d6;
}
.alert-success .alert-link {
  color: #014c39;
}

.alert-info {
  color: #006899;
  background-color: #ccefff;
  border-color: #b3e7ff;
}
.alert-info .alert-link {
  color: #00537a;
}

.alert-warning {
  color: #664200;
  background-color: #ffedcc;
  border-color: #ffe4b3;
}
.alert-warning .alert-link {
  color: #523500;
}

.alert-danger {
  color: #99313f;
  background-color: #ffdce1;
  border-color: #ffcbd2;
}
.alert-danger .alert-link {
  color: #7a2732;
}

.alert-light {
  color: #646361;
  background-color: #fefefc;
  border-color: #fefdfb;
}
.alert-light .alert-link {
  color: #504f4e;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ee3131;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212621;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ee3131;
  border-color: #ee3131;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #8f1d1d;
  background-color: #fcd6d6;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #8f1d1d;
  background-color: #e3c1c1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #8f1d1d;
  border-color: #8f1d1d;
}

.list-group-item-secondary {
  color: #99592a;
  background-color: #ffeada;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #99592a;
  background-color: #e6d3c4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #99592a;
  border-color: #99592a;
}

.list-group-item-success {
  color: #015f47;
  background-color: #ccece4;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #015f47;
  background-color: #b8d4cd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #015f47;
  border-color: #015f47;
}

.list-group-item-info {
  color: #006899;
  background-color: #ccefff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #006899;
  background-color: #b8d7e6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #006899;
  border-color: #006899;
}

.list-group-item-warning {
  color: #664200;
  background-color: #ffedcc;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664200;
  background-color: #e6d5b8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664200;
  border-color: #664200;
}

.list-group-item-danger {
  color: #99313f;
  background-color: #ffdce1;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #99313f;
  background-color: #e6c6cb;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #99313f;
  border-color: #99313f;
}

.list-group-item-light {
  color: #646361;
  background-color: #fefefc;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #646361;
  background-color: #e5e5e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #646361;
  border-color: #646361;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(238, 49, 49, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1f0;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e1f0;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212621;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #ee3131;
}
.link-primary:hover,
.link-primary:focus {
  color: #f15a5a;
}

.link-secondary {
  color: #ff9446;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #ffa96b;
}

.link-success {
  color: #029e76;
}
.link-success:hover,
.link-success:focus {
  color: #35b191;
}

.link-info {
  color: #00aeff;
}
.link-info:hover,
.link-info:focus {
  color: #33beff;
}

.link-warning {
  color: #fea500;
}
.link-warning:hover,
.link-warning:focus {
  color: #feb733;
}

.link-danger {
  color: #ff5269;
}
.link-danger:hover,
.link-danger:focus {
  color: #ff7587;
}

.link-light {
  color: #faf8f2;
}
.link-light:hover,
.link-light:focus {
  color: #fbf9f5;
}

.link-dark {
  color: #212529;
}
.link-dark:hover,
.link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e1e1f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e1e1f0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #e1e1f0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1f0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #e1e1f0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ee3131 !important;
}

.border-secondary {
  border-color: #ff9446 !important;
}

.border-success {
  border-color: #029e76 !important;
}

.border-info {
  border-color: #00aeff !important;
}

.border-warning {
  border-color: #fea500 !important;
}

.border-danger {
  border-color: #ff5269 !important;
}

.border-light {
  border-color: #faf8f2 !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-2 {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: calc(1.2625rem + 0.15vw) !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #ee3131 !important;
}

.text-secondary {
  color: #ff9446 !important;
}

.text-success {
  color: #029e76 !important;
}

.text-info {
  color: #00aeff !important;
}

.text-warning {
  color: #fea500 !important;
}

.text-danger {
  color: #ff5269 !important;
}

.text-light {
  color: #faf8f2 !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212621 !important;
}

.text-muted {
  color: #aeaed5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #ee3131 !important;
}

.bg-secondary {
  background-color: #ff9446 !important;
}

.bg-success {
  background-color: #029e76 !important;
}

.bg-info {
  background-color: #00aeff !important;
}

.bg-warning {
  background-color: #fea500 !important;
}

.bg-danger {
  background-color: #ff5269 !important;
}

.bg-light {
  background-color: #faf8f2 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #ffffff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.75rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 4rem !important;
  }
  .fs-2 {
    font-size: 3rem !important;
  }
  .fs-3 {
    font-size: 2rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
  .fs-5 {
    font-size: 1.375rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.6;
  color: #212621;
  font-size: 16px;
  font-family: var(--font-family-base);
}
body.fixed {
  overflow: hidden;
  position: fixed;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-base);
  font-weight: 700;
  color: var(--title);
}

h1,
.h1,
.h1 {
  line-height: 1.3;
}
@media only screen and (max-width: 1400px) {
  h1,
  .h1,
  .h1 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 991px) {
  h1,
  .h1,
  .h1 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  h1,
  .h1,
  .h1 {
    font-size: 1.875rem;
  }
}

h2,
.h2,
.h2 {
  line-height: 1.4;
}
@media only screen and (max-width: 1400px) {
  h2,
  .h2,
  .h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 991px) {
  h2,
  .h2,
  .h2 {
    font-size: 1.75rem;
  }
}
@media only screen and (max-width: 767px) {
  h2,
  .h2,
  .h2 {
    font-size: 1.5rem;
  }
}

h3,
.h3,
.h3 {
  line-height: 1.4;
}
@media only screen and (max-width: 991px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.375rem;
  }
}

h4,
.h4,
.h4 {
  line-height: 1.4;
}
@media only screen and (max-width: 767px) {
  h4,
  .h4,
  .h4 {
    font-size: 1.25rem;
  }
}

h5,
.h5,
.h5 {
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  h5,
  .h5,
  .h5 {
    font-size: 1.125rem;
  }
}

h6,
.h6,
.h6 {
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  h6,
  .h6,
  .h6 {
    font-size: 1rem;
  }
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: var(--primary-hover);
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

b,
strong,
.strong {
  font-weight: 500;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 60px;
  padding-right: 60px;
}
@media only screen and (max-width: 1680px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 1280px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bg-gray {
  background-color: #f8f8f8;
}

::selection {
  color: #fff;
  background: var(--primary);
}

@media only screen and (min-width: 1400px) {
  .row.sp60,
  .sp60 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .row.sp60 [class*="col-"],
  .sp60 [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 1400px) {
  .row.sp50,
  .sp50 {
    margin-left: -25px;
    margin-right: -25px;
  }
  .row.sp50 [class*="col-"],
  .sp50 [class*="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}
.row.sp40 [class*="col-"],
.sp40 [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.sp20 [class*="col-"],
.sp20 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.sp16 [class*="col-"],
.sp16 [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row.sp10 [class*="col-"],
.sp10 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp4,
.sp4 {
  margin-left: -2px;
  margin-right: -2px;
}
.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}
.row.spno [class*="col-"],
.spno [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 100000;
}

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon";
}

@media only screen and (max-width: 991px) {
  .dlab-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

#pointer-dot {
  left: -4px;
  top: -4px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: #333;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  border: 1px solid var(--primary) !important;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}

#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

#pointer-ring.active + #pointer-dot {
  opacity: 0;
}

.content-inner {
  padding-top: 120px;
  padding-bottom: 90px;
}
@media only screen and (max-width: 991px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.content-inner-1 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 991px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content-inner-2 {
  padding-top: 120px;
  padding-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.content-inner-4 {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 991px) {
  .content-inner-4 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.mfp-bg {
  z-index: 99999;
}

.mfp-wrap {
  z-index: 100000;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background: #fff;
}
#loading-area.loading-page-1 {
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
}
#loading-area.loading-page-1 img,
#loading-area.loading-page-1 svg {
  width: 140px;
}
#loading-area.loading-page-2 {
  background-image: url(../images/loading.gif);
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: center;
}
#loading-area.loading-page-3 {
  background-image: url(../images/loader1.gif);
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: center;
}

.spinner {
  width: 100px;
  height: 60px;
  text-align: center;
}
.spinner .ball {
  width: 30px;
  height: 30px;
  background-color: var(--primary);
  border-radius: 50%;
  display: inline-block;
  animation: motion 3s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}
.spinner p {
  margin-top: 5px;
  letter-spacing: 3px;
  font-size: 16px;
  font-weight: 600;
  color: var(--title);
}
.spinner p:after {
  content: "";
  animation: dots 1s infinite;
}

@keyframes dots {
  0% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

@keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: translateX(-50px) scale(0.3);
  }
  50% {
    transform: translateX(0) scale(1);
  }
  75% {
    transform: translateX(50px) scale(0.3);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-75 {
  font-size: 75px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.p-a0 {
  padding: 0;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-t0 {
  padding-top: 0;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b0 {
  padding-bottom: 0;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l0 {
  padding-left: 0;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r0 {
  padding-right: 0;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-auto {
  margin: auto;
}

.m-a0 {
  margin: 0;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-t0 {
  margin-top: 0;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b0 {
  margin-bottom: 0;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l0 {
  margin-left: 0;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r0 {
  margin-right: 0;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media only screen and (max-width: 1400px) {
  .m-lp-30 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0;
  }
  .m-lg-t5 {
    margin-top: 5px;
  }
  .m-lg-t10 {
    margin-top: 10px;
  }
  .m-lg-t15 {
    margin-top: 15px;
  }
  .m-lg-t20 {
    margin-top: 20px;
  }
  .m-lg-t30 {
    margin-top: 30px;
  }
  .m-lg-t40 {
    margin-top: 40px;
  }
  .m-lg-t50 {
    margin-top: 50px;
  }
  .m-lg-t60 {
    margin-top: 60px;
  }
  .m-lg-t70 {
    margin-top: 70px;
  }
  .m-lg-t80 {
    margin-top: 80px;
  }
  .m-lg-t90 {
    margin-top: 90px;
  }
  .m-lg-t100 {
    margin-top: 100px;
  }
  .m-lg-b0 {
    margin-bottom: 0;
  }
  .m-lg-b5 {
    margin-bottom: 5px;
  }
  .m-lg-b10 {
    margin-bottom: 10px;
  }
  .m-lg-b15 {
    margin-bottom: 15px;
  }
  .m-lg-b20 {
    margin-bottom: 20px;
  }
  .m-lg-b30 {
    margin-bottom: 30px;
  }
  .m-lg-b40 {
    margin-bottom: 40px;
  }
  .m-lg-b50 {
    margin-bottom: 50px;
  }
  .m-lg-b60 {
    margin-bottom: 60px;
  }
  .m-lg-b70 {
    margin-bottom: 70px;
  }
  .m-lg-b80 {
    margin-bottom: 80px;
  }
  .m-lg-b90 {
    margin-bottom: 90px;
  }
  .m-lg-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0;
  }
  .m-md-t5 {
    margin-top: 5px;
  }
  .m-md-t10 {
    margin-top: 10px;
  }
  .m-md-t15 {
    margin-top: 15px;
  }
  .m-md-t20 {
    margin-top: 20px;
  }
  .m-md-t30 {
    margin-top: 30px;
  }
  .m-md-t40 {
    margin-top: 40px;
  }
  .m-md-t50 {
    margin-top: 50px;
  }
  .m-md-t60 {
    margin-top: 60px;
  }
  .m-md-t70 {
    margin-top: 70px;
  }
  .m-md-t80 {
    margin-top: 80px;
  }
  .m-md-t90 {
    margin-top: 90px;
  }
  .m-md-t100 {
    margin-top: 100px;
  }
  .m-md-b0 {
    margin-bottom: 0;
  }
  .m-md-b5 {
    margin-bottom: 5px;
  }
  .m-md-b10 {
    margin-bottom: 10px;
  }
  .m-md-b15 {
    margin-bottom: 15px;
  }
  .m-md-b20 {
    margin-bottom: 20px;
  }
  .m-md-b30 {
    margin-bottom: 30px;
  }
  .m-md-b40 {
    margin-bottom: 40px;
  }
  .m-md-b50 {
    margin-bottom: 50px;
  }
  .m-md-b60 {
    margin-bottom: 60px;
  }
  .m-md-b70 {
    margin-bottom: 70px;
  }
  .m-md-b80 {
    margin-bottom: 80px;
  }
  .m-md-b90 {
    margin-bottom: 90px;
  }
  .m-md-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0;
  }
  .m-sm-t5 {
    margin-top: 5px;
  }
  .m-sm-t10 {
    margin-top: 10px;
  }
  .m-sm-t15 {
    margin-top: 15px;
  }
  .m-sm-t20 {
    margin-top: 20px;
  }
  .m-sm-t30 {
    margin-top: 30px;
  }
  .m-sm-t40 {
    margin-top: 40px;
  }
  .m-sm-t50 {
    margin-top: 50px;
  }
  .m-sm-t60 {
    margin-top: 60px;
  }
  .m-sm-t70 {
    margin-top: 70px;
  }
  .m-sm-t80 {
    margin-top: 80px;
  }
  .m-sm-t90 {
    margin-top: 90px;
  }
  .m-sm-t100 {
    margin-top: 100px;
  }
  .m-sm-b0 {
    margin-bottom: 0;
  }
  .m-sm-b5 {
    margin-bottom: 5px;
  }
  .m-sm-b10 {
    margin-bottom: 10px;
  }
  .m-sm-b15 {
    margin-bottom: 15px;
  }
  .m-sm-b20 {
    margin-bottom: 20px;
  }
  .m-sm-b30 {
    margin-bottom: 30px;
  }
  .m-sm-b40 {
    margin-bottom: 40px;
  }
  .m-sm-b50 {
    margin-bottom: 50px;
  }
  .m-sm-b60 {
    margin-bottom: 60px;
  }
  .m-sm-b70 {
    margin-bottom: 70px;
  }
  .m-sm-b80 {
    margin-bottom: 80px;
  }
  .m-sm-b90 {
    margin-bottom: 90px;
  }
  .m-sm-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .p-sm-a0 {
    padding: 0;
  }
  .m-xs-t0 {
    margin-top: 0;
  }
  .m-xs-t5 {
    margin-top: 5px;
  }
  .m-xs-t10 {
    margin-top: 10px;
  }
  .m-xs-t15 {
    margin-top: 15px;
  }
  .m-xs-t20 {
    margin-top: 20px;
  }
  .m-xs-t30 {
    margin-top: 30px;
  }
  .m-xs-t40 {
    margin-top: 40px;
  }
  .m-xs-t50 {
    margin-top: 50px;
  }
  .m-xs-t60 {
    margin-top: 60px;
  }
  .m-xs-t70 {
    margin-top: 70px;
  }
  .m-xs-t80 {
    margin-top: 80px;
  }
  .m-xs-t90 {
    margin-top: 90px;
  }
  .m-xs-t100 {
    margin-top: 100px;
  }
  .m-xs-b0 {
    margin-bottom: 0;
  }
  .m-xs-b5 {
    margin-bottom: 5px;
  }
  .m-xs-b10 {
    margin-bottom: 10px;
  }
  .m-xs-b15 {
    margin-bottom: 15px;
  }
  .m-xs-b20 {
    margin-bottom: 20px;
  }
  .m-xs-b30 {
    margin-bottom: 30px;
  }
  .m-xs-b40 {
    margin-bottom: 40px;
  }
  .m-xs-b50 {
    margin-bottom: 50px;
  }
  .m-xs-b60 {
    margin-bottom: 60px;
  }
  .m-xs-b70 {
    margin-bottom: 70px;
  }
  .m-xs-b80 {
    margin-bottom: 80px;
  }
  .m-xs-b90 {
    margin-bottom: 90px;
  }
  .m-xs-b100 {
    margin-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: unset;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.sticky-top {
  top: 120px;
}
.sticky-top.nav-tabs-top {
  top: 200px;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center;
}
.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272a8;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}
.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
  z-index: 1;
}

.section-head {
  margin-bottom: 40px;
}
@media only screen and (max-width: 1280px) {
  .section-head {
    margin-bottom: 30px;
  }
}
.section-head h1,
.section-head .h1,
.section-head h2,
.section-head .h2,
.section-head h3,
.section-head .h3,
.section-head h4,
.section-head .h4,
.section-head h5,
.section-head .h5,
.section-head h6,
.section-head .h6 {
  font-family: var(--font-family-title);
}
.section-head p {
  font-size: 18px;
}
.section-head.text-center p {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}
.section-head .sub-title {
  letter-spacing: 0.5em;
}
.section-head .sub-title.style-1 {
  letter-spacing: 0;
  color: #595959;
}
.section-head.space-lg {
  margin-bottom: 60px;
}
@media only screen and (max-width: 991px) {
  .section-head.space-lg {
    margin-bottom: 40px;
  }
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}
.row.spno [class*="col"],
.row.spno [class*="col"],
.spno [class*="col"],
.spno [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px;
}
.row.sp4 [class*="col"],
.row.sp4 [class*="col"],
.sp4 [class*="col"],
.sp4 [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px;
}
.row.sp15 [class*="col"],
.row.sp15 [class*="col"],
.sp15 [class*="col"],
.sp15 [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.sp10,
.sp10 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.sp10 [class*="col"],
.row.sp10 [class*="col"],
.sp10 [class*="col"],
.sp10 [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.rounded-xl {
  border-radius: 50% !important;
}

.rounded-lg {
  border-radius: 20px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-sm {
  border-radius: 5px !important;
}

.text-maroon {
  color: #9e0168;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffa808;
}

.text-skyblue {
  color: #029e76;
}

.text-red {
  color: #ff586e;
}

.text-green {
  color: #029e76;
}

.text-blue {
  color: #5543d1;
}

.bg-maroon {
  background-color: #9e0168;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffa808;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #ff586e;
}

.bg-green {
  background-color: #029e76;
}

.bg-blue {
  background-color: #5543d1;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.card {
  border: 0px solid #e1e1f0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1f0;
  background: #fff;
  padding: 20px 30px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 20px 30px;
  background: #fff;
  border-radius: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #212621;
  margin-bottom: 10px;
  background: transparent;
  border-color: #e1e1f0;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  height: 70px;
  border: 1px solid #ebebeb;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
  color: #212621;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-radius: var(--border-radius-base);
}
.form-control .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select,
.comment-respond .comment-form p.comment-form-author input .select,
.comment-respond .comment-form p.comment-form-email input .select,
.comment-respond .comment-form p.comment-form-url input .select,
.comment-respond .comment-form p textarea .select {
  padding: 12px 0;
}
.form-control span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span,
.comment-respond .comment-form p.comment-form-author input span,
.comment-respond .comment-form p.comment-form-email input span,
.comment-respond .comment-form p.comment-form-url input span,
.comment-respond .comment-form p textarea span {
  margin-top: 0;
}
.form-control::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-input-placeholder,
.comment-respond .comment-form p textarea::-webkit-input-placeholder {
  color: #212621;
}
.form-control:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-email input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-url input:-ms-input-placeholder,
.comment-respond .comment-form p textarea:-ms-input-placeholder {
  color: #212621;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
  color: #212621;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus,
.form-control:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p textarea:active,
.form-control.active,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active,
.comment-respond .comment-form p.comment-form-author input.active,
.comment-respond .comment-form p.comment-form-email input.active,
.comment-respond .comment-form p.comment-form-url input.active,
.comment-respond .comment-form p textarea.active {
  border-color: var(--primary);
}
.form-control.sm,
.wp-block-categories-dropdown select.sm,
.wp-block-archives-dropdown select.sm,
.comment-respond .comment-form p.comment-form-author input.sm,
.comment-respond .comment-form p.comment-form-email input.sm,
.comment-respond .comment-form p.comment-form-url input.sm,
.comment-respond .comment-form p textarea.sm {
  height: 56px;
  font-size: 18px;
}
.form-control.sm .btn,
.wp-block-categories-dropdown select.sm .btn,
.wp-block-archives-dropdown select.sm .btn,
.comment-respond .comment-form p.comment-form-author input.sm .btn,
.comment-respond .comment-form p.comment-form-email input.sm .btn,
.comment-respond .comment-form p.comment-form-url input.sm .btn,
.comment-respond .comment-form p textarea.sm .btn,
.form-control.sm .wp-block-button__link,
.wp-block-categories-dropdown select.sm .wp-block-button__link,
.wp-block-archives-dropdown select.sm .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-author
  input.sm
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-email
  input.sm
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-url
  input.sm
  .wp-block-button__link,
.comment-respond .comment-form p textarea.sm .wp-block-button__link {
  font-size: 16px;
  padding: 16px 20px;
}
.form-control .btn,
.wp-block-categories-dropdown select .btn,
.wp-block-archives-dropdown select .btn,
.comment-respond .comment-form p.comment-form-author input .btn,
.comment-respond .comment-form p.comment-form-email input .btn,
.comment-respond .comment-form p.comment-form-url input .btn,
.comment-respond .comment-form p textarea .btn,
.form-control .wp-block-button__link,
.wp-block-categories-dropdown select .wp-block-button__link,
.wp-block-archives-dropdown select .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-author
  input
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-email
  input
  .wp-block-button__link,
.comment-respond .comment-form p.comment-form-url input .wp-block-button__link,
.comment-respond .comment-form p textarea .wp-block-button__link {
  border: 0;
  font-size: 18px;
  padding: 23px 25px;
  box-shadow: none;
  background-color: #ebebeb;
  color: #575757;
}
.form-control .btn:focus,
.wp-block-categories-dropdown select .btn:focus,
.wp-block-archives-dropdown select .btn:focus,
.comment-respond .comment-form p.comment-form-author input .btn:focus,
.comment-respond .comment-form p.comment-form-email input .btn:focus,
.comment-respond .comment-form p.comment-form-url input .btn:focus,
.comment-respond .comment-form p textarea .btn:focus,
.form-control .wp-block-button__link:focus,
.wp-block-categories-dropdown select .wp-block-button__link:focus,
.wp-block-archives-dropdown select .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  input
  .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  input
  .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  input
  .wp-block-button__link:focus,
.comment-respond .comment-form p textarea .wp-block-button__link:focus {
  outline: 0 !important;
}
.form-control .btn:hover,
.wp-block-categories-dropdown select .btn:hover,
.wp-block-archives-dropdown select .btn:hover,
.comment-respond .comment-form p.comment-form-author input .btn:hover,
.comment-respond .comment-form p.comment-form-email input .btn:hover,
.comment-respond .comment-form p.comment-form-url input .btn:hover,
.comment-respond .comment-form p textarea .btn:hover,
.form-control .wp-block-button__link:hover,
.wp-block-categories-dropdown select .wp-block-button__link:hover,
.wp-block-archives-dropdown select .wp-block-button__link:hover,
.comment-respond
  .comment-form
  p.comment-form-author
  input
  .wp-block-button__link:hover,
.comment-respond
  .comment-form
  p.comment-form-email
  input
  .wp-block-button__link:hover,
.comment-respond
  .comment-form
  p.comment-form-url
  input
  .wp-block-button__link:hover,
.comment-respond .comment-form p textarea .wp-block-button__link:hover {
  color: #575757 !important;
  background-color: #ebebeb;
}
.form-control .dropdown-toggle:after,
.wp-block-categories-dropdown select .dropdown-toggle:after,
.wp-block-archives-dropdown select .dropdown-toggle:after,
.comment-respond
  .comment-form
  p.comment-form-author
  input
  .dropdown-toggle:after,
.comment-respond
  .comment-form
  p.comment-form-email
  input
  .dropdown-toggle:after,
.comment-respond .comment-form p.comment-form-url input .dropdown-toggle:after,
.comment-respond .comment-form p textarea .dropdown-toggle:after {
  border-width: 0.4em 0.4em 0px;
}
.form-control.dropup .dropdown-toggle:after,
.wp-block-categories-dropdown select.dropup .dropdown-toggle:after,
.wp-block-archives-dropdown select.dropup .dropdown-toggle:after,
.comment-respond
  .comment-form
  p.comment-form-author
  input.dropup
  .dropdown-toggle:after,
.comment-respond
  .comment-form
  p.comment-form-email
  input.dropup
  .dropdown-toggle:after,
.comment-respond
  .comment-form
  p.comment-form-url
  input.dropup
  .dropdown-toggle:after,
.comment-respond .comment-form p textarea.dropup .dropdown-toggle:after {
  border-width: 0px 0.4em 0.4em;
}
@media only screen and (max-width: 991px) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select,
  .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p textarea {
    height: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select,
  .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p textarea {
    height: 70px;
  }
}

.form-select {
  background-position: right 1.2rem center;
  background-size: 18px 30px;
}

textarea.form-control,
.comment-respond .comment-form p textarea {
  min-height: 160px;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #212621;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: var(--primary);
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #e1e1f0;
  border-radius: 5px;
  color: #aeaed5;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}
.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #fff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #da1212;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #212621;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #212621;
  font-size: 0.875rem;
  text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #fabebe;
  color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #212621;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #ff5269;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #212529;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #212529;
  color: var(--primary);
}

.form-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}

.form-select {
  border: 1px solid transparent;
}
.form-select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
}
.form-select .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0;
}
.form-select .dropdown-toggle:after {
  content: none;
}
.form-select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.form-select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
.form-select .dropdown-menu ul li {
  padding: 0;
}
.form-select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}
.form-select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.form-select:focus,
.form-select:active,
.form-select.active {
  border-color: var(--primary);
}

.form-select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}
.form-select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}
.form-select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group
  .wp-block-categories-dropdown
  select
  .dropdown-toggle.btn-default,
.wp-block-categories-dropdown
  .form-select.btn-group
  select
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .wp-block-archives-dropdown
  select
  .dropdown-toggle.btn-default,
.wp-block-archives-dropdown
  .form-select.btn-group
  select
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p
  textarea
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p
  .form-select.btn-group
  textarea
  .dropdown-toggle.btn-default {
  padding: 0;
}
.form-select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.form-select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}
.custom-file-label:after {
  content: none !important;
}

.dlab-form.radius-no .form-control,
.dlab-form.radius-no .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dlab-form.radius-no select,
.dlab-form.radius-no .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dlab-form.radius-no select,
.dlab-form.radius-no .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .dlab-form.radius-no input,
.dlab-form.radius-no .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .dlab-form.radius-no input,
.dlab-form.radius-no .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .dlab-form.radius-no input,
.dlab-form.radius-no .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dlab-form.radius-no textarea {
  border-radius: 0;
}

.dlab-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px;
}

.form-check.style-1 .form-check-input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check.style-1 .form-check-input[type="checkbox"]:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link {
  padding: 12px 20px;
  display: inline-flex;
  border-radius: var(--border-radius-base);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  line-height: 1.5;
  box-shadow: 0 5px 15px -10px var(--primary);
  align-items: center;
  overflow: hidden;
}
.btn.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link,
.btn-lg.wp-block-button__link {
  padding: 20px 30px;
  font-size: 16px;
}
@media only screen and (max-width: 1280px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 18px 25px;
  }
}
.btn.btn-md,
.btn-md.wp-block-button__link {
  padding: 15px 30px;
  font-size: 16px;
}
.btn.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link,
.btn-sm.wp-block-button__link {
  font-size: 14px;
  padding: 8px 15px;
}
.btn.btn-xs,
.btn-xs.wp-block-button__link {
  font-size: 14px;
  padding: 11px 25px;
}
@media only screen and (max-width: 767px) {
  .btn,
  .wp-block-button__link {
    padding: 14px 25px;
    font-size: 14px;
  }
}
.btn.btn-primary,
.wp-block-button__link {
  color: #fff;
}
.btn.btn-primary:hover,
.wp-block-button__link:hover {
  color: #fff;
}
.btn i,
.wp-block-button__link i {
  font-size: 24px;
  line-height: 1;
}
.btn.btn-white,
.btn-white.wp-block-button__link {
  background-color: #fff;
  color: var(--primary);
  font-weight: 600;
}
.btn.btn-outline-white,
.btn-outline-white.wp-block-button__link {
  color: #fff;
  border: 1px solid #fff;
}
.btn.effect-1,
.effect-1.wp-block-button__link {
  z-index: 1;
}
.btn.effect-1:before,
.effect-1.wp-block-button__link:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
}
.btn.effect-1:after,
.effect-1.wp-block-button__link:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn.effect-1 span,
.effect-1.wp-block-button__link span {
  display: block;
  position: relative;
  z-index: 2;
}
.btn.effect-1:hover:before,
.effect-1.wp-block-button__link:hover:before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}
.btn.effect-1:hover:after,
.effect-1.wp-block-button__link:hover:after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}
.btn.effect-1:hover span,
.effect-1.wp-block-button__link:hover span {
  animation: MoveScaleUpInitial 0.3s forwards, MoveScaleUpEnd 0.3s forwards 0.3s;
  color: var(--primary);
}

.btn-primary.light,
.light.wp-block-button__link {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}
.btn-primary.light.effect-1:after,
.light.effect-1.wp-block-button__link:after {
  background-color: var(--primary);
}
.btn-primary.light.effect-1:before,
.light.effect-1.wp-block-button__link:before {
  background-color: var(--primary);
}
.btn-primary.light.effect-1:hover,
.light.effect-1.wp-block-button__link:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #fff;
}
.btn-primary.light.effect-1:hover span,
.light.effect-1.wp-block-button__link:hover span {
  color: #fff;
}

@keyframes MoveScaleUpInitial {
  to {
    transform: translate3d(0, -105%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
}

@keyframes MoveScaleUpEnd {
  from {
    transform: translate3d(0, 100%, 0) scale3d(1, 2, 1);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.btn-lg i,
.btn-group-lg > .btn i,
.btn-group-lg > .wp-block-button__link i {
  font-size: 24px;
}

.btn-primary,
.wp-block-button__link {
  border-color: var(--primary);
  background-color: var(--primary);
}
.btn-primary.disabled,
.disabled.wp-block-button__link,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.btn-primary:hover,
.wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #fff;
}

.btn-link-lg {
  font-weight: 600;
  font-size: 18px;
}
.btn-link-lg i {
  transform: scale(1.5);
  margin-left: 20px;
}
.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light {
  background-color: #fff;
}
.btn-light:hover {
  background-color: var(--primary);
  color: #fff !important;
  border-color: transparent;
}
.btn-light.text-primary:hover {
  color: #fff !important;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}
.wp-block-button__link:after {
  content: none;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}
.table tr td,
.table tr th {
  padding: 15px;
}

.table tr th {
  color: #000;
  font-weight: 500;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #e1e1f0;
}
.table-striped tfoot tr th {
  padding-top: 10px;
}

.dlab-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}
.dlab-separator.m-b0 {
  margin-bottom: 0;
}
.dlab-separator.style-liner {
  width: 20px;
}
.dlab-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}
.dlab-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}
.dlab-separator.style-skew[class*="style-"]:before {
  right: 20px;
  left: auto;
}
.dlab-separator.bnr-title {
  height: 1px;
  width: 155px;
  opacity: 0.5;
}
.dlab-separator.bnr-title:before,
.dlab-separator.bnr-title:after {
  position: absolute;
  content: "";
  background-color: inherit;
}
.dlab-separator.bnr-title:before {
  height: inherit;
  right: -80px;
  width: 25px;
}
.dlab-separator.bnr-title:after {
  height: inherit;
  right: -90px;
  top: 0;
  width: 6px;
}
.dlab-separator.bnr-title i {
  background-color: inherit;
  display: block;
  height: inherit;
  position: absolute;
  right: -50px;
  width: 45px;
}

.dlab-separator-outer {
  overflow: hidden;
}

.dlab-separator.style-1 {
  height: 5px;
  display: block;
  width: 82px;
  position: relative;
  margin: 0;
}
.dlab-separator.style-1:before,
.dlab-separator.style-1:after {
  content: "";
  height: 1px;
  width: 80px;
  position: absolute;
  background: var(--primary);
  left: 0;
}
.dlab-separator.style-1:after {
  width: 80%;
  bottom: 0;
}
.dlab-separator.style-1:before {
  width: 100%;
  top: 0;
}

.dlab-separator.style-2 {
  width: 45px;
  height: 2px;
  background-color: #000;
}

.site-filters {
  margin-bottom: 30px;
}
.site-filters ul {
  margin: 0;
  list-style: none;
}
.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}
.site-filters li.btn,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}
.site-filters li input {
  display: none;
}
.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}
.site-filters li.active [class*="btn"] {
  color: #fff;
  background-color: #efbb20;
}
.site-filters.center {
  text-align: center;
}
.site-filters.center ul {
  display: inline-block;
  margin: auto;
}
.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}
.site-filters.style-1 {
  margin-bottom: 45px;
}
.site-filters.style-1 .filters li {
  text-transform: capitalize !important;
  margin: 0 20px -1px;
  border: 0;
}
.site-filters.style-1 .filters li:after {
  content: none;
}
.site-filters.style-1 .filters li a {
  color: #000;
  font-size: 18px;
  margin: 0;
  border-bottom: 2px solid;
  border-color: transparent;
  padding: 0 2px 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.site-filters.style-1 .filters li.active a {
  color: var(--primary);
  border-color: var(--primary);
}
.site-filters.style-1 .filters li:first-child {
  margin-left: 0;
}
.site-filters.style-1 .filters li:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 991px) {
  .site-filters.style-1 .filters li {
    margin: 0 5px -1px;
  }
  .site-filters.style-1 .filters li a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .site-filters.style-1 .filters li {
    margin: 0 0px -6px;
  }
  .site-filters.style-1 .filters li a {
    font-size: 14px;
  }
}

.dlab-img-overlay1,
.dlab-img-overlay2,
.dlab-img-overlay3,
.dlab-img-overlay4,
.dlab-img-overlay5,
.dlab-img-overlay6,
.dlab-img-overlay7,
.dlab-img-overlay8,
.dlab-img-overlay9,
.dlab-img-overlay11,
.dlab-img-overlay12 {
  position: relative;
}
.dlab-img-overlay1:before,
.dlab-img-overlay1:after,
.dlab-img-overlay2:before,
.dlab-img-overlay2:after,
.dlab-img-overlay3:before,
.dlab-img-overlay3:after,
.dlab-img-overlay4:before,
.dlab-img-overlay4:after,
.dlab-img-overlay5:before,
.dlab-img-overlay5:after,
.dlab-img-overlay6:before,
.dlab-img-overlay6:after,
.dlab-img-overlay7:before,
.dlab-img-overlay7:after,
.dlab-img-overlay8:before,
.dlab-img-overlay8:after,
.dlab-img-overlay9:before,
.dlab-img-overlay9:after,
.dlab-img-overlay11:before,
.dlab-img-overlay11:after,
.dlab-img-overlay12:before,
.dlab-img-overlay12:after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}
.dlab-img-overlay1.overlay-primary,
.dlab-img-overlay2.overlay-primary,
.dlab-img-overlay3.overlay-primary,
.dlab-img-overlay4.overlay-primary,
.dlab-img-overlay5.overlay-primary,
.dlab-img-overlay6.overlay-primary,
.dlab-img-overlay7.overlay-primary,
.dlab-img-overlay8.overlay-primary,
.dlab-img-overlay9.overlay-primary,
.dlab-img-overlay11.overlay-primary,
.dlab-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dlab-box:hover .dlab-img-overlay1:before {
  opacity: 0.5;
}

.dlab-box:hover .dlab-img-overlay2:before {
  opacity: 0.9;
}

.dlab-box:hover .dlab-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dlab-box:hover .dlab-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dlab-box:hover .dlab-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dlab-box:hover .dlab-img-overlay6:before {
  height: 100%;
}

.dlab-box:hover .dlab-img-overlay7:before,
.dlab-box:hover .dlab-img-overlay7:after {
  width: 50%;
}

.dlab-box:hover .dlab-img-overlay8:before,
.dlab-box:hover .dlab-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dlab-box:hover .dlab-img-overlay9:before,
.dlab-box:hover .dlab-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dlab-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dlab-img-overlay1:hover:before {
  opacity: 0.5;
}

.dlab-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.dlab-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dlab-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dlab-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dlab-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dlab-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dlab-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dlab-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dlab-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dlab-img-overlay6:hover:before {
  height: 100%;
}

.dlab-img-overlay7:before,
.dlab-img-overlay7:after {
  height: 100%;
}

.dlab-img-overlay7:after {
  left: auto;
  right: 0;
}

.dlab-img-overlay7:hover:before,
.dlab-img-overlay7:hover:after {
  width: 50%;
}

.dlab-img-overlay8:before,
.dlab-img-overlay8:after {
  height: 100%;
}

.dlab-img-overlay8:after {
  left: auto;
  right: 0;
}

.dlab-img-overlay8:hover:before,
.dlab-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dlab-img-overlay9:before:before,
.dlab-img-overlay9:before:after,
.dlab-img-overlay9:after:before,
.dlab-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dlab-img-overlay9:before:hover:before,
.dlab-img-overlay9:before:hover:after,
.dlab-img-overlay9:after:hover:before,
.dlab-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}
.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #000;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #fff;
}

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary);
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}
.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}
.overlay-icon a i {
  background-color: #fff;
}

.overlay-bx:hover a > i,
.dlab-media:hover .overlay-bx a > i,
.dlab-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.overlay-bx:hover,
.dlab-media:hover .overlay-bx,
.dlab-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}
.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #ff9446 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #ff9446 100%);
  background: linear-gradient(to bottom, transparent 0%, #ff9446 100%);
}
.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(
    bottom,
    transparent 0%,
    var(--primary) 100%
  );
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dlab-media {
  position: relative;
  overflow: hidden;
}
.overlay-shine .dlab-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.overlay-shine:hover .dlab-media:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/* info box show */
.dlab-info-has {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 30px 15px 40px;
  opacity: 0;
  margin-bottom: -100px;
  z-index: 2;
}
.dlab-info-has.p-a15 {
  padding: 15px;
}
.dlab-info-has.p-a20 {
  padding: 20px;
}
.dlab-info-has h1,
.dlab-info-has .h1,
.dlab-info-has h2,
.dlab-info-has .h2,
.dlab-info-has h3,
.dlab-info-has .h3,
.dlab-info-has h4,
.dlab-info-has .h4,
.dlab-info-has h5,
.dlab-info-has .h5,
.dlab-info-has a,
.dlab-info-has p,
.dlab-info-has h6,
.dlab-info-has .h6 {
  color: #fff;
}
.dlab-info-has .dlab-info-has-text {
  margin-bottom: 20px;
  line-height: 24px;
}
.dlab-info-has.bg-white h1,
.dlab-info-has.bg-white .h1,
.dlab-info-has.bg-white h2,
.dlab-info-has.bg-white .h2,
.dlab-info-has.bg-white h3,
.dlab-info-has.bg-white .h3,
.dlab-info-has.bg-white h4,
.dlab-info-has.bg-white .h4,
.dlab-info-has.bg-white h5,
.dlab-info-has.bg-white .h5,
.dlab-info-has.bg-white h6,
.dlab-info-has.bg-white .h6 {
  color: #3d474a;
}
.dlab-info-has.bg-white h1 a,
.dlab-info-has.bg-white .h1 a,
.dlab-info-has.bg-white h2 a,
.dlab-info-has.bg-white .h2 a,
.dlab-info-has.bg-white h3 a,
.dlab-info-has.bg-white .h3 a,
.dlab-info-has.bg-white h4 a,
.dlab-info-has.bg-white .h4 a,
.dlab-info-has.bg-white h5 a,
.dlab-info-has.bg-white .h5 a,
.dlab-info-has.bg-white h6 a,
.dlab-info-has.bg-white .h6 a {
  color: #3d474a;
}
.dlab-info-has.bg-white p {
  color: #3d474a;
}

.dlab-info-has.bg-primary {
  background-color: rgba(27, 188, 232, 0.9);
}

.dlab-info-has.bg-green {
  background-color: rgba(106, 179, 62, 0.9);
}

.dlab-info-has.bg-black {
  background-color: rgba(0, 0, 0, 0.6);
}

.dlab-info-has.bg-white {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.dlab-media {
  overflow: hidden;
  position: relative;
}
.dlab-media:hover .dlab-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.dlab-box:hover .dlab-info-has {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

.overlay-bx.no-hover,
.dlab-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
  visibility: visible;
}

.owl-none .owl-nav {
  display: none;
}

.owl-nav button.owl-prev,
.owl-nav button.owl-next {
  cursor: pointer;
  transition: all 0.5s;
  display: inline-block;
  font-size: 24px !important;
  font-weight: 500 !important;
}

.owl-theme .owl-nav,
.owl-theme .owl-dots {
  margin-top: 20px;
  text-align: center;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-dots {
  text-align: center;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span {
  background: #869791;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot span {
  background-color: #dadada;
  width: 15px;
  height: 15px;
  margin: 5px;
}

.owl-theme.owl-dots-1 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-1 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-2 .owl-dots {
  margin-top: 40px;
  position: unset;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}
.owl-theme.owl-dots-2 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}
.owl-theme.owl-dots-2 .owl-dots .owl-dot span {
  height: 10px;
  width: 10px;
  margin: 0;
  border-radius: 0;
}
.owl-theme.owl-dots-2 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-2 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-theme.owl-dots-3 .owl-dots {
  margin-top: 40px;
  position: unset;
  display: block;
  right: auto;
  text-align: center;
  top: auto;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: auto;
}
.owl-theme.owl-dots-3 .owl-dots .owl-dot {
  display: inline-block;
  margin: 4px;
}
.owl-theme.owl-dots-3 .owl-dots .owl-dot span {
  height: 8px;
  width: 8px;
  margin: 0;
  background-color: #898c90;
  border-radius: 8px;
}
.owl-theme.owl-dots-3 .owl-dots .owl-dot:hover span,
.owl-theme.owl-dots-3 .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.owl-btn-top-c .owl-nav {
  text-align: center;
}

.owl-btn-top-l .owl-nav {
  text-align: left;
}
.owl-btn-top-l .owl-nav .owl-prev {
  margin-left: 0;
}

.owl-btn-top-r .owl-nav {
  position: absolute;
  top: -125px;
  right: 0;
  margin: 0;
}
.owl-btn-top-r .owl-nav .owl-next {
  margin-right: 0;
}

.owl-btn-top-lr .owl-nav {
  text-align: left;
}
.owl-btn-top-lr .owl-nav .owl-next {
  margin-right: 0;
  float: right;
}

.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
  padding: 40px 8px;
}

.owl-btn-center .owl-nav {
  justify-content: center;
}

.owl-btn-center-lr .owl-prev,
.owl-btn-center-lr .owl-next {
  position: absolute;
  left: 0;
  top: 50%;
  margin: 0;
  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  cursor: pointer;
}

.owl-btn-center-lr .owl-prev {
  left: -110px;
}

.owl-btn-center-lr .owl-next {
  left: auto;
  right: -110px;
}

@media only screen and (max-width: 1400px) {
  .owl-btn-center-lr .owl-prev {
    left: -40px;
  }
  .owl-btn-center-lr .owl-next {
    right: -40px;
  }
}

@media only screen and (max-width: 1280px) {
  .owl-btn-center-lr .owl-prev {
    left: -20px;
  }
  .owl-btn-center-lr .owl-next {
    right: -20px;
  }
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

.owl-btn-1 .owl-nav button.owl-prev,
.owl-btn-1 .owl-nav button.owl-next {
  border-radius: 3px;
  line-height: 40px;
  margin: 0 20px !important;
  padding: 0;
  text-align: center;
  color: var(--primary) !important;
  font-size: 35px;
  height: auto;
  width: auto;
  background-color: transparent;
  position: relative;
}
.owl-btn-1 .owl-nav button.owl-prev:before,
.owl-btn-1 .owl-nav button.owl-next:before {
  content: "";
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 3px;
  position: absolute;
  font-weight: 400;
  top: 8px;
  color: #212529;
}
.owl-btn-1 .owl-nav button.owl-prev:hover,
.owl-btn-1 .owl-nav button.owl-next:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .owl-btn-1 .owl-nav button.owl-prev,
  .owl-btn-1 .owl-nav button.owl-next {
    margin: 0 10px !important;
  }
}

.owl-btn-1 .owl-nav button.owl-prev {
  padding: 0 30px 0 0px !important;
}
.owl-btn-1 .owl-nav button.owl-prev:before {
  content: "PREV";
  right: 0;
}

.owl-btn-1 .owl-nav button.owl-next {
  padding: 0 0 0 30px !important;
}
.owl-btn-1 .owl-nav button.owl-next:before {
  content: "NEXT";
  left: 0;
}

.owl-btn-1.btn-white .owl-nav .owl-prev,
.owl-btn-1.btn-white .owl-nav .owl-next {
  color: #fff;
}
.owl-btn-1.btn-white .owl-nav .owl-prev:hover,
.owl-btn-1.btn-white .owl-nav .owl-next:hover {
  color: #fff;
}

.owl-btn-2.owl-btn-rounded .owl-nav .owl-prev,
.owl-btn-2.owl-btn-rounded .owl-nav .owl-next {
  border-radius: 8px;
}

.owl-btn-2 .owl-nav .owl-prev,
.owl-btn-2 .owl-nav .owl-next {
  height: 60px;
  line-height: 60px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 60px;
  box-shadow: 0 4px 15px var(--rgba-primary-2);
}
@media only screen and (max-width: 575px) {
  .owl-btn-2 .owl-nav .owl-prev,
  .owl-btn-2 .owl-nav .owl-next {
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 18px !important;
  }
}

.owl-btn-3 .owl-nav .owl-prev,
.owl-btn-3 .owl-nav .owl-next {
  border-radius: 50px;
  height: 45px;
  line-height: 45px;
  margin: 0 10px !important;
  padding: 0;
  text-align: center;
  width: 45px;
}

.owl-btn-primary .owl-nav button.owl-prev,
.owl-btn-primary .owl-nav button.owl-next {
  background-color: var(--primary);
  color: #fff;
  transition: all 0.5s;
}
.owl-btn-primary .owl-nav button.owl-prev:hover,
.owl-btn-primary .owl-nav button.owl-next:hover {
  background-color: #fff;
  color: var(--primary);
}

.owl-btn-white .owl-prev,
.owl-btn-white .owl-next {
  background-color: #fff;
  color: var(--primary);
  transition: all 0.5s;
}
.owl-btn-white .owl-prev:hover,
.owl-btn-white .owl-next:hover {
  background-color: var(--primary);
  color: #fff;
}

.owl-dots-none .owl-dots {
  display: none;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-primary-full .owl-dots .owl-dot span {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  margin: 4px;
}

.owl-dots-white-full .owl-dots .owl-dot:active span,
.owl-dots-white-full .owl-dots .owl-dot.active span,
.owl-dots-black-full .owl-dots .owl-dot:active span,
.owl-dots-black-full .owl-dots .owl-dot.active span,
.owl-dots-primary-full .owl-dots .owl-dot:active span,
.owl-dots-primary-full .owl-dots .owl-dot.active span {
  opacity: 1;
  background-color: var(--primary);
}

.owl-dots-white-big .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  margin: 4px;
}

.owl-dots-white-big .owl-dots .owl-dot:active span,
.owl-dots-black-big .owl-dots .owl-dot:active span,
.owl-dots-primary-big .owl-dots .owl-dot:active span {
  width: 14px;
  height: 14px;
  opacity: 1;
  margin: 0px 4px 2px 4px;
}

.owl-dots-primary-full .owl-dots .owl-dot span,
.owl-dots-primary-big .owl-dots .owl-dot span {
  background-color: var(--primary);
}

.owl-dots-black-full .owl-dots .owl-dot span,
.owl-dots-black-big .owl-dots .owl-dot span {
  background-color: #333;
}

.owl-dots-white-full .owl-dots .owl-dot span,
.owl-dots-white-big .owl-dots .owl-dot span {
  background-color: #f5f5f5;
}

/* owl num count */
.owl-num-count .owl-dots {
  text-align: right;
  counter-reset: li;
  justify-content: center;
  display: flex;
}
.owl-num-count .owl-dots .owl-dot {
  padding: 5px 2px !important;
  cursor: pointer;
  font-size: 18px;
  position: relative;
}
.owl-num-count .owl-dots .owl-dot span {
  width: 20px;
  height: 5px;
  border-radius: 10px;
  transition: all 0.5s;
  background-color: transparent;
}
.owl-num-count .owl-dots .owl-dot:before {
  content: "0" counter(li, decimal);
  counter-increment: li;
  font-weight: 600;
  color: #777777;
  font-size: 18px;
}
.owl-num-count .owl-dots .owl-dot.active:before {
  color: var(--primary);
}
.owl-num-count .owl-dots .owl-dot.active span {
  background-color: var(--primary);
}

.pricingtable-inner {
  text-align: center;
}

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricingtable-features li {
  padding: 12px;
}
.pricingtable-features li i {
  margin: 0 3px;
}

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}

.table-option {
  border: 2px solid #adadad;
  border-radius: 6px;
  padding: 50px 0px 30px 0;
}
.table-option .pricingtable-footer {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features li {
  border: 0;
  background-color: transparent;
  color: #919191;
  font-size: 18px;
}
.table-option .pricingtable-title {
  border: 0;
  background-color: transparent;
  padding: 0;
}
.table-option .pricingtable-title * {
  color: #000;
}
.table-option .pricingtable-title h2,
.table-option .pricingtable-title .h2 {
  font-size: 28px;
  line-height: 36px;
}
.table-option .pricingtable-price {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-bx {
  color: #000;
  font-size: 63px;
  font-weight: 800;
}
.table-option .pricingtable-bx strong,
.table-option .pricingtable-bx sup {
  font-size: 30px;
  font-weight: 700;
}
.table-option:hover,
.table-option.active {
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
}
.table-option.dark {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.table-option.dark:hover,
.table-option.dark.active {
  background-color: #404040;
  border: 2px solid #404040;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}
.table-option.dark:hover .pricingtable-features li,
.table-option.dark:hover .pricingtable-title h2,
.table-option.dark:hover .pricingtable-title .h2,
.table-option.dark:hover .pricingtable-price .pricingtable-bx,
.table-option.dark.active .pricingtable-features li,
.table-option.dark.active .pricingtable-title h2,
.table-option.dark.active .pricingtable-title .h2,
.table-option.dark.active .pricingtable-price .pricingtable-bx {
  color: #fff;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.table-option.dark:hover .pricingtable-footer .btn.black,
.table-option.dark:hover .pricingtable-footer .black.wp-block-button__link,
.table-option.dark.active .pricingtable-footer .btn.black,
.table-option.dark.active .pricingtable-footer .black.wp-block-button__link {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
  color: #000;
}

.pricingtable-toggle {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 0;
  margin-bottom: 10px;
}
.pricingtable-toggle span {
  font-size: 18px;
  margin-top: 5px;
  font-weight: 500;
}
.pricingtable-toggle .custom-control-label {
  margin: 0 35px 0 50px;
}

.pricingtable-wrapper {
  box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base) !important;
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-color: #fff;
}
.pricingtable-wrapper.style-1 {
  padding: 40px 60px;
  position: relative;
  z-index: 1;
  height: 100%;
}
.pricingtable-wrapper.style-1 .pricingtable-title {
  margin-bottom: 15px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.pricingtable-wrapper.style-1 .pricingtable-title .title {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  font-weight: 600;
  background-color: #fff;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
}
.pricingtable-wrapper.style-1 .pricingtable-bx {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  color: var(--primary);
  margin: 0;
  font-size: 45px;
}
.pricingtable-wrapper.style-1 .pricingtable-bx small,
.pricingtable-wrapper.style-1 .pricingtable-bx .small {
  font-size: 15px;
  color: #ff9446;
  font-weight: 600;
}
.pricingtable-wrapper.style-1 .pricingtable-footer {
  margin-top: auto;
}
.pricingtable-wrapper.style-1 .text {
  margin-bottom: 10px;
}
.pricingtable-wrapper.style-1 .pricingtable-price {
  margin-bottom: 15px;
}
.pricingtable-wrapper.style-1 .pricingtable-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pricingtable-wrapper.style-1 .pricingtable-head {
  height: 200px;
  margin: -40px -60px 15px;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.pricingtable-wrapper.style-1 .pricingtable-head:after {
  content: "";
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1280px) {
  .pricingtable-wrapper.style-1 .pricingtable-head {
    margin: -35px -35px 15px;
  }
}
.pricingtable-wrapper.style-1 .pricingtable-features {
  padding: 15px 0;
  text-align: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.pricingtable-wrapper.style-1 .pricingtable-features li {
  padding: 1px 0 1px 40px;
  font-size: 15px;
  position: relative;
  margin-bottom: 10px;
}
.pricingtable-wrapper.style-1 .pricingtable-features li:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 11px;
  width: 25px;
  height: 25px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  color: var(--primary);
  text-align: center;
  top: 0;
  line-height: 23px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  border: 1px solid #e1e1e2;
  font-weight: 900;
}
.pricingtable-wrapper.style-1 .pricingtable-features li:hover:after {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}
.pricingtable-wrapper.style-1 .btn,
.pricingtable-wrapper.style-1 .wp-block-button__link {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  font-weight: 600;
  padding: 18px 45px;
}
.pricingtable-wrapper.style-1:hover .pricingtable-title .title,
.pricingtable-wrapper.style-1.active .pricingtable-title .title {
  background-color: var(--primary);
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .pricingtable-wrapper.style-1 {
    padding: 35px;
  }
}

.dlab-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}
.dlab-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dlab-img-effect.opacity img:hover {
  opacity: 0.8;
}
.dlab-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.dlab-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dlab-img-effect.zoom:hover img {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}
.dlab-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}
.dlab-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dlab-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dlab-img-effect.fade-in {
  background: #000;
}
.dlab-img-effect.fade-in img {
  opacity: 0.65;
}
.dlab-img-effect.fade-in:hover img {
  opacity: 1;
}
.dlab-img-effect.fade-out {
  background: #000;
}
.dlab-img-effect.fade-out:hover img {
  opacity: 0.7;
}
.dlab-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}
.dlab-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.dlab-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dlab-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dlab-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dlab-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}
.dlab-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.dlab-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dlab-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dlab-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dlab-box:hover .dlab-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dlab-accordion .accordion-item {
  box-shadow: 1px 0px 60px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}

.dlab-accordion .accordion-body {
  padding: 25px 30px;
  border-radius: 0;
}

.dlab-accordion .accordion-collapse {
  border: 0;
}

.dlab-accordion .accordion-header .accordion-button {
  font-size: 18px;
  border: 0;
  border-radius: 8px;
  padding: 22px 65px 22px 30px;
  transition: all 0.5s;
  color: #fff;
  box-shadow: 5px 0 15px var(--rgba-primary-4);
  background-color: var(--primary);
}
.dlab-accordion .accordion-header .accordion-button:after {
  content: none;
}
.dlab-accordion .accordion-header .accordion-button .toggle-close {
  background-color: #fff;
  font-family: themify;
  height: 55px;
  width: 55px;
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 0.25rem;
  line-height: 57px;
  text-align: center;
  font-size: 24px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  z-index: 1;
  overflow: hidden;
}
.dlab-accordion .accordion-header .accordion-button .toggle-close:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
.dlab-accordion .accordion-header .accordion-button .toggle-close:before {
  content: "\e61a";
  color: var(--primary);
  font-size: 24px;
  font-family: "themify";
  z-index: 99;
  opacity: 1;
  transform: rotate(45deg);
  transition: all 0.5s;
  line-height: 55px;
  display: block;
}
.dlab-accordion .accordion-header .accordion-button.collapsed {
  background: #fff;
  color: inherit;
}
.dlab-accordion .accordion-header .accordion-button.collapsed:after {
  opacity: 0;
}
.dlab-accordion .accordion-header .accordion-button.collapsed .toggle-close {
  content: "";
}
.dlab-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close:after {
  opacity: 1;
}
.dlab-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close:before {
  opacity: 1;
  transform: rotate(0);
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .dlab-accordion .accordion-header .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
  }
}

.dlab-accordion.accordion-sm .accordion-body {
  padding: 25px;
}

.dlab-accordion.accordion-sm .accordion-header .accordion-button {
  padding: 15px 55px 15px 20px;
}
.dlab-accordion.accordion-sm .accordion-header .accordion-button .toggle-close {
  height: 40px;
  width: 40px;
}
.dlab-accordion.accordion-sm
  .accordion-header
  .accordion-button
  .toggle-close:before {
  font-size: 18px;
  line-height: 40px;
}

@media only screen and (max-width: 575px) {
  .dlab-accordion.accordion-sm
    .accordion-header
    .accordion-header
    .accordion-button {
    padding: 15px 65px 15px 15px;
  }
}

.pagination li {
  margin: 0 5px;
}
.pagination li .page-numbers,
.pagination li .page-link {
  height: 45px;
  min-width: 45px;
  line-height: 42px;
  border-radius: 0;
  color: #777777;
  text-align: center;
  border: 0;
  padding: 0;
  transition: all 0.5s;
  border: 1px solid;
  border-color: #777777;
  font-size: 18px;
  padding: 0 10px;
  display: block;
  border-radius: var(--border-radius-base) !important;
}
.pagination li .page-numbers.current,
.pagination li .page-numbers.active,
.pagination li .page-numbers:hover,
.pagination li .page-link.current,
.pagination li .page-link.active,
.pagination li .page-link:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}
.pagination li .prev,
.pagination li .next {
  border-color: transparent;
  font-size: 18px;
}

.pagination.text-center {
  justify-content: center;
}

.pagination.rounded-0 .page-item .page-link {
  border-radius: 0;
}

.dlab-content-bx {
  position: relative;
  overflow: hidden;
}
.dlab-content-bx .content-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dlab-content-bx .dlab-content-inner {
  position: relative;
}
.dlab-content-bx.style-1 .dlab-content-inner .content-media {
  width: 50vw;
  height: calc(100% + 50px);
  float: right;
  margin-top: -50px;
  z-index: 1;
  padding-bottom: 50px;
  background: #fff;
}
.dlab-content-bx.style-1 .dlab-content-inner .inner-content {
  padding: 50px 0px 50px 50px;
}
@media only screen and (max-width: 991px) {
  .dlab-content-bx.style-1 .dlab-content-inner .content-media {
    width: 100%;
    padding-bottom: 0;
  }
  .dlab-content-bx.style-1 .dlab-content-inner .inner-content {
    padding: 60px 15px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-content-bx.style-1 .dlab-content-inner .inner-content {
    padding: 50px 15px 0;
  }
}
.dlab-content-bx.style-2 .content-media {
  width: 50vw;
  margin: -120px 0 0 0;
  float: right;
  height: 100%;
}
.dlab-content-bx.style-2 .content-media.right {
  float: left;
}
.dlab-content-bx.style-2 .content-media img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 991px) {
  .dlab-content-bx.style-2 .content-media {
    margin: 0;
    padding-top: 40px;
    float: none;
    width: 100%;
  }
}
.dlab-content-bx.style-2 .dlab-content-inner {
  margin-top: 80px;
}
@media only screen and (max-width: 991px) {
  .dlab-content-bx.style-2 .dlab-content-inner {
    margin-top: 20px;
  }
}
.dlab-content-bx.style-3 .dlab-content-inner {
  padding-right: 80px;
}
.dlab-content-bx.style-3 .counter-info {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 80px 80px 50px 80px;
}
.dlab-content-bx.style-3 .counter-info .counter {
  margin: 0 10px 0 0;
  writing-mode: tb-rl;
  border-right: 2px solid #a3cc02;
  font-size: 50px;
  line-height: 40px;
  padding-right: 10px;
}
.dlab-content-bx.style-3 .counter-info .counter-bx {
  display: flex;
  align-items: center;
}
.dlab-content-bx.style-3 .video-btn {
  margin-top: 120px;
}
.dlab-content-bx.style-4 {
  position: relative;
}
.dlab-content-bx.style-4 .content-media {
  margin: 100px auto;
  max-width: 1000px;
}
.dlab-content-bx.style-4 .content-media img {
  box-shadow: 40px -40px 0 0 var(--primary);
  margin: 40px 40px 0 0;
}
.dlab-content-bx.style-4 .content-media:after {
  content: attr(data-name);
  font-size: 100px;
  opacity: 0.6;
  position: absolute;
  color: transparent;
  top: 50px;
  z-index: 1;
  right: 10px;
  line-height: 80px;
  -webkit-text-stroke: 1px #fff;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  text-align: right;
  letter-spacing: 10px;
}
.dlab-content-bx.style-4:after {
  content: "";
  left: 0;
  bottom: 0;
  height: 300px;
  background-color: #fff;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li {
  position: relative;
  padding: 6px 5px 6px 15px;
}
.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before {
  position: absolute;
  left: 0;
  top: 4px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before {
  color: var(--primary);
}

.list-angle-double li {
  padding-left: 25px;
}

ul.list-circle li:before {
  content: "\f111";
  font-size: 8px;
  font-weight: 900;
  top: 50%;
  transform: translateY(-50%);
}

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px;
}

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%);
}

ul.grid-2 {
  display: flex;
  flex-wrap: wrap;
}
ul.grid-2 li {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  ul.grid-2 li {
    width: 100%;
  }
}

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px;
}
.list-square-check li:before {
  content: "\f00c";
  top: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--rgba-primary-1);
  color: #000;
  text-align: center;
  padding: 0 0 0 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translateY(-50%);
}
.list-square-check li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

.progress-bx {
  overflow: hidden;
}
.progress-bx .progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.progress-bx .progress-info .title {
  margin-bottom: 0;
}
.progress-bx .progress-info .progress-value {
  margin-bottom: 0;
}
.progress-bx .progress {
  overflow: unset;
}
.progress-bx .progress .progress-bar {
  position: relative;
  overflow: unset;
}
.progress-bx.style-1 .progress {
  background-color: #f8f8f8;
  border-radius: 0;
  height: 15px;
}
.progress-bx.style-1 .progress .progress-bar {
  background-color: var(--primary);
}
.progress-bx.style-1 .title,
.progress-bx.style-1 .progress-value {
  letter-spacing: 3px;
  font-weight: 300;
  font-family: var(--font-family-title);
}
.progress-bx.style-2 .progress {
  background-color: var(--rgba-primary-1);
  height: 15px;
  border-radius: var(--border-radius-base);
}
.progress-bx.style-2 .progress .progress-bar {
  background-color: var(--primary);
  border-radius: var(--border-radius-base);
}

.chatBot {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: #000;
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: none;
  height: 70px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 80px;
  text-align: center;
  border-radius: var(--border-radius-base);
  width: 70px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
  font-size: 32px;
}

.chatbot-toggle-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.chat-window {
  position: fixed;
  z-index: 1000;
  bottom: 90px;
  right: 80px;
  width: 350px;
  height: 450px;
  background-color: white;
  border: 1px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 10px;
  background-color: #000;
  color: white;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* Para Firefox */
}

.chat-window::-webkit-scrollbar {
  display: none; /* Para Chrome, Safari y Edge */
}

.message-bubble {
  max-width: 70%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 15px;
  position: relative;
  word-wrap: break-word;
}

.message-bubble.bot {
  align-self: flex-start;
  background-color: #f1f1f1;
}

.message-bubble.user {
  align-self: flex-end;
  background-color: #000;
  color: white;
}

.chat-footer {
  display: flex;
  border-top: 1px solid #ddd;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.chat-footer button {
  padding: 10px;
  background-color: #000;
  color: white;
  border: none;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spin {
  animation: spin 1s linear;
}

@keyframes swing {
  20% { transform: rotate(15deg); }
  40% { transform: rotate(-10deg); }
  60% { transform: rotate(5deg); }
  80% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.swing {
  animation: swing 1s ease;
}

@keyframes slideIn {
  0% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}

@keyframes slideOut {
  0% { transform: translateY(0); }
  100% { transform: translateY(100%); }
}

.slideIn {
  animation: slideIn 0.5s forwards;
}

.slideOut {
  animation: slideOut 0.5s forwards;
}

.tutorial {
  position: fixed;
  z-index: 1000;
  bottom: 100px;
  right: 80px;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.tutorial::after {
  content: '';
  position: absolute;
  bottom: -10px; /* Posiciona la flecha debajo de la viñeta */
  right: 20px; /* Posiciona la flecha a 20px del lado derecho de la viñeta */
  width: 0;
  height: 0;
  border-top: 10px solid white; /* Tamaño y color de la parte superior de la flecha */
  border-left: 10px solid transparent; /* Tamaño y color de la parte izquierda de la flecha */
  border-right: 10px solid transparent; /* Tamaño y color de la parte derecha de la flecha */
}

@keyframes tutorial-chatbot-animation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.tutorial {
  /* ...otros estilos... */
  animation: tutorial-chatbot-animation 1s ease-in-out infinite; /* La animación se repetirá indefinidamente cada 1 segundo */
}

@keyframes button-chatbot-animation {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

.chatbot-animate {
  /* ...otros estilos... */
  animation: button-chatbot-animation 1s ease-in-out infinite; /* La animación se repetirá indefinidamente cada 1 segundo */
}

.scroltop {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: var(--primary);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  border-radius: var(--border-radius-base);
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}
@media only screen and (max-width: 575px) {
  .scroltop {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .chatBot {
    height: 50px;
    width: 50px;
    line-height: 40px;
    right: 60px;
  }

  .chat-window{
    bottom: 70px;
    right: 10px;
  }
}

.video-bx {
  position: relative;
}
.video-bx.style-1 {
  position: relative;
}
.video-bx.style-1 .video-btn .popup-youtube {
  font-size: 18px;
  color: #575757;
  display: flex;
  text-decoration: underline;
  align-items: center;
}
.video-bx.style-1 .video-btn .popup-youtube .icon {
  position: relative;
  height: 66px;
  width: 66px;
  line-height: 66px;
  text-align: center;
  border-radius: 66px;
  background-color: var(--primary);
  display: block;
  color: #fff;
  font-size: 22px;
  margin-right: 30px;
  transition: all 0.5s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
}
@media only screen and (max-width: 575px) {
  .video-bx.style-1 .video-btn .popup-youtube .icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.video-bx.style-1 .video-btn .popup-youtube .icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx.style-1 .video-btn .popup-youtube .icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx.style-2 {
  height: 500px;
}
.video-bx.style-2.large {
  height: 615px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.video-bx.style-2.large img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-bx.style-2 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-bx.style-2 .video-btn.sm .popup-youtube {
  height: 72px;
  width: 72px;
  min-width: 72px;
  line-height: 72px;
  font-size: 22px;
}
.video-bx.style-2 .video-btn .popup-youtube {
  display: inline-block;
  height: 100px;
  width: 100px;
  min-width: 100px;
  line-height: 100px;
  font-size: 30px;
  border-radius: 100px;
  background-color: var(--primary);
  color: #fff;
}
.video-bx.style-2 .video-btn .popup-youtube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx.style-2 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
@media only screen and (max-width: 991px) {
  .video-bx.style-2 {
    height: 330px;
  }
  .video-bx.style-2.large {
    height: 380px;
  }
}
@media only screen and (max-width: 575px) {
  .video-bx.style-2 {
    height: 200px;
  }
  .video-bx.style-2.large {
    height: 250px;
  }
  .video-bx.style-2 .video-btn .popup-youtube,
  .video-bx.style-2 .video-btn.sm .popup-youtube {
    height: 60px;
    width: 60px;
    min-width: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.7);
    -moz-transform: scale(1.7);
    -webkit-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.7);
    -moz-transform: scale(1.7);
    -webkit-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
    opacity: 0;
  }
}

.btn-next,
.btn-prev {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 50px;
  height: 50px;
  color: var(--primary);
  background: var(--rgba-primary-1);
  font-size: 15px;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  line-height: 50px;
  border-radius: var(--border-radius-base);
  margin: 0 10px;
  display: inline-block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 767px) {
  .btn-next,
  .btn-prev {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.btn-next:hover,
.btn-prev:hover {
  background: var(--primary);
  color: #fff;
}
.btn-next:after,
.btn-prev:after {
  content: none;
}
.btn-next i,
.btn-prev i {
  font-size: 30px;
  line-height: inherit;
}
@media only screen and (max-width: 767px) {
  .btn-next i,
  .btn-prev i {
    font-size: 24px;
  }
}

.swiper-btn-lr .btn-next,
.swiper-btn-lr .btn-prev {
  position: absolute;
  top: 50%;
  margin: 0;
  border-radius: 0;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.swiper-btn-lr .btn-next {
  right: 1px;
}

.swiper-btn-lr .btn-prev {
  left: 1px;
}

.widget {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .widget {
    margin-bottom: 30px;
  }
}
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-radius: var(--border-radius-base);
  border-color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .widget.style-1 {
    padding: 25px;
  }
}

.widget-title {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .widget-title {
    margin-bottom: 15px;
  }
}
.widget-title .title {
  position: relative;
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .widget-title .title {
    font-size: 24px;
  }
}

.search-bx form {
  margin-bottom: 10px;
}

.search-bx .form-control,
.search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx select,
.search-bx .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .search-bx input,
.search-bx .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .search-bx input,
.search-bx .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .search-bx input,
.search-bx .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .search-bx textarea {
  padding: 10px 25px 10px 25px;
  height: 70px;
  border-radius: var(--border-radius-base) 0 0px var(--border-radius-base) !important;
}

.search-bx .input-group-prepend {
  border-right: 0;
  top: 50%;
}
.search-bx .input-group-prepend .input-group-text i {
  color: #828282;
}

.search-bx button {
  height: 70px;
  font-size: 24px;
  padding: 0;
  border-radius: 0;
  z-index: 4;
  width: 70px;
  background-color: var(--primary);
  color: #fff;
  box-shadow: none;
  justify-content: center;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0 !important;
}
.search-bx button i {
  font-size: 22px;
}

.search-bx.style-1 {
  padding: 30px;
  background-color: #ff9446;
  border-radius: 0.25rem;
}
.search-bx.style-1 .input-group {
  margin-bottom: 0;
}
.search-bx.style-1 button {
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0px 10px;
}

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}
.download-file .title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.download-file ul li a {
  background-color: #fff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}
.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}
.download-file ul li a .text {
  display: inline;
  position: relative;
}
.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.widget_contact:after {
  background: var(--gradient-sec);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  z-index: -1;
  background-size: 200%;
}
.widget_contact img {
  margin-bottom: 20px;
}
.widget_contact h4,
.widget_contact .h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
}
.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 11px;
}
.widget_contact .phone-number a {
  color: #fff;
}
.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}
.widget_contact .email a {
  color: #fff;
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.list-2 {
  display: table;
}
.list-2 li {
  width: 50% !important;
  float: left;
}

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 0.5rem 0rem 0.5rem 1.5rem;
  margin-bottom: 0;
  line-height: 1.25rem;
}
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
}
.wp-block-latest-posts li a:before,
.wp-block-categories-list li a:before,
.wp-block-archives-list li a:before,
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_pages ul li a:before,
.widget_recent_comments ul li a:before,
.widget_nav_menu ul li a:before,
.widget_recent_entries ul li a:before,
.widget_services ul li a:before {
  content: "\f101";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  display: block;
  left: -1.5rem;
  top: 0.063rem;
  font-size: 14px;
  font-weight: 900;
}
.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
  transform: translateX(10px);
}
.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}
.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}
.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.5rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  margin-bottom: 2.5rem;
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}
.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}
.widget_recent_comments ul li a {
  font-weight: 500;
}
.widget_recent_comments ul li a:before {
  content: none;
}
.widget_recent_comments ul li:before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.625rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}
.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}
.widget_calendar tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: #1f2471;
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: #1f2471;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .widget_calendar tr:nth-child(2n + 2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--title);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #ccc;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}
.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

/*widget rss  */
.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}
.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: table;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  padding: 0.625rem;
}
.widget_gallery li img {
  display: inline-block;
  width: 100%;
  border-radius: var(--border-radius-base);
}
.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}
.widget_gallery li:hover {
  opacity: 0.7;
}
.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}
.widget_tag_cloud .tagcloud a {
  position: relative;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 6px 15px;
  display: inline-block;
  margin: 0 15px 15px 0;
  font-size: 15px;
  color: inherit;
  line-height: 1.4;
}
.widget_tag_cloud .tagcloud a:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.recent-posts-entry .widget-post-bx .widget-post .dlab-media {
  width: 90px;
  min-width: 90px;
  border-radius: var(--border-radius-base);
}
.recent-posts-entry .widget-post-bx .widget-post .dlab-info {
  padding-left: 15px;
}
.recent-posts-entry .widget-post-bx .widget-post .dlab-meta {
  margin-bottom: 0;
}
.recent-posts-entry .widget-post-bx .widget-post .dlab-meta ul {
  line-height: 1;
}
.recent-posts-entry .widget-post-bx .widget-post .dlab-meta ul li {
  color: #575757;
  text-transform: uppercase;
  position: relative;
  font-weight: 600;
  font-size: 14px;
}
.recent-posts-entry .widget-post-bx .widget-post .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 1.5;
}

.service_menu_nav {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav {
    padding: 15px;
  }
}
.service_menu_nav ul li {
  padding: 0;
}
.service_menu_nav ul li a {
  background-color: #fff;
  display: block;
  border-radius: 6px;
  padding: 15px 25px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-size: 200%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a {
    padding: 15px;
  }
}
.service_menu_nav ul li a:before {
  content: "\f30b";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a:before {
    font-size: 22px;
  }
}
.service_menu_nav ul li a:after {
  background-image: var(--gradient);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  content: "";
  background-size: 200%;
  opacity: 0;
  z-index: -1;
}
.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  padding-left: 20px;
  border-color: transparent;
  transform: translateX(0);
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  color: #fff;
}
.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:before {
  opacity: 1;
  right: 10px;
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li:hover a:before,
  .service_menu_nav ul li.current-menu-item a:before,
  .service_menu_nav ul li.active a:before {
    font-size: 22px;
    right: 5px;
  }
}
.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.active a:after {
  opacity: 1;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}
.wp-block-categories-dropdown select:after,
.wp-block-archives-dropdown select:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_product_tag .product-tag-group {
  display: block;
}
.widget_product_tag
  .product-tag-group
  .btn-check:checked
  + .btn-outline-primary,
.widget_product_tag
  .product-tag-group
  .is-style-outline
  .btn-check:checked
  + .wp-block-button__link,
.is-style-outline
  .widget_product_tag
  .product-tag-group
  .btn-check:checked
  + .wp-block-button__link {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}
.widget_product_tag .product-tag-group .btn,
.widget_product_tag .product-tag-group .wp-block-button__link {
  box-shadow: none;
  padding: 10px 25px;
  margin-right: 15px;
  margin-bottom: 15px;
  border-color: var(--rgba-primary-3);
  border-radius: var(--border-radius-base) !important;
}
.widget_product_tag .product-tag-group .btn:hover,
.widget_product_tag .product-tag-group .wp-block-button__link:hover {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.widget_price_range .price-slide .amount {
  border: 0;
  font-size: 14px;
  font-weight: 600;
  color: #575757;
  margin-bottom: 20px;
}

.widget_price_range .ui-widget-header {
  background: var(--primary);
}

.widget_price_range .ui-slider-horizontal {
  height: 6px;
  margin: 13px 20px 13px 11px;
}

.widget_price_range .ui-slider-horizontal .ui-slider-handle {
  top: -0.65em;
}

.widget_price_range .ui-slider .ui-slider-handle {
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.widget_price_range .ui-state-default,
.widget_price_range .ui-widget-content .ui-state-default,
.widget_price_range .ui-widget-header .ui-state-default {
  background: var(--primary);
  border-color: var(--primary);
}

.widget_price_range .price-slider {
  padding-bottom: 12px;
}
.widget_price_range .price-slider #input-with-keypress-0,
.widget_price_range .price-slider #input-with-keypress-1 {
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  color: #7c7c7c;
}
.widget_price_range .price-slider #input-with-keypress-0 {
  position: relative;
  padding-right: 20px;
  margin-right: 10px;
}
.widget_price_range .price-slider #input-with-keypress-0:after {
  content: "";
  height: 2px;
  width: 8px;
  background: #7c7c7c;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget_price_range .price-slider .noUi-horizontal .noUi-handle {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: -1px 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.widget_price_range .price-slider .noUi-horizontal .noUi-handle:after,
.widget_price_range .price-slider .noUi-horizontal .noUi-handle:before {
  content: none;
}
.widget_price_range .price-slider .noUi-horizontal {
  height: 6px;
}
.widget_price_range .price-slider .noUi-target {
  border: 0;
  background: #e5e5e5;
  padding: 0 12px;
}
.widget_price_range .price-slider .noUi-connect {
  background: var(--primary);
}
.widget_price_range .price-slider .noUi-horizontal .noUi-handle {
  top: -12px;
}

.client-logo-wg ul {
  display: flex;
  flex-flow: wrap;
}
.client-logo-wg ul .brand-logo {
  float: left;
  width: 33.33%;
  padding: 5px 5px;
}
.client-logo-wg ul .brand-logo a {
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 12px 5px;
}
.client-logo-wg ul .brand-logo a:after {
  position: absolute;
  content: "";
  top: -1px;
  left: -1px;
  background-color: #dbdbdb;
  height: 45px;
  width: 45px;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.client-logo-wg ul .brand-logo a:before {
  position: absolute;
  content: "";
  bottom: -1px;
  right: -1px;
  background-color: #dbdbdb;
  height: 45px;
  width: 45px;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.client-logo-wg ul .brand-logo img {
  width: 100%;
}
.client-logo-wg ul .brand-logo:hover a:after,
.client-logo-wg ul .brand-logo:hover a:before {
  height: calc(100% + 2px);
  width: calc(100% + 2px);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
}

.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  justify-content: stretch;
  align-items: stretch;
}

.inquiry-modal .form-control,
.inquiry-modal .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .inquiry-modal select,
.inquiry-modal .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .inquiry-modal select,
.inquiry-modal .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .inquiry-modal input,
.inquiry-modal .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .inquiry-modal input,
.inquiry-modal .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .inquiry-modal input,
.inquiry-modal .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .inquiry-modal textarea {
  font-size: 15px;
  font-weight: 300;
  color: #000;
}

.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
}
.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}
.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}
.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 24px;
  margin-bottom: 15px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}
.inquiry-modal .modal-content .modal-header i {
  color: var(--primary);
  font-size: 54px;
  line-height: 1;
  display: inline-block;
}
.inquiry-modal .modal-content .modal-body {
  padding: 0;
}

.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: -40px;
  top: -40px;
  color: #fff;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .btn-close {
    right: 0;
  }
}

.dlabmove1 {
  animation: dlabmove1 1s linear infinite;
}

.dlabHeart {
  animation: dlabHeart 1s linear infinite;
}

@keyframes dlabmove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes dlabHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.nav-tabs.nav-tabs1 {
  border: 0;
  justify-content: space-between;
}
.nav-tabs.nav-tabs1 .nav-link {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #212621;
  border-radius: 0;
  line-height: 18px;
  padding: 20px 30px;
}
.nav-tabs.nav-tabs1 .nav-link.active {
  color: var(--title);
  border: 1px solid #e1e1f0;
}
@media only screen and (max-width: 1280px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 16px;
    line-height: 16px;
    padding: 18px 25px;
  }
}
@media only screen and (max-width: 575px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 14px;
    line-height: 14px;
    padding: 15px;
  }
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite;
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(5px, 5px);
  }
  65% {
    transform: translate(0, 5px);
  }
  65% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.about-bx1 .dlab-media {
  width: 50vw;
  float: right;
  border-radius: 0 100px 100px 0;
  overflow: hidden;
  margin-right: 50px;
  height: 100%;
}
.about-bx1 .dlab-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-bx1 .about-swiper {
  margin-left: -350px;
  margin-right: -18px;
}
.about-bx1 .about-swiper .icon-bx-wraper.style-1 {
  margin: 45px 18px;
}

@media only screen and (max-width: 1280px) {
  .about-bx1 .dlab-media {
    margin-right: 40px;
  }
  .about-bx1 .about-swiper {
    margin-left: -200px;
  }
}

@media only screen and (max-width: 991px) {
  .about-bx1 .dlab-media {
    width: 100%;
    border-radius: 50px;
    margin-right: 0;
    height: auto;
    margin-bottom: 40px;
  }
  .about-bx1 .about-swiper {
    margin-left: -18px;
  }
}

@media only screen and (max-width: 575px) {
  .about-bx1 .dlab-media {
    border-radius: 30px;
  }
  .about-bx1 .about-swiper .icon-bx-wraper.style-1 {
    margin: 32px 18px;
  }
}

.about-bx2 .year-exp {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #fff;
  padding: 30px 40px 1px 1px;
}
.about-bx2 .year-exp .year {
  height: 105px;
  width: 105px;
  text-align: center;
  border: 5px solid;
  line-height: 98px;
  font-size: 45px;
  margin: 0;
  border-color: var(--primary);
  margin-right: 20px;
}
.about-bx2 .year-exp .text {
  font-size: 25px;
  margin: 0;
}

.about-bx2 .list-square-check li {
  margin-top: 15px;
  margin-bottom: 15px;
}

.about-bx2 .about-media {
  border-radius: var(--border-radius-base);
}
.about-bx2 .about-media img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .about-bx2 .about-media {
    height: 450px;
  }
}

@media only screen and (max-width: 767px) {
  .about-bx2 .list-square-check li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .about-bx2 .year-exp {
    padding: 15px 30px 1px 1px;
  }
  .about-bx2 .year-exp .year {
    height: 80px;
    width: 80px;
    line-height: 74px;
    font-size: 35px;
    margin-right: 15px;
  }
  .about-bx2 .year-exp .text {
    font-size: 20px;
  }
}

.dlab-box {
  position: relative;
}

.portfolio-area1:after {
  content: "";
  height: 40%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: 0;
  width: 100%;
  background: #fff;
}

.dlab-box.style-1 {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .dlab-box.style-1 .dlab-media img {
    min-height: 350px;
  }
}
.dlab-box.style-1 .dlab-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgba(33, 37, 41, 0);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-1 .sub-title {
  writing-mode: tb-rl;
  float: right;
  margin: 0;
  color: #fff;
  background: var(--primary);
  font-weight: 300;
  position: absolute;
  top: 0;
  padding: 40px 25px;
  right: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-1 .sub-title a {
  color: inherit;
}
@media only screen and (max-width: 1280px) {
  .dlab-box.style-1 .sub-title {
    padding: 20px 12px;
    font-size: 15px;
  }
}
.dlab-box.style-1 .title {
  margin: 0;
  font-size: 2.5rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-1 .title span {
  display: block;
}
.dlab-box.style-1 .port-info {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 60px;
}
@media only screen and (max-width: 1280px) {
  .dlab-box.style-1 .port-info {
    padding: 30px;
  }
  .dlab-box.style-1 .port-info .dlab-meta {
    margin-bottom: 5px;
  }
  .dlab-box.style-1 .port-info .dlab-meta ul li {
    font-size: 15px;
  }
  .dlab-box.style-1 .port-info h2.title,
  .dlab-box.style-1 .port-info .title.h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-box.style-1 .port-info h2.title,
  .dlab-box.style-1 .port-info .title.h2 {
    font-size: 1.325rem;
  }
}
.dlab-box.style-1 .dlab-meta ul li {
  color: var(--primary);
  font-size: 18px;
  padding-left: 15px;
  position: relative;
}
.dlab-box.style-1 .dlab-meta ul li:after {
  content: "";
  height: 8px;
  width: 8px;
  background: var(--primary);
  position: absolute;
  border-radius: 4px;
  left: 0;
  top: 50%;
  margin-top: -4px;
}
.dlab-box.style-1 .view-btn {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 30px;
  left: 30px;
  border-radius: var(--border-radius-base);
  z-index: 1;
  transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  -ms-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dlab-box.style-1 .view-btn:after {
  content: "\f00e";
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 0;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #fff;
  font-size: 35px;
  display: inline-block;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
}
.dlab-box.style-1:hover .view-btn {
  background: var(--primary);
  transition-delay: 0s;
}
.dlab-box.style-1:hover .view-btn:after {
  opacity: 1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.portfolio-area2:after,
.portfolio-area2:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
  bottom: 0;
}

.portfolio-area2:after {
  height: 40%;
  left: 0;
  width: 100%;
}

.portfolio-area2:before {
  height: 100%;
  right: 0;
  width: 15%;
}

.portfolio-area2 .setResizeMargin {
  padding-right: 80px;
}

@media only screen and (max-width: 1680px) {
  .portfolio-area2 .setResizeMargin {
    padding-right: 40px;
  }
  .portfolio-area2:before {
    width: 10%;
  }
}

@media only screen and (max-width: 1400px) {
  .portfolio-area2:before {
    content: none;
  }
}

@media only screen and (max-width: 1280px) {
  .portfolio-area2 .setResizeMargin {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-area2 .setResizeMargin {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-area2 .setResizeMargin {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dlab-box.style-2 {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  overflow: hidden;
  padding-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .dlab-box.style-2 .dlab-media img {
    min-height: 350px;
  }
}
.dlab-box.style-2 .dlab-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgba(33, 37, 41, 0);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-2 .sub-title {
  writing-mode: tb-rl;
  float: right;
  margin: 0;
  color: #fff;
  background: var(--primary);
  font-weight: 300;
  position: absolute;
  top: 0;
  padding: 40px 25px;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-2 .sub-title a {
  color: inherit;
}
@media only screen and (max-width: 1280px) {
  .dlab-box.style-2 .sub-title {
    padding: 20px 12px;
    font-size: 15px;
  }
}
.dlab-box.style-2 .title {
  margin: 0;
  font-size: 30px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box.style-2 .title span {
  display: block;
}
.dlab-box.style-2 .port-info {
  position: absolute;
  bottom: 10px;
  right: 200px;
  background: #fff;
  padding: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
}
@media only screen and (max-width: 1280px) {
  .dlab-box.style-2 .port-info {
    padding: 30px;
  }
  .dlab-box.style-2 .port-info .dlab-meta {
    margin-bottom: 5px;
  }
  .dlab-box.style-2 .port-info .dlab-meta ul li {
    font-size: 15px;
  }
  .dlab-box.style-2 .port-info h2.title,
  .dlab-box.style-2 .port-info .title.h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .dlab-box.style-2 .port-info {
    right: 30px;
    max-width: 350px;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-box.style-2 .port-info h2.title,
  .dlab-box.style-2 .port-info .title.h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-box.style-2 .port-info {
    padding: 20px;
    right: 15px;
    max-width: 90%;
  }
}
.dlab-box.style-2 .dlab-meta ul li {
  color: var(--primary);
  font-size: 18px;
  padding-left: 15px;
  position: relative;
}
.dlab-box.style-2 .dlab-meta ul li:after {
  content: "";
  height: 8px;
  width: 8px;
  background: var(--primary);
  position: absolute;
  border-radius: 4px;
  left: 0;
  top: 50%;
  margin-top: -4px;
}
.dlab-box.style-2 .view-btn {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: var(--border-radius-base);
  z-index: 1;
  transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  -ms-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dlab-box.style-2 .view-btn:after {
  content: "\f00e";
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 0;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #fff;
  font-size: 35px;
  display: inline-block;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
}
@media only screen and (max-width: 767px) {
  .dlab-box.style-2 .view-btn {
    width: 50px;
    height: 50px;
    top: 15px;
    right: 15px;
  }
  .dlab-box.style-2 .view-btn:after {
    font-size: 25px;
  }
}
.dlab-box.style-2:hover .view-btn {
  background: var(--primary);
  transition-delay: 0s;
}
.dlab-box.style-2:hover .view-btn:after {
  opacity: 1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
  position: relative;
}
.quote-left:before,
.quote-right:before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left:before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right:before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-size: 15px;
  font-weight: 400;
}
.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-bg {
  color: #fff;
}

.star-rating li {
  display: inline-block;
}

.testimonial-1 .testimonial-text {
  background-color: #fff;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.testimonial-1 .testimonial-text:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.testimonial-1 .testimonial-detail {
  padding: 25px 0 20px 20px;
}

.testimonial-1 .testimonial-pic {
  float: left;
  margin-right: 15px;
  width: 80px;
  height: 80px;
}

.testimonial-1 .testimonial-position,
.testimonial-1 .testimonial-name {
  padding: 2px 0;
}

.testimonial-1 .testimonial-position {
  color: var(--primary);
  font-style: italic;
}

.testimonial-1 .testimonial-name {
  padding-top: 20px;
  font-weight: 700;
}

.testimonial-1 .quote-left:before {
  content: "\f11d";
  font-family: "flaticon";
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  background: var(--primary);
  position: absolute;
  color: #fff;
  text-align: center;
  left: 0;
  top: 50px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}

.section-head.style-1 h2,
.section-head.style-1 .h2 {
  font-size: 2.8125rem;
}
.section-head.style-1 h2 span,
.section-head.style-1 .h2 span {
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .section-head.style-1 h2,
  .section-head.style-1 .h2 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 767px) {
  .section-head.style-1 h2,
  .section-head.style-1 .h2 {
    font-size: 1.875rem;
  }
}

.section-head.style-1 .sub-title:before,
.section-head.style-1 .sub-title:after {
  content: "\f111";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  display: inline-block;
}

.section-head.style-1 .sub-title:before {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.section-head.style-1 .sub-title:after {
  content: none;
  transform-origin: right;
  -moz-transform-origin: right;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
}

.section-head.style-1.text-center .sub-title:after {
  content: "\f111";
}

.section-head.style-2 h2,
.section-head.style-2 .h2 {
  font-size: 2.8125rem;
}
.section-head.style-2 h2 span,
.section-head.style-2 .h2 span {
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .section-head.style-2 h2,
  .section-head.style-2 .h2 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 767px) {
  .section-head.style-2 h2,
  .section-head.style-2 .h2 {
    font-size: 1.875rem;
  }
}

.section-head.style-2 .sub-title {
  display: inline-flex;
  align-items: center;
}
.section-head.style-2 .sub-title:before,
.section-head.style-2 .sub-title:after {
  content: "";
  height: 1px;
  width: 55px;
  background-color: #000;
  display: inline-block;
  margin-right: 10px;
}
@media only screen and (max-width: 575px) {
  .section-head.style-2 .sub-title:before,
  .section-head.style-2 .sub-title:after {
    width: 35px;
  }
}
.section-head.style-2 .sub-title:before {
  margin-right: 10px;
}
.section-head.style-2 .sub-title:after {
  content: none;
  margin-left: 10px;
}
.section-head.style-2 .sub-title.text-primary:before,
.section-head.style-2 .sub-title.text-primary:after {
  background-color: var(--primary);
}
.section-head.style-2 .sub-title.text-secondary:before,
.section-head.style-2 .sub-title.text-secondary:after {
  background-color: var(--secondary);
}
.section-head.style-2 .sub-title.text-white:before,
.section-head.style-2 .sub-title.text-white:after {
  background-color: #fff;
}

.section-head.style-2.text-end .sub-title:before {
  content: none;
}

.section-head.style-2.text-end .sub-title:after {
  content: "";
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}
.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}
.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-lg i {
  font-size: 60px;
  vertical-align: middle;
}
.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-md i {
  font-size: 45px;
  vertical-align: middle;
}
.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  line-height: 80px;
}
.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-sm i {
  font-size: 30px;
  vertical-align: middle;
}
.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}
.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
}
.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}
.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
}
.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}
.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 70px;
}

.icon-md {
  display: inline-block;
  text-align: center;
}
.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}
.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 50px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
}
.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}
.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
}
.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}
.icon-bx-wraper .dlab-tilte {
  margin-top: 0;
}
.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}
.icon-bx-wraper p:last-child {
  margin: 0;
}
.icon-bx-wraper.center {
  text-align: center;
}
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 20px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}
.icon-bx-wraper.right {
  text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #fff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dlab-box,
.dlab-info,
.dlab-tilte,
.dlab-tilte-inner {
  position: relative;
}

.dlab-tilte-inner {
  display: inline-block;
}

.dlab-box[class*="border-"],
.dlab-info[class*="border-"] {
  border-color: #eee;
}

.dlab-info.border-1,
.dlab-info.border-2,
.dlab-info.border-3,
.dlab-info.border-4,
.dlab-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}
.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}

.right-border:before {
  right: 0;
}

.dlab-media,
.dlab-post-media {
  position: relative;
  overflow: hidden;
}
.dlab-media img,
.dlab-post-media img {
  width: 100%;
  height: auto;
}

.dlab-box-bg {
  overflow: hidden;
  background-size: cover;
}
.dlab-box-bg .btn,
.dlab-box-bg .wp-block-button__link {
  overflow: unset;
}
.dlab-box-bg .glyph-icon {
  font-size: 50px;
}
.dlab-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}
.dlab-box-bg .text-primary,
.dlab-box-bg .icon-content .dlab-tilte,
.dlab-box-bg .icon-content p,
.dlab-box-bg .icon-box-btn .btn,
.dlab-box-bg .icon-box-btn .wp-block-button__link {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}
.dlab-box-bg.active .text-primary,
.dlab-box-bg.active .icon-content .dlab-tilte,
.dlab-box-bg.active .icon-content p,
.dlab-box-bg.active .icon-box-btn .btn,
.dlab-box-bg.active .icon-box-btn .wp-block-button__link {
  color: #fff;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dlab-box-bg .icon-box-btn .btn,
.dlab-box-bg .icon-box-btn .wp-block-button__link {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0px 26px 40px rgba(38, 58, 126, 0.14);
  border-radius: var(--border-radius-lg);
  padding: 45px 40px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.icon-bx-wraper.style-1 svg path {
  fill: var(--primary);
}
.icon-bx-wraper.style-1 svg rect {
  fill: var(--primary);
}
.icon-bx-wraper.style-1.box-sm {
  padding: 25px 20px;
}
@media only screen and (max-width: 1400px) {
  .icon-bx-wraper.style-1 {
    padding: 40px 25px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-1 {
    padding: 30px 25px;
    box-shadow: 0px 0px 40px rgba(38, 58, 126, 0.14);
  }
}

.icon-bx-wraper.hover-overlay-effect {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.icon-bx-wraper.hover-overlay-effect .effect {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
.icon-bx-wraper.hover-overlay-effect svg path,
.icon-bx-wraper.hover-overlay-effect .title {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.icon-bx-wraper.hover-overlay-effect:hover {
  color: #fff;
}
.icon-bx-wraper.hover-overlay-effect:hover .text-effect-1 .char:after,
.icon-bx-wraper.hover-overlay-effect:hover .text-effect-1 .char:before {
  color: #fff;
}
.icon-bx-wraper.hover-overlay-effect:hover .text-primary {
  color: #fff !important;
}
.icon-bx-wraper.hover-overlay-effect:hover .btn-outline-primary,
.icon-bx-wraper.hover-overlay-effect:hover
  .is-style-outline
  .wp-block-button__link,
.is-style-outline
  .icon-bx-wraper.hover-overlay-effect:hover
  .wp-block-button__link {
  color: #fff;
  border-color: #fff;
}
.icon-bx-wraper.hover-overlay-effect:hover svg path,
.icon-bx-wraper.hover-overlay-effect:hover svg rect {
  fill: #fff;
}
.icon-bx-wraper.hover-overlay-effect:hover .title {
  color: #fff;
}
.icon-bx-wraper.hover-overlay-effect:hover .effect {
  width: 300%;
  height: 1200px;
}

.icon-bx-wraper.style-2 {
  background-color: var(--rgba-primary-1);
  margin-left: 25px;
  border-radius: var(--border-radius-base);
}
.icon-bx-wraper.style-2 .icon-bx-sm {
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  display: inline-block;
  height: 50px;
  width: 50px;
  min-width: 50px;
  line-height: 50px;
  text-align: center;
  background-color: var(--primary);
}
.icon-bx-wraper.style-2 .icon-content {
  padding: 15px 40px;
  font-weight: 600;
  min-height: 85px;
  display: flex;
  align-items: center;
  color: #595959;
}

.icon-bx-wraper.style-3 {
  background: #f7f8fa;
  padding: 20px;
  border: 1px dashed #eeeeee;
  border-radius: var(--border-radius-base);
}
.icon-bx-wraper.style-3 .photo {
  float: left;
  width: 120px;
  min-width: 120px;
  border-radius: var(--border-radius-base);
}
.icon-bx-wraper.style-3 .content-box {
  padding-left: 140px;
}
.icon-bx-wraper.style-3 .used-car-dl-info li {
  display: inline-block;
  color: #6d7075;
  font-weight: 500;
  font-size: 15px;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid #00000047;
}
.icon-bx-wraper.style-3 .used-car-dl-info li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 .photo {
    width: 80px;
    min-width: 80px;
  }
  .icon-bx-wraper.style-3 .content-box {
    padding-left: 100px;
  }
  .icon-bx-wraper.style-3 .used-car-dl-info li {
    border-right: 0;
  }
}

.icon-bx-wraper.style-4 {
  padding: 40px 30px;
  background-color: #fff;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.icon-bx-wraper.style-4 .dlab-tilte {
  font-weight: 500;
}
.icon-bx-wraper.style-4.active {
  background-color: var(--primary);
  color: #fff;
}
.icon-bx-wraper.style-4.active .dlab-tilte {
  color: #fff;
}
.icon-bx-wraper.style-4.active svg path {
  fill: #fff;
}

.icon-bx-wraper.style-5 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}
.icon-bx-wraper.style-5.active {
  background-color: #fff;
}
.icon-bx-wraper.style-5.active .dlab-tilte {
  color: #000;
}

.icon-bx-wraper.style-6 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: var(--border-radius-base);
  padding: 40px 30px;
}

.dlab-team {
  transition: all 0.5s;
}
.dlab-team .dlab-name a {
  transition: all 0.5s;
}
.dlab-team .dlab-content {
  transition: all 0.5s;
  position: relative;
}
.dlab-team .dlab-media img {
  width: 100%;
}
.dlab-team .dlab-position {
  display: block;
}

.dlab-team.style-1 {
  text-align: center;
}
.dlab-team.style-1 .dlab-media {
  height: 335px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
}
.dlab-team.style-1 .dlab-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dlab-team.style-1 .dlab-media .overlay-bx {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.dlab-team.style-1 .dlab-media .overlay-bx .social-list {
  transform: scale(0);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-team.style-1:hover .dlab-media img {
  transform: scale(1.1);
}
.dlab-team.style-1:hover .dlab-media .overlay-bx {
  opacity: 1;
  visibility: visible;
}
.dlab-team.style-1:hover .dlab-media .overlay-bx .social-list {
  transform: scale(1);
}

@media only screen and (max-width: 991px) {
  .team-slider {
    overflow: hidden;
  }
  .team-slider .row {
    flex-wrap: nowrap;
  }
  .team-slider .swiper-slide {
    width: 360px;
  }
}

@media only screen and (max-width: 575px) {
  .team-slider .swiper-slide {
    width: 300px;
  }
  .team-slider .dlab-team.style-1 {
    margin-bottom: 0;
  }
}

.blog-swiper {
  margin: -15px;
  width: auto;
}
.blog-swiper .swiper-slide > div {
  margin: 15px;
  height: 100%;
}

.dlab-card {
  position: relative;
}
.dlab-card .dlab-info {
  padding: 30px;
  position: relative;
}
.dlab-card .dlab-title {
  margin-bottom: 15px;
  word-break: break-word;
}
.dlab-card.blog-half {
  display: flex;
}
@media only screen and (max-width: 575px) {
  .dlab-card.blog-half {
    display: block;
  }
}
.dlab-card.blog-half .dlab-info {
  padding: 30px;
  position: relative;
  flex: 1;
}
.dlab-card.blog-half .dlab-media {
  margin-bottom: 0;
  max-width: 300px;
  min-width: 300px;
}
@media only screen and (max-width: 1280px) {
  .dlab-card.blog-half .dlab-media {
    max-width: 250px;
    min-width: 250px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-card.blog-half .dlab-media {
    max-width: 100%;
    min-width: 100%;
  }
}
.dlab-card.blog-half .dlab-media a {
  display: block;
  height: 100%;
}
.dlab-card.blog-half .dlab-media img {
  height: 100%;
  object-fit: cover;
  min-height: 235px;
}
@media only screen and (max-width: 575px) {
  .dlab-card.blog-half .dlab-media img {
    min-height: auto;
  }
}
.dlab-card.blog-half.post-video .post-video-icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.dlab-card.blog-half .swiper-container {
  height: 100%;
}

.dlab-meta {
  margin-bottom: 15px;
}
.dlab-meta ul {
  margin: 0;
  padding: 0;
}
.dlab-meta ul li {
  display: inline-block;
  margin-right: 20px;
}
.dlab-meta ul li a {
  color: inherit;
}

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.post-video .post-video-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.post-video .post-video-icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.dlab-card.style-1 {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.dlab-card.style-1 .dlab-media img {
  border-radius: var(--border-radius-base);
}
.dlab-card.style-1 .dlab-info {
  padding: 35px 30px 20px;
}
@media only screen and (max-width: 575px) {
  .dlab-card.style-1 .dlab-info {
    padding: 25px 25px 20px;
  }
}
.dlab-card.style-1 .dlab-title {
  margin-bottom: 12px;
}
.dlab-card.style-1 p {
  font-size: 16px;
  margin-bottom: 20px;
}
.dlab-card.style-1 .dlab-meta {
  margin-bottom: 10px;
}
.dlab-card.style-1 .dlab-meta ul li {
  margin-right: 0;
}
.dlab-card.style-1.blog-lg .dlab-info {
  padding: 40px 35px 30px;
}
@media only screen and (max-width: 767px) {
  .dlab-card.style-1.blog-lg .dlab-info {
    padding: 35px 30px 20px;
  }
}
.dlab-card.style-1.shadow {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1) !important;
}
.dlab-card.style-1.shadow .dlab-media img {
  border-radius: 0;
}
.dlab-card.style-1.shadow .btn,
.dlab-card.style-1.shadow .wp-block-button__link {
  margin-bottom: 10px;
}
.dlab-card.style-1.blog-half .dlab-meta {
  position: unset !important;
}
.dlab-card.style-1.text-white .dlab-title a {
  color: #fff;
}
.dlab-card.style-1.text-white .dlab-title a:hover {
  color: var(--primary);
}
.dlab-card.style-1 .dlab-meta ul li {
  display: inline;
  font-weight: 500;
  position: relative;
  padding-right: 20px;
}
.dlab-card.style-1 .dlab-meta ul li span {
  color: #888;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  padding: 0 15px 0px 0px;
  letter-spacing: 1px;
  font-weight: 400;
}
.dlab-card.style-1 .dlab-meta ul li span:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  background: var(--primary);
  top: 9px;
  right: 13px;
}
.dlab-card.style-1 .dlab-meta ul li a {
  color: #888;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  padding: 0 10px 0px 0px;
  letter-spacing: 1px;
  font-weight: 400;
}

.dlab-card.style-2 .dlab-media img {
  border-radius: var(--border-radius-base);
}

.dlab-card.style-2 .dlab-info {
  padding: 30px 25px 20px;
}

.dlab-card.style-2 .dlab-meta ul li {
  margin-right: 10px;
}

.dlab-card.style-2 .post-date,
.dlab-card.style-2 .post-author a {
  background-color: var(--primary);
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  padding: 8px 18px;
}

.dlab-card.style-2.blog-half {
  align-items: center;
}
.dlab-card.style-2.blog-half h2.dlab-title,
.dlab-card.style-2.blog-half .dlab-title.h2 {
  font-size: 42px;
}
.dlab-card.style-2.blog-half .dlab-media {
  max-width: 60%;
  min-width: 60%;
}
.dlab-card.style-2.blog-half .dlab-info {
  padding: 0 70px 0 0;
}
.dlab-card.style-2.blog-half .dlab-info p {
  margin-bottom: 30px;
}
.dlab-card.style-2.blog-half .dlab-media + .dlab-info {
  padding: 0 0 0 70px;
}
@media only screen and (max-width: 1280px) {
  .dlab-card.style-2.blog-half h2.dlab-title,
  .dlab-card.style-2.blog-half .dlab-title.h2 {
    font-size: 36px;
  }
  .dlab-card.style-2.blog-half .dlab-media {
    max-width: 50%;
    min-width: 50%;
  }
  .dlab-card.style-2.blog-half .dlab-info {
    padding: 0 30px 0 0;
  }
  .dlab-card.style-2.blog-half .dlab-media + .dlab-info {
    padding: 0 0 0 30px;
  }
}
@media only screen and (max-width: 991px) {
  .dlab-card.style-2.blog-half {
    display: block;
  }
  .dlab-card.style-2.blog-half .dlab-media {
    max-width: 100%;
    min-width: 100%;
  }
  .dlab-card.style-2.blog-half .dlab-info {
    padding: 0 0 30px 0;
  }
  .dlab-card.style-2.blog-half .dlab-media + .dlab-info {
    padding: 30px 0 0 0;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-card.style-2.blog-half h2.dlab-title,
  .dlab-card.style-2.blog-half .dlab-title.h2 {
    font-size: 32px;
  }
}

.dlab-card.style-2.text-white .dlab-title a {
  color: #fff;
}

.banner-one {
  overflow: hidden;
}
.banner-one > .row {
  margin-left: calc(calc(100% - 1300px) / 2);
}
@media only screen and (max-width: 1400px) {
  .banner-one > .row {
    margin-left: calc(calc(100% - 1140px) / 2);
  }
}
@media only screen and (max-width: 1200px) {
  .banner-one > .row {
    margin-left: calc(calc(100% - 960px) / 2);
  }
}
@media only screen and (max-width: 991px) {
  .banner-one > .row {
    margin-left: calc(calc(100% - 720px) / 2);
    margin-right: calc(calc(100% - 720px) / 2);
  }
}
@media only screen and (max-width: 767px) {
  .banner-one > .row {
    margin-left: calc(calc(100% - 540px) / 2);
    margin-right: calc(calc(100% - 540px) / 2);
  }
}
@media only screen and (max-width: 575px) {
  .banner-one > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.banner-one .trending-box {
  display: inline-block;
  background-color: #f0f0f0;
  color: #747474;
  border-radius: 44px;
  margin-bottom: 25px;
  padding: 8px 25px 8px 8px;
  font-size: 16px;
  font-weight: 500;
}
.banner-one .trending-box .text-btn {
  background-color: #ff9446;
  color: #fff;
  border-radius: 44px;
  padding: 8px 20px;
  font-weight: 600;
  margin-right: 15px;
  display: inline-block;
}
.banner-one .banner-content {
  padding: 60px 0;
  position: relative;
}
.banner-one .banner-content .title {
  font-size: 58px;
  color: #000;
  margin-bottom: 20px;
}
.banner-one .banner-content .img1 {
  position: absolute;
  right: 25px;
  top: 70px;
}
@media only screen and (max-width: 1600px) {
  .banner-one .banner-content .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 1400px) {
  .banner-one .banner-content .title {
    font-size: 50px;
  }
  .banner-one .trending-box {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-one .banner-content {
    padding: 40px 0;
  }
  .banner-one .banner-content .title {
    font-size: 32px;
  }
  .banner-one .trending-box {
    font-size: 14px;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
  }
}

.banner-slider {
  width: 100%;
  right: 0;
  border-radius: 0 0 0 100px;
  overflow: hidden;
}
.banner-slider .dlab-slide-item {
  position: relative;
}
.banner-slider .dlab-slide-item .silder-content {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.31) 0%,
    rgba(0, 0, 0, 0.01) 100%
  );
}
.banner-slider .dlab-slide-item .slider-img {
  height: calc(100vh - 117px);
  min-height: 780px;
}
.banner-slider .dlab-slide-item .slider-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner-slider .dlab-slide-item .inner-content {
  display: flex;
  justify-content: space-between;
  padding: 70px 70px;
  color: #fff;
}
.banner-slider .dlab-slide-item .inner-content .right {
  text-align: right;
}
.banner-slider .dlab-slide-item .inner-content .car-type {
  font-weight: 600;
  margin-bottom: 0;
}
.banner-slider .dlab-slide-item .inner-content .car-name {
  font-size: 36px;
  font-weight: 300;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0.5s;
  -o-transition: opacity 0.5s, visibility 0.5s;
}
.banner-slider .dlab-slide-item .inner-content .price-label {
  margin-bottom: 0;
}
.banner-slider .dlab-slide-item .inner-content .car-label {
  margin-bottom: 0;
}
.banner-slider .dlab-slide-item .inner-content .car-price {
  font-size: 36px;
  perspective: 500px;
  transform-style: preserve-3d;
  font-weight: 600;
}
.banner-slider .dlab-slide-item .discover-link {
  position: absolute;
  bottom: 70px;
  left: 70px;
  color: #fff;
  letter-spacing: 5px;
}
.banner-slider .dlab-slide-item .discover-link:before {
  content: ">";
  font: inherit;
  vertical-align: 0.1em;
  display: inline-block;
  transform: scaleY(0) translateX(-1em);
  margin-right: -0.25em;
}
.banner-slider .dlab-slide-item .discover-link:before,
.banner-slider .dlab-slide-item .discover-link .char {
  transition: transform 0.8s cubic-bezier(0.75, 0, 0.24, 0.98);
  transition-delay: calc(0.015s * var(--char-index));
}
.banner-slider .dlab-slide-item .discover-link .char {
  transform: translateX(-0.15em);
}
.banner-slider .dlab-slide-item .discover-link:hover:before {
  transform: scaleY(1) translateX(0em);
}
.banner-slider .dlab-slide-item .discover-link:hover .char {
  transform: translateX(1em);
  transform: translateX(0.5em) translateX(calc(0.1em * var(--char-index)));
}
.banner-slider .slider-one-pagination {
  position: absolute;
  right: 70px;
  bottom: 70px;
}
.banner-slider .slider-one-pagination .btn-next,
.banner-slider .slider-one-pagination .btn-prev {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
}
.banner-slider .slider-one-pagination .btn-next i,
.banner-slider .slider-one-pagination .btn-prev i {
  font-size: 26px;
}
.banner-slider .slider-one-pagination .btn-next:hover,
.banner-slider .slider-one-pagination .btn-prev:hover {
  color: #fff;
}
.banner-slider .swiper-slide-active .dlab-slide-item .inner-content .car-name {
  webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.banner-slider
  .swiper-slide-active
  .dlab-slide-item
  .inner-content
  .car-name
  .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}
.banner-slider
  .swiper-slide-active
  .dlab-slide-item
  .inner-content
  .car-price
  .char {
  animation: plop 2s ease;
  animation-delay: calc(0.05s * var(--char-index));
}
@media only screen and (max-width: 1400px) {
  .banner-slider .dlab-slide-item .inner-content {
    padding: 50px 50px;
  }
  .banner-slider .dlab-slide-item .inner-content .car-name,
  .banner-slider .dlab-slide-item .inner-content .car-price {
    font-size: 30px;
  }
  .banner-slider .dlab-slide-item .discover-link {
    left: 50px;
  }
  .banner-slider .slider-one-pagination {
    right: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-slider {
    width: 100%;
    border-radius: 50px;
  }
  .banner-slider .dlab-slide-item .slider-img {
    min-height: unset;
    height: 550px;
  }
  .banner-slider .dlab-slide-item .discover-link {
    bottom: 50px;
  }
  .banner-slider .slider-one-pagination {
    bottom: 45px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-slider {
    border-radius: 30px;
  }
  .banner-slider .dlab-slide-item .inner-content {
    padding: 20px 20px;
  }
  .banner-slider .dlab-slide-item .inner-content .car-type,
  .banner-slider .dlab-slide-item .inner-content .price-label {
    font-size: 14px;
  }
  .banner-slider .dlab-slide-item .inner-content .car-name,
  .banner-slider .dlab-slide-item .inner-content .car-price {
    font-size: 20px;
  }
  .banner-slider .dlab-slide-item .slider-img {
    height: 350px;
  }
  .banner-slider .dlab-slide-item .discover-link {
    left: 20px;
    bottom: 20px;
    font-size: 12px;
  }
  .banner-slider .slider-one-pagination {
    bottom: 15px;
    right: 20px;
  }
  .banner-slider .slider-one-pagination .btn-next,
  .banner-slider .slider-one-pagination .btn-prev {
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin: 0 5px;
  }
  .banner-slider .slider-one-pagination .btn-next i,
  .banner-slider .slider-one-pagination .btn-prev i {
    font-size: 20px;
  }
}

.social-list.style-1 li {
  display: inline-block;
}
.social-list.style-1 li a {
  color: var(--primary);
  opacity: 0.5;
  margin-right: 18px;
}
.social-list.style-1 li i {
  font-size: 20px;
}
.social-list.style-1 li:hover a {
  opacity: 1;
}

.car-search-box {
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 31px 81px rgba(0, 17, 77, 0.2);
  border-radius: 12px;
  padding: 15px 0;
  position: relative;
  z-index: 5;
  margin: 40px 0;
  margin-right: -140px;
}
.car-search-box.sp15 {
  padding: 15px 5px;
}
.car-search-box .selected-box .form-select {
  font-weight: 600;
  height: 52px;
  border: 0;
  text-transform: uppercase;
}
.car-search-box .selected-box .form-control,
.car-search-box .selected-box .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .car-search-box .selected-box select,
.car-search-box .selected-box .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .car-search-box .selected-box select,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-author
  input,
.comment-respond
  .comment-form
  p.comment-form-author
  .car-search-box
  .selected-box
  input,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-email
  input,
.comment-respond
  .comment-form
  p.comment-form-email
  .car-search-box
  .selected-box
  input,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-url
  input,
.comment-respond
  .comment-form
  p.comment-form-url
  .car-search-box
  .selected-box
  input,
.car-search-box .selected-box .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .car-search-box .selected-box textarea {
  background-color: transparent;
}
.car-search-box .selected-box .form-control .btn,
.car-search-box .selected-box .wp-block-categories-dropdown select .btn,
.wp-block-categories-dropdown .car-search-box .selected-box select .btn,
.car-search-box .selected-box .wp-block-archives-dropdown select .btn,
.wp-block-archives-dropdown .car-search-box .selected-box select .btn,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-author
  input
  .btn,
.comment-respond
  .comment-form
  p.comment-form-author
  .car-search-box
  .selected-box
  input
  .btn,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-email
  input
  .btn,
.comment-respond
  .comment-form
  p.comment-form-email
  .car-search-box
  .selected-box
  input
  .btn,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-url
  input
  .btn,
.comment-respond
  .comment-form
  p.comment-form-url
  .car-search-box
  .selected-box
  input
  .btn,
.car-search-box .selected-box .comment-respond .comment-form p textarea .btn,
.comment-respond .comment-form p .car-search-box .selected-box textarea .btn,
.car-search-box .selected-box .form-control .wp-block-button__link,
.car-search-box
  .selected-box
  .wp-block-categories-dropdown
  select
  .wp-block-button__link,
.wp-block-categories-dropdown
  .car-search-box
  .selected-box
  select
  .wp-block-button__link,
.car-search-box
  .selected-box
  .wp-block-archives-dropdown
  select
  .wp-block-button__link,
.wp-block-archives-dropdown
  .car-search-box
  .selected-box
  select
  .wp-block-button__link,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-author
  input
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-author
  .car-search-box
  .selected-box
  input
  .wp-block-button__link,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-email
  input
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-email
  .car-search-box
  .selected-box
  input
  .wp-block-button__link,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p.comment-form-url
  input
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-url
  .car-search-box
  .selected-box
  input
  .wp-block-button__link,
.car-search-box
  .selected-box
  .comment-respond
  .comment-form
  p
  textarea
  .wp-block-button__link,
.comment-respond
  .comment-form
  p
  .car-search-box
  .selected-box
  textarea
  .wp-block-button__link {
  background-color: transparent;
  text-transform: uppercase;
  padding: 14px 15px;
  font-weight: 500;
  font-size: 15px;
}
.car-search-box .selected-box input {
  padding: 14px 10px;
  height: auto;
  text-transform: uppercase;
  font-size: 15px;
  border: 0;
}
.car-search-box > div.border-0 .selected-box {
  border-right: 0;
}
.car-search-box .img2 {
  position: absolute;
  bottom: -30px;
  left: -60px;
  width: auto;
  height: auto;
}
.car-search-box.item2 > div {
  border-right: 2px solid #e1e1f0;
}
.car-search-box.item2 > div:nth-child(2),
.car-search-box.item2 > div:nth-child(3) {
  border-right: 0;
}
.car-search-box.item3 > div {
  border-right: 2px solid #e1e1f0;
}
.car-search-box.item3 > div:nth-child(3),
.car-search-box.item3 > div:nth-child(4) {
  border-right: 0;
}
.car-search-box.item6 .form-control,
.car-search-box.item6 .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .car-search-box.item6 select,
.car-search-box.item6 .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .car-search-box.item6 select,
.car-search-box.item6
  .comment-respond
  .comment-form
  p.comment-form-author
  input,
.comment-respond
  .comment-form
  p.comment-form-author
  .car-search-box.item6
  input,
.car-search-box.item6 .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .car-search-box.item6 input,
.car-search-box.item6 .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .car-search-box.item6 input,
.car-search-box.item6 .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .car-search-box.item6 textarea {
  height: auto;
  background-color: #ebebeb;
}
.car-search-box.item6 .selected-box {
  margin-bottom: 15px;
}
.car-search-box.item6 .fm-btn {
  margin-bottom: -50px;
}
@media only screen and (max-width: 1400px) {
  .car-search-box {
    padding: 10px 0;
  }
}
@media only screen and (max-width: 991px) {
  .car-search-box {
    margin-right: 0;
  }
  .car-search-box.item3 .selected-box {
    border-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .car-search-box .selected-box {
    border-right: 0;
  }
  .car-search-box.item2 > div {
    border-right: 0;
  }
  .car-search-box.item3 > div {
    border-right: 0;
  }
  .car-search-box .btn-lg,
  .car-search-box .btn-group-lg > .btn,
  .car-search-box .btn-group-lg > .wp-block-button__link {
    padding: 15px 30px;
  }
}
@media only screen and (max-width: 575px) {
  .car-search-box {
    margin: 25px 0;
  }
}

.tags-area {
  margin-top: 45px;
}
.tags-area p {
  color: #575757;
}
.tags-area .tag-list li {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  padding-right: 24px;
}
.tags-area .tag-list li a {
  color: #000;
}
@media only screen and (max-width: 1280px) {
  .tags-area .tag-list li {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .tags-area {
    margin-top: 20px;
  }
  .tags-area .tag-list li {
    font-size: 16px;
    padding-right: 16px;
  }
}

.features-box .feature-swiper .dlab-media {
  height: 460px;
  border-radius: 60px;
}
.features-box .feature-swiper .dlab-media img {
  height: 100%;
  object-fit: cover;
}
.features-box .feature-swiper .dlab-media:after {
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.39) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.features-box .feature-swiper .swiper-pagination {
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.features-box .feature-swiper .swiper-pagination .swiper-pagination-bullet {
  display: block;
  height: 13px;
  width: 13px;
  border-radius: 13px;
  border: 1px solid #fff;
  background: transparent;
  opacity: 1;
  margin: 10px 0;
}
.features-box
  .feature-swiper
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}

.features-box .content-box {
  padding-right: 50px;
}
.features-box .content-box.right {
  padding-right: 0;
  padding-left: 50px;
}

.features-box .image-slider-box {
  position: relative;
  border-radius: 60px;
  margin-bottom: 80px;
}
.features-box .image-slider-box:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #d2d2d2;
  border-radius: inherit;
  bottom: -75px;
  transform: scale(0.85);
  left: 0;
}
.features-box .image-slider-box:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #ebebeb;
  border-radius: inherit;
  bottom: -135px;
  transform: scale(0.75);
  left: 0;
}
.features-box .image-slider-box .img1 {
  position: absolute;
  top: 40px;
  left: -40px;
  z-index: 1;
}

@media only screen and (max-width: 1400px) {
  .features-box .feature-swiper .dlab-media {
    height: 400px;
  }
  .features-box .image-slider-box {
    margin-bottom: 70px;
  }
  .features-box .image-slider-box:after {
    bottom: -70px;
  }
  .features-box .image-slider-box:before {
    bottom: -120px;
  }
}

@media only screen and (max-width: 1280px) {
  .features-box .content-box {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .features-box .content-box {
    padding-right: 0;
  }
  .features-box .content-box.right {
    padding-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .features-box .feature-swiper .dlab-media {
    height: 250px;
    border-radius: 30px;
  }
  .features-box .feature-swiper .swiper-pagination {
    right: 20px;
  }
  .features-box .image-slider-box {
    margin-bottom: 45px;
  }
  .features-box .image-slider-box:after {
    bottom: -45px;
  }
  .features-box .image-slider-box:before {
    bottom: -75px;
  }
}

.feature-list {
  display: flex;
}
.feature-list > div:not(:last-child) {
  margin-right: 30px;
}
.feature-list label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}

@media screen and (max-width: 575px) {
  .feature-list {
    display: block;
  }
  .feature-list > div:not(:last-child) {
    margin-right: 0;
  }
}

.feature-list .value {
  margin-bottom: 0;
  font-weight: 600;
}
@media only screen and (max-width: 1400px) {
  .feature-list > div:not(:last-child) {
    margin-right: 25px;
  }
}

.car-list-box {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.car-list-box .list-info {
  position: relative;
  padding: 40px 40px;
}
.car-list-box .list-info .title a .char {
  overflow: hidden;
  color: transparent;
}
.car-list-box .list-info .title a .char:after,
.car-list-box .list-info .title a .char:before {
  visibility: visible;
  color: #000;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.car-list-box .list-info .title a .char:before {
  transform: translateX(110%);
  color: var(--primary);
}
.car-list-box .list-info .title a:hover .char:before {
  transform: translateX(0%);
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.car-list-box .list-info .title a:hover .char:after {
  transform: translateX(-110%);
  transition-delay: calc(0.02s * (var(--char-index)));
}
.car-list-box .list-info .title + .car-type {
  margin-bottom: 30px;
}
.car-list-box .list-info .badge {
  position: absolute;
  top: -25px;
  right: 30px;
  background-color: var(--primary);
  padding: 12px 20px;
  font-size: 20px;
  border-radius: 30px;
  font-weight: 500;
}
.car-list-box .car-type {
  font-weight: 600;
}
.car-list-box .media-box {
  overflow: hidden;
  position: relative;
}
.car-list-box .media-box img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 100%;
}
.car-list-box .media-box .overlay-bx {
  display: flex;
  align-items: center;
  justify-content: center;
}
.car-list-box .media-box .overlay-bx .view-btn {
  cursor: pointer;
  transform: scale(0);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.car-list-box .media-box:after {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.car-list-box.overlay {
  box-shadow: none;
}
.car-list-box.overlay .media-box {
  height: 500px;
  border-radius: 30px;
}
.car-list-box.overlay .media-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.car-list-box.overlay .media-box:after {
  content: "";
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.17) 0%,
    rgba(0, 0, 0, 0.67) 100%
  );
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  opacity: 1;
}
.car-list-box.overlay .list-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
}
.car-list-box.overlay .list-info .title a .char:after,
.car-list-box.overlay .list-info .title a .char:before {
  color: #fff;
}
.car-list-box.overlay .list-info .badge {
  position: unset;
}
.car-list-box.overlay .car-type {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.255em;
}
.car-list-box.list-view {
  display: flex;
}
.car-list-box.list-view .media-box {
  width: 360px;
  float: left;
}
.car-list-box.list-view .media-box img {
  height: 100%;
  object-fit: cover;
}
.car-list-box.list-view .list-info {
  padding: 30px 30px;
  flex: 1;
}
.car-list-box.list-view .list-info .badge {
  position: unset;
}
.car-list-box.list-view .list-info .title + .car-type {
  margin-bottom: 18px;
}
@media only screen and (max-width: 767px) {
  .car-list-box.list-view {
    display: block;
  }
  .car-list-box.list-view .media-box {
    width: 100%;
    float: unset;
  }
}
@media only screen and (max-width: 575px) {
  .car-list-box.list-view .list-info {
    padding: 20px 20px;
  }
}
.car-list-box.box-sm .list-info {
  padding: 25px 20px;
}
.car-list-box.box-sm .list-info .title + .car-type {
  margin-bottom: 15px;
  font-size: 14px;
}
.car-list-box.box-sm .list-info .badge {
  padding: 10px 16px;
  font-size: 18px;
  right: 20px;
}
.car-list-box.box-sm .feature-list > div:not(:last-child) {
  margin-right: 20px;
}
.car-list-box.box-sm .feature-list label {
  font-size: 13px;
}
.car-list-box.box-sm .feature-list .value {
  font-size: 14px;
}
@media only screen and (max-width: 1400px) {
  .car-list-box.box-sm .list-info {
    padding: 25px 15px;
  }
  .car-list-box.box-sm .feature-list > div:not(:last-child) {
    margin-right: 12px;
  }
}
.car-list-box:hover {
  box-shadow: 0px 16px 35px rgba(0, 0, 0, 0.15);
}
.car-list-box:hover .media-box img {
  transform: scale(1.2);
}
.car-list-box:hover .media-box .overlay-bx {
  opacity: 1;
  visibility: visible;
}
.car-list-box:hover .media-box .overlay-bx .view-btn {
  transform: scale(1);
}
@media only screen and (max-width: 1400px) {
  .car-list-box.overlay .media-box {
    height: 440px;
  }
}
@media only screen and (max-width: 991px) {
  .car-list-box .list-info {
    padding: 20px;
  }
  .car-list-box .list-info .title {
    margin-bottom: 10px;
    font-size: 24px;
  }
  .car-list-box .list-info .badge {
    top: -20px;
    padding: 8px 16px;
    font-size: 16px;
  }
  .car-list-box .car-type {
    top: 20px;
    left: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .car-list-box.overlay .media-box {
    height: 370px;
  }
}

.call-to-action-bx {
  background-color: var(--primary);
  color: #fff;
  padding: 40px 39px;
  border-radius: 40px;
  margin-top: 110px;
  margin-bottom: 90px;
  margin-left: 0;
  margin-right: 0;
}
.call-to-action-bx .media-box {
  position: relative;
  padding-left: 70px;
}
.call-to-action-bx .media-box .main-img {
  width: 100%;
  box-shadow: 0px 49px 111px rgba(1, 19, 86, 0.28);
  border-radius: 50px;
  margin: -150px 0 -180px 0px;
  position: relative;
  z-index: 1;
  height: 470px;
  object-fit: cover;
}
.call-to-action-bx .media-box .pt-img {
  position: absolute;
  top: 0;
  left: -8px;
  z-index: 0;
}
.call-to-action-bx .media-box:after {
  content: "";
  position: absolute;
  bottom: -220px;
  z-index: 0;
  left: 0;
  background-color: var(--secondary);
  height: 222px;
  width: 222px;
  border-radius: 222px;
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}
@media only screen and (max-width: 1400px) {
  .call-to-action-bx {
    margin-top: 130px;
    margin-bottom: 113px;
  }
  .call-to-action-bx .media-box .main-img {
    height: 445px;
    margin: -160px 0px -165px;
  }
}
@media only screen and (max-width: 1200px) {
  .call-to-action-bx {
    margin-top: 90px;
    margin-bottom: 93px;
  }
  .call-to-action-bx .media-box {
    padding-left: 35px;
  }
  .call-to-action-bx .media-box .main-img {
    height: 395px;
    margin: -120px 0px -165px;
  }
  .call-to-action-bx .media-box:after {
    height: 150px;
    width: 150px;
    bottom: -200px;
  }
}
@media only screen and (max-width: 991px) {
  .call-to-action-bx {
    margin: 0 0;
    margin-bottom: 30px;
    padding: 30px 30px;
    border-radius: 15px;
  }
  .call-to-action-bx .media-box {
    display: none;
  }
}

.swiper-dots-1 .swiper-pagination {
  position: relative;
}
.swiper-dots-1 .swiper-pagination .swiper-pagination-bullet {
  height: 8px;
  width: 26px;
  background-color: #c4c4c4;
  border-radius: 40px;
  margin: 10px 10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 1;
}
.swiper-dots-1
  .swiper-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 63px;
  background-color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .swiper-dots-1 .swiper-pagination .swiper-pagination-bullet {
    margin: 5px;
  }
}

.process-wrapper > div .icon-bx-wraper {
  margin-top: 15px;
  padding: 45px 0;
}
.process-wrapper > div .icon-bx-wraper:after {
  content: "";
  position: absolute;
  right: 0;
  top: -15px;
  height: 33px;
  width: 33px;
  border-radius: 33px;
  border: 17px solid;
}
.process-wrapper > div .icon-bx-wraper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 34px);
  border-top: 6px solid;
}
@media only screen and (max-width: 767px) {
  .process-wrapper > div .icon-bx-wraper {
    margin-top: 0;
    padding: 30px 30px;
  }
  .process-wrapper > div .icon-bx-wraper:after {
    top: auto;
    bottom: 0;
    left: -15px;
  }
  .process-wrapper > div .icon-bx-wraper:before {
    height: calc(100% - 34px);
    border-top: 0;
    border-left: 6px solid;
  }
}
@media only screen and (max-width: 575px) {
  .process-wrapper > div .icon-bx-wraper:after {
    left: -7px;
    height: 20px;
    width: 20px;
    border: 10px solid;
  }
  .process-wrapper > div .icon-bx-wraper:before {
    height: calc(100% - 21px);
  }
}

.process-wrapper > div:nth-child(1) .icon-bx-wraper:before,
.process-wrapper > div:nth-child(1) .icon-bx-wraper:after {
  border-color: var(--rgba-primary-2);
}

.process-wrapper > div:nth-child(2) .icon-bx-wraper:before,
.process-wrapper > div:nth-child(2) .icon-bx-wraper:after {
  border-color: var(--rgba-primary-5);
}

.process-wrapper > div:nth-child(3) .icon-bx-wraper:before,
.process-wrapper > div:nth-child(3) .icon-bx-wraper:after {
  border-color: var(--rgba-primary-7);
}

.process-wrapper > div:nth-child(4) .icon-bx-wraper:before,
.process-wrapper > div:nth-child(4) .icon-bx-wraper:after {
  border-color: var(--primary);
}

@media only screen and (max-width: 767px) {
  .process-wrapper {
    margin-top: 0;
  }
}

.map-bx-wraper .map-img {
  max-width: 100%;
}

.map-bx-wraper {
  position: relative;
}
.map-bx-wraper .shop-location {
  position: absolute;
  height: 164px;
  width: 164px;
  border-radius: 20px;
  background: #ffffff;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 32px 76px rgba(0, 0, 0, 0.11);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.map-bx-wraper .shop-location svg path {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.map-bx-wraper .shop-location:hover,
.map-bx-wraper .shop-location.active {
  background-color: var(--primary);
}
.map-bx-wraper .shop-location:hover svg path,
.map-bx-wraper .shop-location.active svg path {
  fill: #fff;
}
.map-bx-wraper .shop-location.location1 {
  top: 14%;
  left: 10%;
  transform: translate(-10%, -14%);
}
.map-bx-wraper .shop-location.location2 {
  top: 35%;
  left: 50%;
  transform: translate(-50%, -35%);
}
.map-bx-wraper .shop-location.location3 {
  top: 14%;
  right: 10%;
  transform: translate(-10%, -14%) scale(1.2);
}
.map-bx-wraper .shop-location.location4 {
  bottom: 14%;
  left: 60%;
  transform: translate(-60%, -14%) scale(0.6);
}
@media only screen and (max-width: 991px) {
  .map-bx-wraper .shop-location {
    height: 80px;
    width: 80px;
  }
  .map-bx-wraper .shop-location svg {
    width: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .map-bx-wraper .shop-location {
    height: 50px;
    width: 50px;
    border-radius: 10px;
  }
  .map-bx-wraper .shop-location svg {
    width: 25px;
  }
}

.catagory-result-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.catagory-result-row .serch-result {
  font-weight: 400;
  color: #575757;
}
.catagory-result-row .serch-result strong {
  font-weight: 700;
}
@media only screen and (max-width: 1280px) {
  .catagory-result-row {
    display: block;
  }
  .catagory-result-row .serch-result {
    margin-bottom: 15px;
  }
}

.custom-select {
  width: auto !important;
  background-color: transparent !important;
}
.custom-select .btn,
.custom-select .wp-block-button__link {
  background-color: transparent;
  border: 2px solid var(--rgba-primary-5);
  color: var(--primary);
  font-size: 20px;
  width: auto !important;
  font-weight: 600;
  display: inline-block;
  padding: 10px 40px 10px 20px;
}

.dlab-thum-bx {
  border-radius: 24px;
  overflow: hidden;
}
.dlab-thum-bx .overlay-icon {
  width: auto;
  height: auto;
  bottom: 20px;
  right: 20px;
  left: auto;
  top: auto;
  transform: scale(0);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-thum-bx:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}
.dlab-thum-bx:hover .overlay-icon {
  transform: scale(0.6);
}
@media only screen and (max-width: 575px) {
  .dlab-thum-bx {
    border-radius: 12px;
  }
}

.product-gallery {
  padding-right: 30px;
}
.product-gallery .thumb-slider {
  margin-top: 30px;
}
.product-gallery .thumb-slider .swiper-slide {
  width: 179px;
}
.product-gallery .thumb-slider .dlab-media {
  border-radius: 24px;
  overflow: hidden;
}
@media only screen and (max-width: 1280px) {
  .product-gallery {
    padding-right: 0;
  }
  .product-gallery .thumb-slider {
    margin-top: 30px;
  }
  .product-gallery .thumb-slider .swiper-slide {
    width: 120px;
  }
}
@media only screen and (max-width: 575px) {
  .product-gallery .thumb-slider {
    margin-top: 15px;
  }
  .product-gallery .thumb-slider .swiper-slide {
    width: 85px;
  }
  .product-gallery .thumb-slider .dlab-media {
    border-radius: 12px;
  }
}

.dlab-post-title {
  margin-bottom: 40px;
}
.dlab-post-title .sub-title {
  font-weight: 400;
  color: #595959;
  letter-spacing: 0.245em;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1280px) {
  .dlab-post-title {
    margin-bottom: 30px;
  }
}

.price-btn {
  font-size: 24px;
  padding: 7px 30px;
  cursor: unset;
}

.social-list ul {
  display: inline-block;
}
.social-list ul li {
  display: inline-block;
  margin: 0 20px;
}

.social-list.style-2 ul li {
  margin: 0px 2px;
}
.social-list.style-2 ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 1px solid #fff;
  color: #fff;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.social-list.style-2 ul li a:hover {
  background-color: #fff;
  color: #000;
}

.dlab-tabs .nav-tabs {
  border: 0;
}
.dlab-tabs .nav-tabs li {
  font-size: 30px;
}
.dlab-tabs .nav-tabs li a {
  color: #c4c4c4;
  font-weight: 600;
  border-right: 2px solid #c4c4c4;
  padding-right: 25px;
  margin-right: 25px;
}
.dlab-tabs .nav-tabs li a.active {
  color: #000;
}
.dlab-tabs .nav-tabs li:last-child a {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.dlab-tabs .nav-tabs.sm li {
  font-size: 20px;
}
.dlab-tabs .nav-tabs.sm li a {
  padding-right: 18px;
  margin-right: 18px;
}

@media only screen and (max-width: 991px) {
  .dlab-tabs .nav-tabs li {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .dlab-tabs .nav-tabs li {
    font-size: 18px;
  }
  .dlab-tabs .nav-tabs li a {
    padding-right: 10px;
    margin-right: 10px;
  }
}

.dropdown-menu {
  z-index: 10000;
}

.car-gallery .card-container {
  padding-bottom: 8px;
}

.brand-logo-area .brand-logo {
  border: 1px solid #eee;
  padding: 15px 25px;
}

.product-rating-box {
  background-color: var(--primary);
  color: #fff;
  padding: 55px 55px;
  border-radius: 22px;
}
.product-rating-box .review-text {
  font-size: 24px;
  line-height: 1;
  font-weight: 200;
  margin-bottom: 25px;
}
.product-rating-box .review-text span {
  font-weight: 600;
  font-size: 144px;
}
.product-rating-box .rating-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-rating-box .item-review li {
  display: inline-block;
  margin-right: 8px;
}
.product-rating-box .item-review li i {
  color: #fff500;
  font-size: 25px;
}
.product-rating-box .item-review li:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 1400px) {
  .product-rating-box {
    padding: 30px 35px;
  }
  .product-rating-box .review-text span {
    font-size: 100px;
  }
  .product-rating-box .item-review li {
    margin-right: 2px;
  }
  .product-rating-box .item-review li i {
    font-size: 20px;
  }
}

ol.commentlist {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
ol.commentlist li {
  position: relative;
  padding: 0;
  margin-bottom: 20px;
  background: #f7f8fa;
  padding: 20px;
  border: 1px dashed #eee;
  border-radius: var(--border-radius-base);
}
ol.commentlist li img {
  float: left;
  width: 100px;
  height: auto;
  border-radius: var(--border-radius-base);
}
ol.commentlist li .comment-text {
  padding-left: 120px;
}
ol.commentlist li .star-rating {
  position: absolute;
  top: 20px;
  right: 20px;
}
ol.commentlist li .meta strong {
  font-weight: 600;
  color: #000;
  margin-right: 5px;
}
ol.commentlist li .description p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  ol.commentlist li .star-rating {
    position: unset;
  }
}
@media only screen and (max-width: 575px) {
  ol.commentlist li img {
    width: 60px;
  }
  ol.commentlist li .comment-text {
    padding-left: 78px;
  }
}

.content-box.style-1 {
  background-color: var(--primary);
  color: #fff;
  border-radius: 22px;
  padding: 30px 30px;
}
.content-box.style-1 .title {
  color: #fff;
  margin-bottom: 25px;
}
.content-box.style-1 .title span {
  display: block;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 65%;
}
.content-box.style-1 .btn,
.content-box.style-1 .wp-block-button__link {
  background-color: #fff;
  color: var(--primary);
  font-weight: 600;
}
.content-box.style-1 p {
  font-size: 18px;
}
@media only screen and (max-width: 1680px) {
  .content-box.style-1 {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 1400px) {
  .content-box.style-1 {
    padding: 30px 22px;
  }
  .content-box.style-1 .btn,
  .content-box.style-1 .wp-block-button__link {
    padding: 16px 16px;
  }
}

.content-row-wrapper > div:nth-child(odd) {
  margin-top: -100px;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .content-row-wrapper > div:nth-child(odd) {
    margin-top: 0;
  }
}

.section-head .icon-full {
  height: 140px;
  width: 140px;
  border-radius: 27px;
  text-align: center;
  line-height: 140px;
  background-color: var(--primary);
  box-shadow: 0px 29px 58px var(--rgba-primary-2);
  margin-bottom: 35px;
}
@media only screen and (max-width: 767px) {
  .section-head .icon-full {
    height: 100px;
    width: 100px;
    line-height: 100px;
    border-radius: 14px;
  }
  .section-head .icon-full svg {
    width: 50px;
  }
}

.text-effect-1 .char {
  overflow: hidden;
  color: transparent;
}
.text-effect-1 .char:after,
.text-effect-1 .char:before {
  visibility: visible;
  color: #000;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}
.text-effect-1 .char:before {
  transform: translateX(110%);
  color: var(--primary);
}

.text-effect-1:hover .char:before {
  transform: translateX(0%);
  transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
}

.text-effect-1:hover .char:after {
  transform: translateX(-110%);
  transition-delay: calc(0.02s * (var(--char-index)));
}

.rating-info {
  display: flex;
  align-items: center;
}
.rating-info .total-rating {
  min-width: 110px;
  padding-right: 30px;
}
.rating-info .total-rating .rate {
  font-size: 50px;
  font-weight: 700;
}
.rating-info .rating-body {
  flex: 1;
}
.rating-info .progress {
  height: 0.5rem;
}
.rating-info .rating-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating-info .rating-bar span {
  font-weight: 600;
}
.rating-info .rating-bar span i {
  color: #b0b0b0;
}
.rating-info .rating-bar .rating-body {
  flex: 1;
}
.rating-info .rating-bar .rating-left {
  padding-right: 12px;
}
.rating-info .rating-bar .rating-right {
  padding-left: 12px;
}

@keyframes plop {
  0% {
    opacity: 0;
    transform: translate3d(0px, 10px, 400px) rotate(180deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.8, 0.25);
  }
  20% {
    transform: translate3d(0px, -20px, 200px) rotate(90deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1.1);
  }
  40%,
  70% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) rotate(0deg);
  }
  90%,
  100% {
    opacity: 0;
    transform: translate3d(0px, 10px, -200px) rotate(-90deg);
    animation-timing-function: cubic-bezier(0.5, 0, 0.8, 0.25);
  }
}

/* Support and Buy Button */
.theme-btn {
  background-color: #ffffff;
  border-radius: 40px;
  bottom: 10px;
  color: #ffffff;
  display: flex;
  height: 50px;
  left: 10px;
  min-width: 50px;
  position: fixed;
  text-align: center;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}
.theme-btn i {
  font-size: 22px;
  line-height: 50px;
}
.theme-btn.bt-support-now {
  background-image: linear-gradient(45deg, #00bfff 8%, #2395c1 100%);
  bottom: 70px;
}
.theme-btn.bt-buy-now {
  background-image: linear-gradient(
    to right,
    #61dc6a 0,
    #2bc911 100%,
    #61dc6a 200%
  );
}
.theme-btn span {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  letter-spacing: -15px;
  opacity: 0;
  line-height: 50px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.theme-btn:focus,
.theme-btn:active,
.theme-btn:hover {
  color: #fff;
  padding: 0 20px;
}
.theme-btn:focus span,
.theme-btn:active span,
.theme-btn:hover span {
  opacity: 1;
  letter-spacing: 1px;
  padding-left: 10px;
}

.at-expanding-share-button[data-position="bottom-left"] {
  bottom: 130px !important;
}

.splitting .word,
.splitting .char {
  line-height: 1;
}

.shake {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -webkit-transform-origin: 50% 100%;
}

.used-car-features {
  display: flex;
  flex-flow: wrap;
}
.used-car-features.grid2 .car-features {
  width: 16.66%;
  outline: 1px solid rgba(0, 0, 0, 0.1);
  outline-offset: -5px;
  border: 0;
  padding: 25px 15px;
}
@media only screen and (max-width: 991px) {
  .used-car-features.grid2 .car-features {
    width: 33.33%;
  }
}
@media only screen and (max-width: 575px) {
  .used-car-features.grid2 .car-features {
    width: 50%;
  }
}
.used-car-features.grid2 .car-features svg {
  margin-bottom: 10px;
}
.used-car-features .car-features {
  width: 33.33%;
  float: left;
  padding: 15px 15px;
  text-align: center;
  border: 1px solid #eee;
  margin: 0 -1px -1px 0;
}
.used-car-features .car-features i {
  font-size: 30px;
}
.used-car-features .car-features h6,
.used-car-features .car-features .h6 {
  font-size: 16px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1400px) {
  .used-car-features .car-features span {
    font-size: 14px;
  }
}

.dlab-accordion .acod-head .acod-title {
  margin-bottom: 0;
}
.dlab-accordion .acod-head .acod-title a {
  border: 0;
  display: block;
  border-bottom: 2px solid #ffffff;
  padding: 15px 20px;
  background: #f1f1f1;
  color: var(--primary);
  position: relative;
}
.dlab-accordion .acod-head .acod-title a:after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dlab-accordion .acod-head .acod-title a.collapsed {
  color: #000;
}
.dlab-accordion .acod-head .acod-title a.collapsed:after {
  transform: translateY(-50%) rotate(180deg);
}
@media only screen and (max-width: 991px) {
  .dlab-accordion .acod-head .acod-title a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-accordion .acod-head .acod-title a {
    font-size: 16px;
  }
}

.table-dl li {
  display: flex;
  flex-flow: wrap;
}
.table-dl li div {
  width: 50%;
  float: left;
  font-weight: 500;
  background: #f3f3f3;
  padding: 12px 20px;
  border-bottom: 1px solid #fff;
}
@media only screen and (max-width: 991px) {
  .table-dl li div {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .table-dl li div {
    width: 100%;
  }
}
.table-dl li .leftview {
  font-weight: 600;
}

.table-dl .table-head {
  position: sticky;
  top: 100px;
}
.table-dl .table-head.detail-tab {
  top: 155px;
}
@media only screen and (max-width: 991px) {
  .table-dl .table-head {
    top: 80px;
  }
  .table-dl .table-head.detail-tab {
    top: 135px;
  }
}
@media only screen and (max-width: 767px) {
  .table-dl .table-head {
    position: unset;
  }
}

.table-dl.table-col4 li div {
  width: 25%;
}
@media only screen and (max-width: 767px) {
  .table-dl.table-col4 li div {
    width: 100%;
  }
}

.table-dl.table-col4 li.table-head {
  background: var(--primary-dark);
  color: #fff;
}
.table-dl.table-col4 li.table-head div {
  background: transparent;
}
.table-dl.table-col4 li.table-head div:first-child {
  background: rgba(0, 0, 0, 0.2);
}

.compare-box {
  padding: 20px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.18);
  border-radius: var(--border-radius-base);
  text-align: center;
}
.compare-box .icon-box {
  padding: 20px 10px;
  display: inline-block;
  position: relative;
}
.compare-box .icon-box svg {
  opacity: 0.5;
}
.compare-box .icon-box .plus-ico {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  color: #919191;
  height: 40px;
  width: 40px;
  line-height: 38px;
  font-size: 20px;
  border-radius: 50%;
  border: 2px solid #eee;
  border-style: dashed;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.nav-tabs.style-1 {
  border: 0;
  padding: 6px 6px;
  background-color: #ebebeb;
  border-radius: 30px;
  display: inline-block;
}
.nav-tabs.style-1 li {
  display: inline-block;
}
.nav-tabs.style-1 .nav-link {
  border-radius: 30px;
  border: 0;
  color: #666666;
  font-weight: 500;
}
.nav-tabs.style-1 .nav-link.active {
  background-color: var(--primary);
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .nav-tabs.style-1 .nav-link {
    font-size: 14px;
  }
}

.car-details-no .car-details-info {
  position: relative;
  top: 0;
}

.car-details-info {
  position: sticky;
  top: 100px;
  z-index: 9;
  background-color: #ebebeb;
}
.car-details-info .nav ul li {
  display: inline-block;
  float: left;
}
.car-details-info .nav ul li a {
  border-left: 1px solid white;
  color: #000;
  display: block;
  font-weight: 600;
  padding: 15px 20px;
}
.car-details-info .nav ul li:last-child a {
  border-right: 1px solid white;
}
.car-details-info .nav ul li.active a {
  background-color: var(--primary);
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .car-details-info {
    top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .car-details-info {
    position: unset;
  }
}

.main-slider {
  position: relative;
}

.car-searching {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.car-searching .section-head.style-1 {
  margin-bottom: 0;
}
.car-searching .section-head.style-1 .title-sm,
.car-searching .section-head.style-1 .h3 {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .car-searching {
    position: relative;
    padding-top: 30px;
  }
  .car-searching .section-head.style-1 .title-sm,
  .car-searching .section-head.style-1 .h3 {
    color: #000;
  }
}

.search-row {
  display: flex;
  align-items: flex-end;
}
.search-row label {
  text-transform: uppercase;
  margin-bottom: 10px;
}
.search-row .form-group,
.search-row .input-group {
  margin-bottom: 30px;
}

.searching-form {
  background: rgba(0, 0, 0, 0.6);
  padding: 50px 0 20px;
}

.sep-line {
  width: 40px;
  height: 3px;
  background: #d81517;
  margin-top: 35px;
}

.about-sc blockquote {
  padding: 15px 20px 15px 44px;
  margin: 0;
  background: 0 0;
  color: #fff;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
}

.about-des .icon-bx-wraper p {
  color: #999;
  font-size: 14px;
  line-height: 19px;
}

.about-des .dlab-tilte {
  color: #fff;
  font-size: 22px;
}

.text-gray-dark {
  color: #999;
}

.form-slide {
  position: absolute;
  bottom: 10%;
  width: 100%;
}

.form-head h2,
.form-head .h2 {
  font-size: 24px;
}

.search-car {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius-base);
  max-width: 380px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
}
.search-car .nav-tabs.style-1 {
  display: flex;
}
.search-car .nav-tabs.style-1 li {
  width: 50%;
  text-align: center;
}
@media only screen and (max-width: 575px) {
  .search-car {
    padding: 15px;
  }
}

.check-nav {
  justify-content: center;
}
.check-nav label {
  color: #000;
}
.check-nav > li {
  margin: 0px 15px;
}

.no-of-item {
  padding: 30px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .no-of-item {
    padding: 20px !important;
  }
}

.no-of-item-dtl {
  text-align: right;
}
.no-of-item-dtl h2,
.no-of-item-dtl .h2 {
  font-size: 115px;
  color: #000;
  opacity: 0.2;
  font-weight: 700;
  line-height: 115px;
  margin: 0;
}
.no-of-item-dtl h3,
.no-of-item-dtl .h3 {
  font-size: 26px;
  color: #000;
  font-weight: 500;
}
@media only screen and (max-width: 1280px) {
  .no-of-item-dtl h2,
  .no-of-item-dtl .h2 {
    font-size: 90px;
  }
  .no-of-item-dtl h3,
  .no-of-item-dtl .h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .no-of-item-dtl {
    text-align: center;
  }
}

.dlab-new-item .dlab-info {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 16px 20px;
  transition: all 0.5s ease 0s;
}
.dlab-new-item .dlab-info p {
  margin: 0;
  color: #636363;
}
.dlab-new-item .dlab-info .dlab-title {
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0 0;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .dlab-new-item .dlab-info .dlab-title {
    font-size: 15px;
  }
}

.dlab-new-item:hover .dlab-info {
  background-color: var(--primary);
}
.dlab-new-item:hover .dlab-info .dlab-title a,
.dlab-new-item:hover .dlab-info p {
  color: #fff;
}

.creative-banner-one {
  position: relative;
}
.creative-banner-one .swiper-slide {
  height: 100vh;
  padding-top: 100px;
}
@media only screen and (max-width: 1200px) {
  .creative-banner-one .swiper-slide {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .creative-banner-one .swiper-slide {
    padding-top: 70px;
  }
}
.creative-banner-one .bottom-aside {
  height: 150px;
  position: absolute;
  bottom: 0;
  right: 100px;
  z-index: 99;
  left: 0;
}
.creative-banner-one .bottom-aside .video-box {
  width: 250px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.creative-banner-one .bottom-aside .video-box .popup-youtube {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  color: #000;
  text-align: center;
  line-height: 43px;
}
@media only screen and (max-width: 1200px) {
  .creative-banner-one .bottom-aside {
    right: 80px;
  }
  .creative-banner-one .bottom-aside .video-box {
    width: 180px;
  }
}
@media only screen and (max-width: 575px) {
  .creative-banner-one .bottom-aside .video-box {
    display: none;
  }
}
.creative-banner-one .swiper-button-arrow {
  position: absolute;
  width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.creative-banner-one .swiper-button-arrow .swiper-button-next:before,
.creative-banner-one .swiper-button-arrow .swiper-button-prev:before {
  content: "";
  height: 2px;
  width: 100px;
  position: absolute;
  border-radius: 50px;
  background-color: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.creative-banner-one .swiper-button-arrow .swiper-button-next:after,
.creative-banner-one .swiper-button-arrow .swiper-button-prev:after {
  font-family: poppins;
  text-transform: uppercase !important;
  color: var(--primary);
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.creative-banner-one .swiper-button-arrow .swiper-button-next {
  right: 10px;
  left: auto;
}
.creative-banner-one .swiper-button-arrow .swiper-button-next:before {
  right: 50px;
}
.creative-banner-one .swiper-button-arrow .swiper-button-prev {
  left: 10px;
  right: auto;
}
.creative-banner-one .swiper-button-arrow .swiper-button-prev:before {
  left: 50px;
}
@media only screen and (max-width: 767px) {
  .creative-banner-one .swiper-button-arrow {
    left: 20px;
    width: 250px;
    transform: translate(20px, -50%);
  }
  .creative-banner-one .swiper-button-arrow .swiper-button-next:before,
  .creative-banner-one .swiper-button-arrow .swiper-button-prev:before {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .creative-banner-one .swiper-button-arrow {
    width: 230px;
  }
}

.vehicle-box.style1 {
  background-position: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.vehicle-box.style1 .left-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 80px 0 150px 80px;
  z-index: 9;
  position: relative;
}
.vehicle-box.style1 .left-info:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 500px;
  top: 0;
  left: 0;
  z-index: -1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.58+0,0+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(125, 185, 232, 0) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(125, 185, 232, 0) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(125, 185, 232, 0) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#94000000', endColorstr='#007db9e8',GradientType=1 );
  /* IE6-9 */
}
.vehicle-box.style1 .left-info:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 500px;
  top: 0;
  right: 0;
  z-index: -1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0+0,0.58+100 */
  background: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.7) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.7) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.7) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#94000000',GradientType=1 );
  /* IE6-9 */
}
.vehicle-box.style1 .left-info .bottom-info {
  border-left: 4px solid var(--primary);
  padding: 5px 0 5px 20px;
  margin-top: auto;
}
.vehicle-box.style1 .left-info .bottom-info h3,
.vehicle-box.style1 .left-info .bottom-info .h3 {
  color: #fff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
@media only screen and (max-width: 575px) {
  .vehicle-box.style1 .left-info .bottom-info h3,
  .vehicle-box.style1 .left-info .bottom-info .h3 {
    font-size: 16px;
  }
}
.vehicle-box.style1 .left-info .title {
  font-size: 80px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 0;
}
.vehicle-box.style1 .left-info .main-title {
  font-size: 28px;
  color: var(--primary);
  text-transform: uppercase;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .vehicle-box.style1 .left-info .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .vehicle-box.style1 .left-info {
    padding: 30px 0 80px 20px;
  }
  .vehicle-box.style1 .left-info .bottom-info {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .vehicle-box.style1 .left-info .title {
    font-size: 40px;
    line-height: 50px;
  }
  .vehicle-box.style1 .left-info .main-title {
    font-size: 20px;
  }
}
.vehicle-box.style1 .right-info {
  position: absolute;
  right: 100px;
  z-index: 10;
  top: 50%;
  transform: translate(-100px, -50%) !important;
}
@media only screen and (max-width: 575px) {
  .vehicle-box.style1 .right-info {
    display: none;
  }
}
.vehicle-box.style1 .vehicle-services li {
  display: block;
  padding: 20px 0;
}
.vehicle-box.style1 .vehicle-services li h3,
.vehicle-box.style1 .vehicle-services li .h3 {
  color: var(--primary);
  font-size: 28px;
  margin-bottom: 0;
  line-height: 28px;
  font-weight: 800;
}
.vehicle-box.style1 .vehicle-services li p {
  margin-bottom: 0;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
}

.swiper-pagination.style1 {
  top: 50%;
  transform: translateY(-50%) !important;
}
.swiper-pagination.style1 .swiper-pagination-bullet {
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  height: 10px;
  width: 10px;
  transition: all 0.5s;
  margin-bottom: 30px;
  position: relative;
}
.swiper-pagination.style1 .swiper-pagination-bullet:after {
  content: "";
  width: 2px;
  height: 0;
  background-color: #231e22;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s;
}
.swiper-pagination.style1
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #231e22;
  border-color: #231e22;
  margin-bottom: 150px;
  transition: all 0.5s;
  position: relative;
}
.swiper-pagination.style1
  .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  height: 100px;
}

.service-box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.service-box .dlab-info {
  padding: 30px 30px;
}
.service-box:hover {
  transform: translateY(-10px);
}

.icon-bx-xs.check-km {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #fff;
  cursor: pointer;
  color: var(--primary);
}

/*Blog single */
.blog-single {
  margin-bottom: 3.125rem;
}
.blog-single .dlab-post-meta {
  margin-bottom: 1.25rem;
}
.blog-single .dlab-post-text {
  padding-bottom: 30px;
}
.blog-single .dlab-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.blog-single .dlab-post-tags {
  margin-top: 1.25rem;
}
.blog-single .dlab-post-media {
  border-radius: 0.25rem;
}
.blog-single.dlab-card .post-tags {
  margin-bottom: 0;
}
.blog-single.style-1 {
  box-shadow: unset;
  border-radius: 0;
  overflow: unset;
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .blog-single.style-1 {
    margin-bottom: 40px;
  }
}
.blog-single.style-1 .dlab-media {
  margin: 0;
}
.blog-single.style-1 .dlab-media + .dlab-info {
  text-align: unset;
  border: 0;
  padding: 0;
  margin-top: 0;
  padding: 30px 0 0 0;
}
.blog-single.style-1 .dlab-info {
  text-align: unset;
  border: 0;
  padding: 0;
  margin-top: 0;
}
.blog-single.style-1 .dlab-info + .dlab-media {
  margin-top: 25px;
}
.blog-single.style-1 .dlab-meta ul li {
  margin-left: 0;
  margin-right: 10px;
}
.blog-single.style-1 .dlab-meta ul li:first-child {
  margin-left: 0;
}

.dlab-post-text a {
  text-decoration: underline;
  box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
}

.dlab-post-text .widget_archive li a,
.dlab-post-text .wp-block-latest-posts li a,
.dlab-post-text .wp-block-categories-list li a,
.dlab-post-text .wp-block-archives-list li a,
.dlab-post-text .widget_categories li a,
.dlab-post-text blockquote a,
.dlab-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.post-header {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}
.post-header .dlab-media img {
  min-height: 250px;
  object-fit: cover;
}
.post-header .dlab-info {
  position: absolute;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+1,000000+100&0+1,0.6+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99000000',GradientType=0 );
  /* IE6-9 */
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .post-header .dlab-info {
    padding: 40px 15px 15px 15px !important;
  }
}
.post-header .dlab-info .dlab-title {
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .post-header .dlab-info .dlab-title {
    font-size: 1.125rem;
  }
}
.post-header .dlab-info .dlab-meta > ul {
  justify-content: center;
}
.post-header .dlab-info .dlab-meta > ul > li {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

.side-bar {
  padding-left: 30px;
}
@media only screen and (max-width: 1400px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}
.side-bar.left {
  padding-left: 0;
  padding-right: 30px;
}
@media only screen and (max-width: 1400px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}
.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}
.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}
.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}
.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 3rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
}
@media only screen and (max-width: 991px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 1.25rem 0.938rem 1.25rem;
    font-size: 0.813rem;
  }
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: -0.3125rem;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.3;
}
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  content: "";
  margin-right: 0.625rem;
  width: 15px;
  height: 2px;
  background: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}
.wp-block-quote.is-large:after,
.wp-block-quote.is-style-large:after,
blockquote.wp-block-quote:after,
blockquote.wp-block-pullquote:after,
blockquote.wp-block-pullquote.alignright:after,
blockquote.wp-block-pullquote.alignleft:after,
blockquote:after {
  content: "";
  width: 6px;
  border-radius: 4px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
}
.wp-block-quote.is-large:before,
.wp-block-quote.is-style-large:before,
blockquote.wp-block-quote:before,
blockquote.wp-block-pullquote:before,
blockquote.wp-block-pullquote.alignright:before,
blockquote.wp-block-pullquote.alignleft:before,
blockquote:before {
  content: "";
  z-index: -1;
  background-size: cover;
  left: 20px;
  background-image: var(--quote);
  background-position: center right;
  width: 90px;
  padding: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  height: 90px;
  top: 0px;
  position: absolute;
}
.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}
.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}
.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #f8f8f8;
  color: var(--primary);
  padding: 25px 30px;
}
.wp-block-quote.is-large.style-1 p,
.wp-block-quote.is-style-large.style-1 p,
blockquote.wp-block-quote.style-1 p,
blockquote.wp-block-pullquote.style-1 p,
blockquote.wp-block-pullquote.alignright.style-1 p,
blockquote.wp-block-pullquote.alignleft.style-1 p,
blockquote.style-1 p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--primary);
}
.wp-block-quote.is-large.style-1:after,
.wp-block-quote.is-style-large.style-1:after,
blockquote.wp-block-quote.style-1:after,
blockquote.wp-block-pullquote.style-1:after,
blockquote.wp-block-pullquote.alignright.style-1:after,
blockquote.wp-block-pullquote.alignleft.style-1:after,
blockquote.style-1:after {
  content: none;
}
.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
  left: auto;
  background-size: contain;
  width: 80px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background-image: var(--quote-2);
}
.wp-block-quote.is-large.style-1 cite,
.wp-block-quote.is-style-large.style-1 cite,
blockquote.wp-block-quote.style-1 cite,
blockquote.wp-block-pullquote.style-1 cite,
blockquote.wp-block-pullquote.alignright.style-1 cite,
blockquote.wp-block-pullquote.alignleft.style-1 cite,
blockquote.style-1 cite {
  color: var(--title);
}
.wp-block-quote.is-large.style-1 cite:before,
.wp-block-quote.is-style-large.style-1 cite:before,
blockquote.wp-block-quote.style-1 cite:before,
blockquote.wp-block-pullquote.style-1 cite:before,
blockquote.wp-block-pullquote.alignright.style-1 cite:before,
blockquote.wp-block-pullquote.alignleft.style-1 cite:before,
blockquote.style-1 cite:before {
  background: var(--title);
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dlab-card.blog-single .dlab-post-text blockquote p,
.dlab-page-text blockquote p,
blockquote p {
  font-size: 1.5rem;
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 700;
  font-style: italic;
}
.dlab-card.blog-single .dlab-post-text blockquote p cite,
.dlab-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .dlab-card.blog-single .dlab-post-text blockquote p,
  .dlab-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}

.dlab-page-text {
  padding-bottom: 30px !important;
}

.dlab-card.blog-single .dlab-post-text blockquote.style-1 p,
.dlab-page-text blockquote.style-1 p,
blockquote.style-1 p {
  color: var(--primary);
  margin-bottom: 1.5rem;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dlab-card.blog-single,
.dlab-card.blog-single + #comment-list */
.min-container,
.dlab-page-text,
.dlab-page-text + #comment-list,
.dlab-page-text + #comment-list + .paginate-links {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dlab-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dlab-page-text + #comment-list + .paginate-links,
.dlab-page-text + #comment-list,
.dlab-page-text {
  padding: 0;
}

.dlab-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dlab-page-text > ol,
.dlab-page-text > ul,
.dlab-page-text > .wp-block-gallery,
.dlab-card.blog-single .dlab-post-text > .wp-block-gallery,
.dlab-card.blog-single .dlab-post-text > ol,
.dlab-card.blog-single .dlab-post-text > ul {
  list-style: none;
  margin-bottom: 1.875rem;
}

.dlab-page-text > ol,
.dlab-page-text > ul,
.dlab-card.blog-single .dlab-post-text > ol,
.dlab-card.blog-single .dlab-post-text > ul,
.dlab-card.blog-single .dlab-post-text.text > ol,
.dlab-card.blog-single .dlab-post-text.text > ul {
  padding-left: 1.25rem;
}
.dlab-page-text > ol ol,
.dlab-page-text > ol ul,
.dlab-page-text > ul ol,
.dlab-page-text > ul ul,
.dlab-card.blog-single .dlab-post-text > ol ol,
.dlab-card.blog-single .dlab-post-text > ol ul,
.dlab-card.blog-single .dlab-post-text > ul ol,
.dlab-card.blog-single .dlab-post-text > ul ul,
.dlab-card.blog-single .dlab-post-text.text > ol ol,
.dlab-card.blog-single .dlab-post-text.text > ol ul,
.dlab-card.blog-single .dlab-post-text.text > ul ol,
.dlab-card.blog-single .dlab-post-text.text > ul ul {
  padding-left: 1.563rem;
}

.dlab-page-text > ol li,
.dlab-page-text > ul li,
.dlab-card.blog-single .dlab-post-text > ol li,
.dlab-card.blog-single .dlab-post-text > ul li,
.dlab-card.blog-single .dlab-post-text > ol li,
.dlab-card.blog-single .dlab-post-text > ul li,
.dlab-card.blog-single .dlab-post-text.text > ol li,
.dlab-card.blog-single .dlab-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dlab-page-text > .wp-block-gallery,
.dlab-card.blog-single .dlab-post-text > .wp-block-gallery,
.dlab-card.blog-single .dlab-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dlab-page-text > .wp-block-gallery li,
.dlab-card.blog-single .dlab-post-text > .wp-block-gallery li,
.dlab-card.blog-single .dlab-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dlab-page-text > .wp-block-gallery li:before,
.dlab-card.blog-single .dlab-post-text > .wp-block-gallery li:before,
.dlab-card.blog-single .dlab-post-text.text > .wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem / 2));
  margin-right: calc(-12.5rem - (0.313rem / 2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem / 2));
  margin-right: calc(-6.25rem - (0.313rem / 2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}

.dlab-page-text h1,
.dlab-page-text .h1,
.dlab-post-text h1,
.dlab-post-text .h1,
.thm-unit-test .dlab-post-text h1,
.thm-unit-test .dlab-post-text .h1 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dlab-page-text h2,
.dlab-page-text .h2,
.dlab-post-text h2,
.dlab-post-text .h2,
.thm-unit-test .dlab-post-text h2,
.thm-unit-test .dlab-post-text .h2 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dlab-page-text h3,
.dlab-page-text .h3,
.dlab-post-text h3,
.dlab-post-text .h3,
.thm-unit-test .dlab-post-text h3,
.thm-unit-test .dlab-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dlab-page-text h4,
.dlab-page-text .h4,
.dlab-post-text h4,
.dlab-post-text .h4,
.thm-unit-test .dlab-post-text h4,
.thm-unit-test .dlab-post-text .h4 {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dlab-page-text h5,
.dlab-page-text .h5,
.dlab-post-text h5,
.dlab-post-text .h5,
.thm-unit-test .dlab-post-text h5,
.thm-unit-test .dlab-post-text .h5 {
  margin-bottom: 0.75rem;
}

.dlab-page-text h6,
.dlab-page-text .h6,
.dlab-post-text h6,
.dlab-post-text .h6,
.thm-unit-test .dlab-post-text h6,
.thm-unit-test .dlab-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.dlab-page-text,
.dlab-page-text ul li,
.dlab-page-text ol li,
.dlab-card.blog-single .dlab-post-text,
.dlab-card.blog-single .dlab-post-text ul li,
.dlab-card.blog-single .dlab-post-text ol li,
.dlab-card.blog-single
  .dlab-card.blog-single
  .dlab-post-text
  p:not(.has-text-color):not(.has-text-align-center):not(
    .has-text-align-left
  ):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dlab-page-text ul li,
.dlab-page-text ol li,
.dlab-card.blog-single .dlab-post-text ul li,
.dlab-card.blog-single .dlab-post-text ol li {
  position: relative;
}

.dlab-page-text ul > li:before,
.dlab-page-text ol > li:before,
.dlab-card.blog-single .dlab-post-text ul > li:before,
.dlab-card.blog-single .dlab-post-text ol > li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #212529;
  left: -0.938rem;
  position: absolute;
  top: 1.063rem;
  border-radius: 4px;
}

.dlab-page-text ul > li li:before,
.dlab-page-text ol > li li:before,
.dlab-card.blog-single .dlab-post-text ul > li li:before,
.dlab-card.blog-single .dlab-post-text ol > li li:before {
  content: none;
}

.dlab-page-text p,
.dlab-card.blog-single .dlab-post-text p {
  margin-bottom: 1.65rem;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}

.paginate-links a,
.paginate-links > span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #1f2471;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #e0e0e0;
  border-radius: 0.1875rem;
}

.wp-block-columns {
  margin-bottom: 0;
}

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3) 60%,
    transparent
  );
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }
  .alignwide .alignleft img {
    width: 100%;
  }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem);
  }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem);
  }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem);
  }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem);
  }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem);
  }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem);
  }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.dlab-page-text.sidebar .alignfull,
.dlab-page-text.sidebar .alignfullwide,
.dlab-page-text.sidebar .alignwide,
.dlab-page-text.sidebar .wp-block-gallery,
.blog-single.dlab-card.sidebar .alignfullwide,
.blog-single.dlab-card.sidebar .alignwide,
.blog-single.dlab-card.sidebar .dlab-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial;
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dlab-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dlab-post-text table,
.dlab-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dlab-post-text figcaption,
.dlab-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dlab-post-text table tbody tr:nth-of-type(odd),
.dlab-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dlab-post-text td,
.dlab-post-text th,
.dlab-page-text td,
.dlab-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}
.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote cite {
  color: #fff;
}
.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dlab-post-text .wp-block-cover-image-text,
.dlab-post-text .wp-block-cover-text,
.dlab-post-text section.wp-block-cover-image h2,
.dlab-post-text section.wp-block-cover-image .h2,
.dlab-page-text .wp-block-cover-image-text,
.dlab-page-text .wp-block-cover-text,
.dlab-page-text section.wp-block-cover-image h2,
.dlab-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dlab-post-text .bootstrap-select .dropdown-menu li a,
.dlab-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dlab-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-tag-cloud a {
  padding: 0.5rem 0.625rem;
  background: var(--primary);
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 0 0.3125rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
  box-shadow: none;
}
.wp-block-tag-cloud a:hover {
  background: var(--primary-hover);
}

.wp-block-latest-comments {
  padding: 0 !important;
}
.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  color: var(--title);
  font-family: "Poppins", sans-serif;
  font-size: 1.063rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 0.625rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta
  a {
  color: var(--title);
  box-shadow: none;
  text-decoration: none;
}
.wp-block-latest-comments
  img
  + article
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}
.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
}
.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dlab-page-text {
  max-width: 100%;
}

.wp-block-rss {
  padding: 0 !important;
}
.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}
.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}
.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {
  .dlab-page-text .wp-block-gallery,
  .dlab-card.blog-single .dlab-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem / 2));
    margin-right: calc(-6.25rem - (0.625rem / 2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }
  .dlab-page-text .wp-block-gallery,
  .dlab-card.blog-single .dlab-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .dlab-page-text,
  .dlab-page-text ul li,
  .dlab-page-text ol li,
  .dlab-page-text p,
  .dlab-card.blog-single .dlab-post-text,
  .dlab-card.blog-single .dlab-post-text ul li,
  .dlab-card.blog-single .dlab-post-text ol li,
  .dlab-card.blog-single .dlab-post-text p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .wp-block-media-text {
    display: block;
  }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}
.post-footer .dlab-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}
.post-footer .dlab-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}
.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #212529;
}

.extra-blog {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800;
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}
.comments-area .comment-list .default-form {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
  color: #e10000;
}
.comments-area .comment-list .dlab-page-text {
  padding-bottom: 0 !important;
}
.comments-area .comment-list > .comment .comment-body {
  position: relative;
  padding: 0 0 30px 110px;
  margin-bottom: 30px;
  min-height: 95px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body {
    padding: 0 0 20px 75px;
    margin-bottom: 20px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 95px;
  width: 95px;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 767px) {
  .comments-area
    .comment-list
    > .comment
    .comment-body
    .comment-author
    .avatar {
    height: 60px;
    width: 60px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author .fn {
  font-size: 18px;
  line-height: 18px;
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
  display: block;
}
.comments-area .comment-list > .comment .comment-body p {
  font-size: 15px;
  margin-bottom: 10px;
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  background: var(--primary);
  border-radius: 6px;
  color: #fff;
  padding: 6px 10px 4px 10px;
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link:hover {
  background: var(--primary-hover);
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link
  i {
  margin-right: 8px;
}
.comments-area .comment-list > .comment > .children {
  padding-left: 50px;
}

.comments-area.style-1 .comment-list > .comment .comment-body {
  position: relative;
  padding: 0 0 25px 110px;
  margin-bottom: 30px;
  min-height: 125px;
  border-bottom: 1px solid #e1e1f0;
}
@media only screen and (max-width: 767px) {
  .comments-area.style-1 .comment-list > .comment .comment-body {
    padding: 0 0 25px 75px;
  }
}
.comments-area.style-1
  .comment-list
  > .comment
  .comment-body
  .comment-author
  .avatar {
  border-radius: var(--border-radius-base);
}

.comments-area.style-1 .comment-list > .comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-2 .comment-list > .comment {
  border-bottom: 1px solid #e1e1f0;
  margin-bottom: 30px;
}
.comments-area.style-2
  .comment-list
  > .comment
  .comment-body
  .comment-author
  .avatar {
  border-radius: 50%;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #fff;
}
.comments-area.style-2 .comment-list > .comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.comments-area.style-3 .comment-list > .comment {
  margin-bottom: 30px;
}
.comments-area.style-3
  .comment-list
  > .comment
  .comment-body
  .comment-author
  .avatar {
  border-radius: 5px;
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  border: 5px solid #fff;
}
.comments-area.style-3 .comment-list > .comment:last-child {
  margin-bottom: 0;
}

.comment-reply-title a {
  font-size: 16px;
  font-weight: 600;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.comment-respond .comment-form p label {
  display: none;
}
.comment-respond .comment-form p textarea {
  height: 120px !important;
}
.comment-respond .comment-form .comment-form-email {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-email {
    width: 100%;
  }
}
.comment-respond .comment-form .comment-form-author {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author {
    width: 100%;
  }
}
.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond.style-1 .comment-form .comment-form-author,
.comment-respond.style-1 .comment-form .comment-form-email,
.comment-respond.style-1 .comment-form .comment-form-url,
.comment-respond.style-3 .comment-form .comment-form-author,
.comment-respond.style-3 .comment-form .comment-form-email,
.comment-respond.style-3 .comment-form .comment-form-url {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-author,
.comment-respond.style-2 .comment-form .comment-form-email {
  width: 50%;
}

.comment-respond.style-2 .comment-form .comment-form-url {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dlab-page-text [class*="galleryid-"],
.dlab-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}
.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}
.post-password-form label {
  display: block;
  font-size: 1rem;
}
.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}
.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}
.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.blog-post-banner .dlab-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
.blog-post-banner .dlab-meta {
  color: #fff;
}
.blog-post-banner .dlab-meta ul {
  display: flex;
  opacity: 0.7;
}
.blog-post-banner .dlab-meta ul li {
  margin-right: 20px;
}
.blog-post-banner .dlab-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dlab-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dlab-share-post {
  border-top: 1px solid #cccccc;
  border-top-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.dlab-share-post .dlab-social-icon {
  display: flex;
  align-items: center;
}
.dlab-share-post .dlab-social-icon .title {
  display: inline-block;
  font-size: 18px;
  padding-right: 10px;
  margin: 0;
}
.dlab-share-post .dlab-social-icon ul {
  padding: 0;
  margin: 0;
}
.dlab-share-post .dlab-social-icon ul li {
  display: inline-block;
  margin-right: 5px;
}
.dlab-share-post .dlab-social-icon ul li a {
  width: 35px;
  height: 35px;
  color: #fff;
  background: var(--primary);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  border-radius: var(--border-radius-base);
}
.dlab-share-post .dlab-social-icon ul li a:hover {
  background: var(--primary-hover);
}
@media only screen and (max-width: 575px) {
  .dlab-share-post .dlab-social-icon {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-share-post {
    display: block;
    text-align: center;
  }
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.post-swiper {
  position: relative;
}
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}
.post-swiper .prev-post-swiper-btn {
  left: 10px;
}
.post-swiper .next-post-swiper-btn {
  right: 10px;
}

.author-box {
  padding: 35px;
  background: #f3f4f6;
  border-radius: var(--border-radius);
}
.author-box .author-profile-info {
  display: flex;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info {
    display: block;
    text-align: center;
  }
}
.author-box .author-profile-info .author-profile-pic {
  border-radius: var(--border-radius-base);
  width: 120px;
  height: 120px;
  overflow: hidden;
  min-width: 120px;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-pic {
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}
.author-box .author-profile-info .author-profile-content {
  padding-left: 25px;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 25px;
}
.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: table;
  float: left;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul {
    display: inline-block;
    float: none;
    margin-right: 0;
  }
}
.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.author-box .author-profile-info .author-profile-content ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background: var(--primary);
  color: #fff;
  vertical-align: middle;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  border-radius: var(--border-radius-base);
}
.author-box .author-profile-info .author-profile-content ul li a:hover {
  background: var(--primary-hover);
}

.dlab-coming-soon {
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  z-index: 1;
  padding-left: 90px;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .dlab-coming-soon {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-coming-soon {
    padding-left: 50px;
  }
}
.dlab-coming-soon .dlab-title {
  font-size: 60px;
  line-height: 1.2;
  color: #000;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1400px) {
  .dlab-coming-soon .dlab-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media only screen and (max-width: 1280px) {
  .dlab-coming-soon .dlab-title {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-coming-soon .dlab-title {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 15px;
  }
}
.dlab-coming-soon .dlab-coming-bx {
  padding-left: 80px;
  padding-right: 80px;
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 1400px) {
  .dlab-coming-soon .dlab-coming-bx {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .dlab-coming-soon .dlab-coming-bx {
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    display: flex;
    padding: 60px 20px;
    text-align: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-coming-soon .dlab-coming-bx {
    padding: 20px 10px;
  }
}
@media only screen and (max-width: 1280px) {
  .dlab-coming-soon .btn,
  .dlab-coming-soon .wp-block-button__link {
    font-size: 13px;
    padding: 15px 20px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-coming-soon .btn,
  .dlab-coming-soon .wp-block-button__link {
    font-size: 12px;
    padding: 12px 15px;
    letter-spacing: 0;
  }
}
.dlab-coming-soon .slider-box {
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 991px) {
  .dlab-coming-soon .slider-box {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.dlab-coming-soon .slider-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.countdown {
  margin-bottom: 40px;
}
@media only screen and (max-width: 575px) {
  .countdown {
    margin-bottom: 20px;
  }
}
.countdown .date {
  color: #000;
  padding-right: 30px;
  display: inline-block;
}
@media only screen and (max-width: 1280px) {
  .countdown .date {
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .countdown .date {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown .date {
    padding-right: 5px;
    padding-left: 5px;
  }
}
.countdown .date .time {
  color: var(--primary);
  font-family: var(--font-family-title);
  font-weight: 700;
  letter-spacing: 4px;
  font-size: 90px;
  line-height: 90px;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1400px) {
  .countdown .date .time {
    font-size: 70px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .countdown .date .time {
    font-size: 50px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown .date .time {
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
  }
}
.countdown .date span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media only screen and (max-width: 1280px) {
  .countdown .date span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown .date span {
    font-size: 12px;
  }
}

.sidenav-menu {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 90px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px;
  justify-content: space-between;
}
@media only screen and (max-width: 575px) {
  .sidenav-menu {
    width: 50px;
    padding: 10px 5px 20px 5px;
  }
}
.sidenav-menu .dlab-social-icon {
  margin: 0;
}
.sidenav-menu .dlab-social-icon li a {
  color: #000;
  text-transform: capitalize;
  font-weight: 400;
  writing-mode: vertical-rl;
  padding: 10px 10px;
  margin: 0;
  display: block;
}
@media only screen and (max-width: 991px) {
  .sidenav-menu .dlab-social-icon li a {
    padding: 4px 8px;
    font-size: 14px;
  }
}

.under-construct {
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 80px;
  background-color: #f2f2f4;
  background-position: center;
  z-index: 1;
  overflow: hidden;
}
.under-construct:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../images/background/under-construct.jpg);
  background-position: center;
  background-size: cover;
}
@media only screen and (max-width: 575px) {
  .under-construct:after {
    opacity: 0.85;
  }
}
@media only screen and (max-width: 1680px) {
  .under-construct {
    padding: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construct {
    padding: 20px;
  }
}
.under-construct .logo-header {
  height: auto;
  width: 100%;
}
.under-construct .logo-header a {
  display: inline-block;
  width: 180px;
}
.under-construct .dlab-content .dlab-title {
  font-size: 90px;
  line-height: 1.2;
  margin-bottom: 10px;
  color: #212529;
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 1680px) {
  .under-construct .dlab-content .dlab-title {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .under-construct .dlab-content .dlab-title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construct .dlab-content .dlab-title {
    font-size: 40px;
  }
}
.under-construct .dlab-content p {
  font-size: 35px;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 1.2;
  color: #333;
}
@media only screen and (max-width: 1680px) {
  .under-construct .dlab-content p {
    font-size: 28px;
  }
}
@media only screen and (max-width: 1280px) {
  .under-construct .dlab-content p {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construct .dlab-content p {
    font-size: 16px;
    font-weight: 500;
  }
}
.under-construct .inner-box {
  height: 100%;
  width: 50%;
}
@media only screen and (max-width: 1280px) {
  .under-construct .inner-box {
    width: 100%;
  }
}

/* Error Page */
.error-page {
  position: relative;
}
.error-page .dz_error {
  font-size: 180px;
  font-weight: 700;
  line-height: 150px;
  margin: auto;
  color: var(--primary);
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 991px) {
  .error-page .dz_error {
    font-size: 130px;
    line-height: 1;
  }
}
@media only screen and (max-width: 767px) {
  .error-page .dz_error {
    font-size: 90px;
  }
}
@media only screen and (max-width: 575px) {
  .error-page .dz_error {
    font-size: 75px;
  }
}
.error-page .error-head {
  font-size: 32px;
  margin: 15px 0 25px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 575px) {
  .error-page .error-head {
    font-size: 18px;
    line-height: 1.4;
  }
}
.error-page:after {
  content: attr(data-text);
  position: absolute;
  color: var(--secondary);
  font-size: 18vw;
  line-height: 1;
  font-weight: 800;
  opacity: 0.05;
  z-index: -1;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-family: var(--font-family-title);
}

.map-iframe iframe {
  height: 500px;
}

.contact-area1 {
  padding: 60px 40px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  position: relative;
  z-index: 1;
  background: #fff;
}
.contact-area1 label {
  margin-bottom: 20px;
}
.contact-area1 .form-group {
  margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .contact-area1 {
    padding: 40px 15px;
  }
}

.bottom-shape {
  position: relative;
}
.bottom-shape:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 370px;
  background-color: #fff;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.sub-menu-down .active {
  position: relative;
}

.sub-menu-down .active::after {
  content: "";
  width: 100%;
  background-color: red;
  bottom: 0;
  left: 0;
  border-radius: 5px 5px 0 0;
  height: 6px;
  position: absolute;
  transition: all 0.2s;
}

.dlab-tabs .accordion-item {
  border: none !important;
}

.dlab-tabs .accordion-button {
  background-color: #f1f1f1 !important;
  border: none !important;
  margin-top: 2px;
}

.dlab-tabs .accordion-body {
  padding: 0 !important;
  margin-top: 2px;
}

.dlab-tabs .active {
  border: none;
}

.dlab-tabs .accordion-button:focus .collapsed {
  color: red;
}

.dlab-tabs .color {
  color: black !important;
}

.st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.car-search-box.sp15 {
  padding: 15px 5px;
}

.car-search-box .selected-box .form-select {
  background-color: transparent;
  text-transform: uppercase;
  padding: 14px 15px;
  font-weight: 500;
  font-size: 15px;
  color: #504f4f;
  cursor: pointer !important;
}

.form-control.sm,
.wp-block-categories-dropdown select.sm,
.wp-block-archives-dropdown select.sm,
.comment-respond .comment-form p.comment-form-author input.sm,
.comment-respond .comment-form p.comment-form-email input.sm,
.comment-respond .comment-form p.comment-form-url input.sm,
.comment-respond .comment-form p textarea.sm {
  font-size: 16px;
  background-color: #fff;
  font-weight: 500;
  color: #595c59;
  cursor: pointer !important;
}

.form-group > .form-control,
.wp-block-categories-dropdown .form-group > select,
.wp-block-archives-dropdown .form-group > select,
.comment-respond .comment-form p.comment-form-author .form-group > input,
.comment-respond .comment-form p.comment-form-email .form-group > input,
.comment-respond .comment-form p.comment-form-url .form-group > input,
.comment-respond .comment-form p .form-group > textarea {
  font-size: 18px;
  background-color: #ebebeb;
  font-weight: 500;
  color: #575757;
}

.car-search-box .selected-box .customSelect {
  background-color: #ebebeb !important;
}

#BudgetCheck,
#BrandCheck {
  cursor: pointer !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.cursorUnsate {
  cursor: unset !important;
}

.table-dl .table-head {
  background: var(--primary) !important;
}

.price-slider {
  overflow-x: hidden;
}

.range-container {
  /* Removing the default appearance */
  /* For Chrome browsers */
  /* For Firefox browsers */
}
.range-container .slider {
  position: relative;
  width: 100%;
}
.range-container .slider__track,
.range-container .slider__range,
.range-container .slider__left-value,
.range-container .slider__right-value {
  position: absolute;
}
.range-container .slider__track,
.range-container .slider__range {
  border-radius: 3px;
  height: 6px;
}
.range-container .slider__track {
  background-color: #d6d6d6;
  width: 100%;
  z-index: 1;
}
.range-container .slider__range {
  background-color: #ff0000;
  z-index: 2;
  position: relative;
}
.range-container .slider__left-value,
.range-container .slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}
.range-container .slider__left-value {
  left: 6px;
}
.range-container .slider__right-value {
  right: -4px;
}
.range-container .thumb,
.range-container .thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.range-container .thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}
.range-container .thumb--left {
  z-index: 3;
}
.range-container .thumb--right {
  z-index: 4;
}
.range-container .thumb::-webkit-slider-thumb {
  background-color: #ff0000;
  border: none;
  border-radius: 30px;
  box-shadow: 0 0 1px 1px #ff0000;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.range-container .thumb::-moz-range-thumb {
  background-color: #ff0000;
  border: none;
  border-radius: 30px;
  box-shadow: 0 0 1px 1px #ff0000;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

@media screen and (max-width: 1700) {
  .thumb {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .thumb {
    width: 700px !important;
  }
}

@media screen and (max-width: 775px) {
  .thumb {
    width: 510px !important;
  }
}

@media screen and (max-width: 575px) {
  .thumb {
    width: 95% !important;
  }
}

.rev-slider-title {
  position: absolute;
  right: 0%;
  top: 50% !important;
  transform: translate(-10%, -50%);
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 7;
  white-space: nowrap;
  color: white;
  font-weight: 700;
  visibility: inherit;
  transition: none 0s ease 0s;
  letter-spacing: 2px;
  font-size: 1.616vw;
  opacity: 1;
  font-family: Lato;
  padding-bottom: 50px;
}
.rev-slider-title > div {
  white-space: nowrap;
  font-family: Poppins, sans-serif;
  font-weight: 800;
  line-height: 120px;
  visibility: inherit;
  font-size: 8.433vw;
  color: white;
}
.rev-slider-title > .btn,
.rev-slider-title > .wp-block-button__link {
  margin-top: 30px;
  font-size: 0.984vw !important;
  font-family: sans-serif;
}

#home-slider-form {
  background-color: #1a1919cc !important;
}

@media screen and (max-width: 1200px) {
  .rev-slider-title {
    font-size: 2.616vw;
  }
  .rev-slider-title > div {
    font-size: 6.433vw;
  }
  .rev-slider-title > .btn,
  .rev-slider-title > .wp-block-button__link {
    margin-top: 10px;
    font-size: 1vw !important;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 480px) {
  .rev-slider-title {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0;
    padding-bottom: 10px;
    font-size: 3.616vw;
  }
  .rev-slider-title > div {
    font-size: 11.433vw;
    line-height: 50px;
  }
  .rev-slider-title > .btn,
  .rev-slider-title > .wp-block-button__link {
    margin-top: 10px;
    font-size: 2vw !important;
    margin-bottom: 10px;
    padding: 7px;
  }
}

#about-title {
  color: #1a1919 !important;
}

.icon-bx-wraper:hover #about-title {
  color: #ffffff !important;
}

.icon-bx-wraper:hover .text-effect-1 {
  color: #ffffff !important;
}

.borderNone {
  border: none;
}

.nav-tabs .tab-one {
  border-right-color: #dee2e6 !important;
}

.nav-tabs .tab-one:hover {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}

.nav-tabs .tab-two {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
}

.boxed {
  padding: 0px 100px;
}

.skin-1 {
  background-color: #ee3131 !important;
}

.skin-2 {
  background-color: #0d3de5 !important;
}

.skin-3 {
  background-color: #2a7e19 !important;
}

.skin-4 {
  background-color: #a10559 !important;
}

.skin-5 {
  background-color: #f77900 !important;
}

.skin-6 {
  background-color: #d7b65d !important;
}

.skin-7 {
  background-color: #22abc3 !important;
}

.skin-8 {
  background-color: #8669d4 !important;
}

.styleswitcher-inner {
  min-height: 100vh !important;
  position: absolute !important;
  top: 0 !important;
}

.switch-btn {
  background-color: white !important;
  color: var(--primary) !important;
}

.pattern-switcher {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 10px;
}
.pattern-switcher li {
  height: 36px !important;
  width: 36px !important;
}
.pattern-switcher img {
  width: 100%;
}

.masonaryContainer {
  width: 100%;
  display: flex;
}
.masonaryContainer img {
  width: 100% !important;
  display: block;
}
.masonaryContainer .figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 20px;
  break-inside: avoid;
}
.masonaryContainer .figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}
.masonaryContainer .figure a {
  color: black;
  text-decoration: none;
}
.masonaryContainer figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.2em 0.5em;
  justify-self: start;
}
.masonaryContainer .container {
  column-count: 3;
  column-gap: 20px;
  margin: auto;
}

@media screen and (max-width: 775px) {
  .masonaryContainer .container {
    column-count: 2;
  }
}

@media screen and (max-width: 580px) {
  .masonaryContainer .container {
    column-count: 1;
  }
}

.masonaryContainer2 {
  width: 100%;
  display: flex;
}
.masonaryContainer2 img {
  width: 100% !important;
  display: block;
}
.masonaryContainer2 .figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  break-inside: avoid;
}
.masonaryContainer2 .figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}
.masonaryContainer2 .figure a {
  color: black;
  text-decoration: none;
}
.masonaryContainer2 figcaption {
  grid-row: 2;
  grid-column: 1;
  background-color: rgba(255, 255, 255, 0.5);
  justify-self: start;
}
.masonaryContainer2 .container {
  column-count: 4;
  column-gap: 10px;
  margin: auto;
}

@media screen and (max-width: 775px) {
  .masonaryContainer2 .container {
    column-count: 3;
  }
}

@media screen and (max-width: 580px) {
  .masonaryContainer2 .container {
    column-count: 2;
  }
}

@media screen and (max-width: 300px) {
  .masonaryContainer2 .container {
    column-count: 1;
  }
}

.home_three_custom_pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  display: flex;
  gap: 10px;
  z-index: 999999;
}

.home_three_custom_pagination > span {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 2px solid white;
}

.home_three_custom_pagination > .swiper-pagination-bullet-active {
  width: 17px;
  height: 17px;
  background-color: white;
}

.hoverNone {
  position: relative;
}

.hoverNone:hover {
  background-color: unset;
}

.hoverNone:focus {
  background-color: unset;
  color: unset;
}

.hoverNone::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 10px;
  border-radius: 2px;
  transform: rotate(45deg) !important;
  background: #2b2a2a;
  top: 11px;
  right: 10px;
}

.hoverNone::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 10px;
  border-radius: 2px;
  transform: rotate(-45deg) !important;
  background: #2b2a2a;
  top: 11px;
  right: 16px;
  font-weight: bold;
}

.focusBtn:focus {
  color: white;
}

.form-option-select.form-select {
  font-size: 16px !important;
  border: none !important;
  background-color: white;
  line-height: 2.5;
}

.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #efbb20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 200px;
  height: 90px;
  position: relative;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .logo-header {
    width: 180px;
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header {
    width: 180px;
  }
}
.logo-header a {
  display: table-cell;
  vertical-align: middle;
}
.logo-header img {
  height: 100px;
  max-width: 200px;
  object-fit: contain;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
  .logo-header img {
    max-width: 180px;
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 180px;
  }
}
.logo-header span {
  font-size: 20px;
  letter-spacing: 20px;
}

.top-bar {
  background-color: #fff;
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}
.top-bar .dlab-topbar-left {
  float: left;
}
.top-bar .dlab-topbar-left li {
  padding-right: 20px;
}
.top-bar .dlab-topbar-right {
  float: right;
}
.top-bar .dlab-topbar-right li {
  padding-left: 15px;
}
.top-bar .dlab-social li a {
  color: inherit;
}
.top-bar .dlab-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}
.top-bar .dlab-topbar-center,
.top-bar .dlab-topbar-left,
.top-bar .dlab-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}
.top-bar .dlab-topbar-center ul,
.top-bar .dlab-topbar-left ul,
.top-bar .dlab-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-bar .dlab-topbar-center ul li,
.top-bar .dlab-topbar-left ul li,
.top-bar .dlab-topbar-right ul li {
  display: inline-block;
  font-size: 15px;
}
.top-bar .dlab-topbar-center ul i,
.top-bar .dlab-topbar-left ul i,
.top-bar .dlab-topbar-right ul i {
  margin-right: 5px;
  color: var(--primary);
}
.top-bar .dlab-topbar-center .dlab-social-icon li,
.top-bar .dlab-topbar-left .dlab-social-icon li,
.top-bar .dlab-topbar-right .dlab-social-icon li {
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.dlab-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dlab-quik-search form {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  opacity: 1;
  transform: translateY(-50%);
}
.dlab-quik-search .form-control,
.dlab-quik-search .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dlab-quik-search select,
.dlab-quik-search .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dlab-quik-search select,
.dlab-quik-search .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .dlab-quik-search input,
.dlab-quik-search .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .dlab-quik-search input,
.dlab-quik-search .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .dlab-quik-search input,
.dlab-quik-search .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dlab-quik-search textarea {
  padding: 15px 60px 15px 15px;
  width: 100%;
  height: 90px;
  border: none;
  background: 0 0;
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}
.dlab-quik-search .form-control::-webkit-input-placeholder,
.dlab-quik-search
  .wp-block-categories-dropdown
  select::-webkit-input-placeholder,
.wp-block-categories-dropdown
  .dlab-quik-search
  select::-webkit-input-placeholder,
.dlab-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dlab-quik-search select::-webkit-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dlab-quik-search
  input::-webkit-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dlab-quik-search
  input::-webkit-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dlab-quik-search
  input::-webkit-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p
  textarea::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p
  .dlab-quik-search
  textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dlab-quik-search .form-control:-ms-input-placeholder,
.dlab-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dlab-quik-search select:-ms-input-placeholder,
.dlab-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dlab-quik-search select:-ms-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dlab-quik-search
  input:-ms-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dlab-quik-search
  input:-ms-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dlab-quik-search
  input:-ms-input-placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p
  textarea:-ms-input-placeholder,
.comment-respond
  .comment-form
  p
  .dlab-quik-search
  textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dlab-quik-search .form-control::placeholder,
.dlab-quik-search .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dlab-quik-search select::placeholder,
.dlab-quik-search .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dlab-quik-search select::placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dlab-quik-search
  input::placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dlab-quik-search
  input::placeholder,
.dlab-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dlab-quik-search
  input::placeholder,
.dlab-quik-search .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .dlab-quik-search textarea::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dlab-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  color: #fff;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
}
.dlab-quik-search.On {
  top: 0;
}

.site-header {
  position: relative;
  z-index: 9999;
}
.site-header .main-bar {
  background: #fff;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}
.site-header .container {
  position: relative;
}
.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 100px;
}
@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}
@media only screen and (max-width: 1680px) {
  .site-header .extra-nav {
    margin-left: 10px;
  }
}
.site-header .extra-nav #quik-search-btn {
  color: #212529;
  cursor: pointer;
  margin-left: 15px;
  border: 0;
  width: 45px;
  height: 45px;
  background: transparent;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: 45px;
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav .btn,
  .site-header .extra-nav .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.site-header .extra-nav ul li {
  display: inline-block;
}
.site-header .extra-nav ul li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}
@media only screen and (max-width: 991px) {
  .navbar-toggler {
    margin: 15px 0 15px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .navbar-toggler {
    margin: 15px ​0 15px 15p;
  }
}

.navicon {
  width: 50px;
  height: 50px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-base);
}
.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: #fff;
  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navicon span:nth-child(1) {
  top: 15px;
  width: 30px;
}
.navicon span:nth-child(2) {
  top: 23.5px;
  width: 25px;
}
.navicon span:nth-child(3) {
  top: 33px;
  width: 20px;
}
.navicon.open span:nth-child(1) {
  top: 25px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navicon.open span:nth-child(3) {
  top: 25px;
  width: 30px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}
.header-nav .badge {
  padding: 4px 6px;
  font-size: 11px;
  font-weight: 500;
  background-color: red;
  margin-left: 10px;
}
@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    margin: 0;
    width: 280px;
  }
}
.header-nav .logo-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .header-nav .logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}
.header-nav .nav {
  float: right;
  padding: 0;
  font-size: 0;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}
.header-nav .nav > li > a {
  color: #464646;
  font-size: 16px;
  padding: 42px 15px;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1.1;
}
.header-nav .nav > li > a i {
  margin-left: 5px;
}
@media only screen and (max-width: 1680px) {
  .header-nav .nav > li > a {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media only screen and (max-width: 1400px) {
  .header-nav .nav > li > a {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
  .header-nav .nav > li > a i {
    background-color: var(--primary);
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 20px;
    margin: 0;
  }
}
.header-nav .nav > li > a:before {
  content: "";
  width: 100%;
  background-color: var(--primary);
  bottom: 0;
  left: 0;
  border-radius: 5px 5px 0 0;
  height: 0px;
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.header-nav .nav > li .mega-menu,
.header-nav .nav > li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  visibility: hidden;
  width: 215px;
  z-index: 10;
  border-radius: var(--border-radius-base);
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu,
  .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
  .header-nav .nav > li .mega-menu > li > a i,
  .header-nav .nav > li .sub-menu > li > a i {
    color: #fff !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: #aaa;
  }
}
.header-nav .nav > li .mega-menu li,
.header-nav .nav > li .sub-menu li {
  position: relative;
}
.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 14px;
  padding: 8px 25px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 500;
}
.header-nav .nav > li .mega-menu li a:after,
.header-nav .nav > li .sub-menu li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  background: var(--primary);
  left: 0px;
  top: 18px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li a,
  .header-nav .nav > li .sub-menu li a {
    padding: 2px 0 1px 16px;
    display: block;
    line-height: 30px;
    position: relative;
    /* &:after{
								content: "\f054";
								font-family: "Font Awesome 5 Free";
								width: auto;
								height: auto;
								background: 0 0!important;
								top: 3px;
								color: #262F5A;
								font-size: 12px;
								position: absolute;
								left: 0;
								font-weight: 700;
							} */
  }
}
.header-nav .nav > li .mega-menu li:hover > a,
.header-nav .nav > li .sub-menu li:hover > a {
  color: var(--primary);
  background: #f3f3f3;
  /* &:after {
							width: 10px;
						} */
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li:hover > a,
  .header-nav .nav > li .sub-menu li:hover > a {
    padding: 2px 0 1px 16px;
  }
}
.header-nav .nav > li .mega-menu li > .sub-menu,
.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 215px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li > .sub-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu .mega-menu,
  .header-nav .nav > li .mega-menu .sub-menu,
  .header-nav .nav > li .sub-menu .mega-menu,
  .header-nav .nav > li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }
  .header-nav .nav > li .mega-menu .mega-menu li a:after,
  .header-nav .nav > li .mega-menu .sub-menu li a:after,
  .header-nav .nav > li .sub-menu .mega-menu li a:after,
  .header-nav .nav > li .sub-menu .sub-menu li a:after {
    width: 5px;
    content: "";
    height: 5px;
    background: #262f5a !important;
    border-radius: 4px;
    top: 13px;
  }
}
.header-nav .nav > li .sub-menu li > a > i {
  color: inherit;
  display: block;
  float: right;
  font-size: 16px;
  opacity: 1;
  margin-top: 4px;
}
.header-nav .nav > li .mega-menu {
  display: flex;
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 0;
}
.header-nav .nav > li .mega-menu > li {
  display: block;
  padding: 30px 0 25px;
  position: relative;
  vertical-align: top;
  width: 25%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li {
    width: 100%;
    padding: 0;
  }
}
.header-nav .nav > li .mega-menu > li > a {
  color: #212529;
  display: block;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.header-nav .nav > li .mega-menu > li > a > i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li > a {
    display: none;
  }
}
.header-nav .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li:after {
    content: none;
  }
}
.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.header-nav .nav > li .mega-menu > li:hover > a {
  background-color: transparent;
}
.header-nav .nav > li .mega-menu > li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu {
    display: none;
    padding: 10px 0;
  }
}
.header-nav .nav > li:hover > .mega-menu,
.header-nav .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li:hover > .mega-menu,
  .header-nav .nav > li:hover > .sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}
.header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
.header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}
.header-nav .nav > li.open .mega-menu,
.header-nav .nav > li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
.header-nav .nav > li.open .open.menu-item-has-children > a:before {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.header-nav .nav > li.open .open .mega-menu,
.header-nav .nav > li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
@media only screen and (max-width: 1400px) {
  .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav > li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-nav .nav > li:last-child .sub-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}
.header-nav .dlab-social-icon {
  display: none;
  text-align: center;
}
.header-nav .dlab-social-icon li {
  display: inline-block;
  margin: 0 2px;
}
.header-nav .dlab-social-icon a {
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid #eee;
  line-height: 38px;
  text-align: center;
  border-radius: 40px;
}
@media only screen and (max-width: 991px) {
  .header-nav .dlab-social-icon {
    display: block;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-transparent .top-bar,
.header-transparent .main-bar {
  background: transparent;
}
.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (max-width: 1280px) {
  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.header-transparent .side-menu-btn .menu-icon-in span {
  background-color: #fff;
}
.header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
  background-color: #212529;
}
@media only screen and (min-width: 991px) {
  .header-transparent .nav > li > a {
    color: #fff;
  }
}
.header-transparent .navbar-toggler span {
  background: #fff;
}
.header-transparent .is-fixed .nav > li > a {
  color: #212529;
}
.header-transparent .is-fixed .navbar-toggler span {
  background: #212529;
}
.header-transparent .top-bar {
  border-color: #fff;
}
.header-transparent .top-bar .dlab-topbar-left li,
.header-transparent .top-bar .dlab-topbar-left i,
.header-transparent .top-bar .dlab-topbar-right li,
.header-transparent .top-bar .dlab-topbar-right i {
  color: #fff;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 25px 20px;
    width: 100% !important;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 180px;
    vertical-align: middle;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .navbar-toggler.open {
    z-index: 99;
  }
  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
}

.text-black .nav > li > a {
  color: #212529;
}

.text-black .navbar-toggler span {
  background: #212529;
}

/* Start Header is-fixed Animation */
.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

/* Header is-fixed Animation End */
.site-header.style-1 .extra-cell {
  display: flex;
  align-items: center;
}
.site-header.style-1 .extra-cell > a,
.site-header.style-1 .extra-cell > div {
  margin-left: 60px;
}
@media only screen and (max-width: 1680px) {
  .site-header.style-1 .extra-cell > a,
  .site-header.style-1 .extra-cell > div {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header.style-1 .extra-cell .login-btn {
    display: none;
  }
}
.site-header.style-1 .extra-cell .login-btn i {
  font-size: 18px;
  margin-right: 10px;
  line-height: 18px;
}

.site-header.style-1 .header-nav .nav > li {
  font-family: "Montserrat", sans-serif;
}
.site-header.style-1 .header-nav .nav > li > a {
  font-weight: 500;
}
@media only screen and (max-width: 1280px) {
  .site-header.style-1 .header-nav .nav > li > a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .header-nav .nav > li > a:before {
    content: none;
  }
}
@media only screen and (min-width: 991px) {
  .site-header.style-1 .header-nav .nav > li:last-child a:after {
    content: none;
  }
}

.site-header.style-1 .header-nav .nav > li:hover > a,
.site-header.style-1 .header-nav .nav > li.active > a {
  color: var(--primary);
}
.site-header.style-1 .header-nav .nav > li:hover > a:before,
.site-header.style-1 .header-nav .nav > li.active > a:before {
  height: 6px;
}

@media only screen and (max-width: 991px) {
  .site-header.style-1 {
    padding-top: 0;
    position: relative;
  }
}

.site-header.style-1 .phone-no {
  /* color:var(--primary);
			background-color:var(--rgba-primary-1);
			font-weight:600; */
}
.site-header.style-1 .phone-no i {
  font-size: 24px;
  margin-right: 15px;
}
@media only screen and (max-width: 1400px) {
  .site-header.style-1 .phone-no {
    font-size: 0;
    padding: 16px 20px;
  }
  .site-header.style-1 .phone-no i {
    margin-right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .phone-no {
    padding: 12px 17px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header.style-1 .phone-no {
    display: none;
  }
}

.site-header.style-1 .logo-header img {
  max-width: 181px;
}

.site-header.center .logo-header {
  position: absolute;
  transform: translate(-50%);
  -moz-transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -o-transform: translate(-50%);
  position: absolute;
  left: 50%;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .site-header.center .logo-header {
    position: unset;
    transform: unset;
    -moz-transform: unset;
    -webkit-transform: unset;
    -o-transform: unset;
    position: unset;
    left: auto;
    text-align: left;
  }
}

.site-header.center .header-nav .nav {
  display: inline-block;
  float: left;
  width: 40%;
}
@media only screen and (max-width: 991px) {
  .site-header.center .header-nav .nav {
    width: 100%;
    float: none;
  }
}
.site-header.center .header-nav .nav > li {
  display: inline-block;
}
.site-header.center .header-nav .nav.navbar-left {
  text-align: right;
  margin-right: 100px;
}
@media only screen and (max-width: 991px) {
  .site-header.center .header-nav .nav.navbar-left {
    text-align: left;
    margin-right: 0;
  }
}
.site-header.center .header-nav .nav.navbar-right {
  float: right;
  margin-left: 100px;
}
@media only screen and (max-width: 991px) {
  .site-header.center .header-nav .nav.navbar-right {
    float: none;
    margin-left: 0;
  }
}

.extra-icon-box {
  padding-left: 65px;
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: start;
  width: 270px;
  flex-direction: column;
}
@media only screen and (max-width: 1680px) {
  .extra-icon-box {
    width: 240px;
  }
}
@media only screen and (max-width: 1280px) {
  .extra-icon-box {
    display: none;
  }
}
.extra-icon-box i {
  width: 50px;
  height: 50px;
  background: var(--primary);
  text-align: center;
  line-height: 50px;
  color: #fff;
  position: absolute;
  border-radius: var(--border-radius-base);
  left: 0;
  box-shadow: 5px 5px 30px -15px var(--primary);
  font-size: 22px;
}
.extra-icon-box:hover i {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.extra-icon-box span {
  font-size: 18px;
  line-height: 1;
  margin-bottom: 5px;
}
.extra-icon-box .title {
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}
@media only screen and (max-width: 1680px) {
  .extra-icon-box .title {
    font-size: 20px;
  }
}

.menu-btn {
  background: transparent;
  width: 45px;
  height: 45px;
  box-shadow: none;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1680px) {
  .menu-btn {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .menu-btn {
    display: none;
  }
}
.menu-btn span {
  background: #212529;
  left: 10px;
}
.menu-btn span:nth-child(1) {
  top: 14px;
  width: 25px;
}
.menu-btn span:nth-child(2) {
  top: 21.5px;
  width: 20px;
}
.menu-btn span:nth-child(3) {
  top: 29px;
  width: 15px;
}
.menu-btn.open span:nth-child(1) {
  top: 22px;
}
.menu-btn.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.menu-btn.open span:nth-child(3) {
  top: 22px;
  width: 25px;
}

.contact-sidebar {
  padding: 30px;
  position: fixed;
  height: 100%;
  width: 300px;
  z-index: 99999;
  background: #fff;
  top: 0;
  left: -300px;
  overflow-y: auto;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.contact-sidebar.active {
  left: 0;
}
.contact-sidebar .logo-contact {
  margin-bottom: 40px;
  display: block;
}
.contact-sidebar .logo-contact img {
  width: 180px;
}
.contact-sidebar .dlab-title {
  margin-bottom: 10px;
}
.contact-sidebar .dlab-title h4,
.contact-sidebar .dlab-title .h4 {
  font-size: 24px;
  font-family: var(--font-family-title);
  margin-bottom: 10px;
  line-height: 1;
}
.contact-sidebar .contact-text p {
  font-weight: 500;
  font-size: 16px;
}
.contact-sidebar .icon-bx-wraper {
  margin-bottom: 20px;
}
.contact-sidebar .icon-bx-wraper .icon-md {
  padding: 0;
  margin-right: 15px;
  background: var(--primary);
  box-shadow: 0px 5px 10px 0 var(--rgba-primary-1);
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-top: 5px;
  border-radius: var(--border-radius-base);
}
.contact-sidebar .icon-bx-wraper .icon-md i {
  font-size: 28px;
}
.contact-sidebar .icon-bx-wraper .tilte {
  font-family: var(--font-family-title);
  margin-bottom: 5px;
}
.contact-sidebar .icon-bx-wraper p {
  font-size: 16px;
  font-weight: 500;
}

.menu-close {
  width: 0;
  position: fixed;
  height: 100%;
  background: #333;
  top: 0;
  opacity: 0.9;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  z-index: 99998;
}

.contact-sidebar.active + .menu-close {
  width: 100%;
}

.header-creative-one {
  position: absolute;
  width: 100%;
}
.header-creative-one .main-bar {
  background-color: #fff;
  padding-left: 80px;
  padding-right: 100px;
}
@media only screen and (max-width: 1200px) {
  .header-creative-one .main-bar {
    padding-right: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .header-creative-one .main-bar {
    padding-left: 20px;
  }
}
.header-creative-one .extra-nav {
  padding: 0 40px 0 0;
}
.header-creative-one .header-nav .nav > li .sub-menu li > a > i {
  display: inline-block;
  float: unset;
  transform: rotate(90deg);
  margin-left: 14px !important;
  font-size: 18px !important;
  background: transparent;
  color: #000 !important;
  height: auto;
  line-height: 1;
}
@media only screen and (max-width: 991px) {
  .header-creative-one .header-nav .nav > li {
    border: 0;
  }
  .header-creative-one .header-nav .nav > li > a i {
    height: auto;
    width: auto;
    color: #000;
    background-color: transparent;
    float: unset;
    padding-left: 10px;
  }
  .header-creative-one .header-nav .nav > li .sub-menu,
  .header-creative-one .header-nav .nav > li .mega-menu {
    border-top: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .header-creative-one.site-header .extra-nav {
    height: 80px;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 575px) {
  .header-creative-one.site-header .extra-nav {
    height: 70px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .header-creative-one.site-header .logo-header {
    height: 80px;
  }
  .header-creative-one.site-header .logo-header img {
    height: 80px;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 575px) {
  .header-creative-one.site-header .logo-header {
    width: 140px;
    height: 70px;
  }
  .header-creative-one.site-header .logo-header img {
    height: 70px;
    max-width: 140px;
  }
}
@media only screen and (max-width: 991px) {
  .header-creative-one .phone-no {
    font-size: 0;
  }
}
@media only screen and (max-width: 575px) {
  .header-creative-one .phone-no {
    display: none;
  }
}

/* SideNav ======= */
.header-sidenav .full-sidenav {
  position: fixed;
  right: -300px;
  width: 300px;
  top: 0;
  z-index: 999988;
  background: #fff;
  height: 100vh;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  left: auto;
  padding-top: 90px;
}

.header-sidenav .full-sidenav .mCSB_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

.header-sidenav .full-sidenav.active {
  right: 0;
}

.header-sidenav .full-sidenav .nav {
  margin-bottom: auto;
}

.header-sidenav .header-nav.full-sidenav .nav > li > a {
  color: #000;
}

.header-sidenav .header-nav.full-sidenav .nav > li > a:after {
  content: none;
}

.header-sidenav .header-nav.full-sidenav .social-menu,
.header-sidenav .header-nav.full-sidenav .logo-header,
.header-sidenav .header-nav.full-sidenav .nav {
  float: none;
  width: 100%;
}

.header-sidenav .header-nav.full-sidenav .nav {
  height: 100%;
  display: block;
}

.header-sidenav .header-nav.full-sidenav .logo-header {
  height: auto;
  padding: 30px;
}

.header-sidenav.header-nav.full-sidenav .logo-header a {
  width: 170px;
  display: block;
}

.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu,
.header-sidenav .header-nav.full-sidenav .nav > li:hover > .sub-menu,
.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu,
.header-sidenav .header-nav.full-sidenav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  position: unset;
}

.header-sidenav .header-nav.full-sidenav .nav > li > .sub-menu,
.header-sidenav .header-nav.full-sidenav .nav > li > .mega-menu {
  box-shadow: none;
  -webkit-transition: unset;
  -moz-transition: unset;
  -ms-transition: unset;
  -o-transition: unset;
  transition: unset;
}

.header-sidenav .header-nav.full-sidenav .nav > li .sub-menu {
  width: 100%;
  padding: 0;
  margin-top: 0;
  box-shadow: none;
  transition: none !important;
  padding-left: 30px;
}

.header-sidenav .header-nav.full-sidenav .nav > li .sub-menu li a {
  color: #504e4e;
  font-size: 15px;
  padding: 10px 25px;
  line-height: 1;
}

.header-sidenav .header-nav.full-sidenav .nav > li {
  width: 100%;
}
.header-sidenav .header-nav.full-sidenav .nav > li.active > a {
  color: var(--primary);
}

.header-sidenav .header-nav.full-sidenav .nav > li > a {
  padding: 12px 30px;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
}

.header-sidenav .social-menu {
  padding: 40px;
}

.header-sidenav .social-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
}

.header-sidenav .social-menu .copyright-head {
  margin-bottom: 0;
  color: #cccccc;
}

.header-sidenav .social-menu ul li {
  display: inline-block;
}

.header-sidenav .social-menu ul li a {
  color: #bbc7cd;
  font-size: 18px;
  padding-right: 15px;
}

.header-sidenav .social-menu ul li a:hover {
  color: #8799a3;
}

.header-sidenav .header-nav.full-sidenav .mCustomScrollBox {
  width: 100%;
}

.header-sidenav .menu-close i {
  color: #fff;
  font-size: 24px;
  position: absolute;
  right: 320px;
  top: 20px;
}

.header-sidenav .menu-close {
  position: fixed;
  height: 100vh;
  width: 0;
  background: #3a3a3a;
  top: 0;
  left: auto;
  right: 0;
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  filter: blur(300px);
  cursor: pointer;
  overflow: hidden;
  z-index: 9999;
}

.header-sidenav .menu-close.active {
  width: 100%;
  opacity: 0.8;
  filter: blur(0);
}

.header-sidenav .header-transparent .is-fixed .main-bar {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
}

.header-sidenav .header-full .container-fluid,
.header-sidenav .header-full .container-sm,
.header-sidenav .header-full .container-md,
.header-sidenav .header-full .container-lg,
.header-sidenav .header-full .container-xl {
  padding-left: 30px;
  padding-right: 30px;
}

.header-sidenav .site-header ul,
.site-header ol {
  margin-bottom: 0;
  list-style: none;
}

.header-sidenav .extra-nav ul li {
  list-style: none;
}

.header-sidenav .extra-nav .extra-cell {
  margin: 0;
}

.header-sidenav .menu-aside {
  right: 0;
  position: absolute;
  width: 100px;
  height: 100vh;
  z-index: 100000;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
@media only screen and (max-width: 1200px) {
  .header-sidenav .menu-aside {
    width: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .header-sidenav .menu-aside {
    width: 70px;
  }
}

.menu-aside .menu-btn {
  padding: 0;
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  margin: 0;
  background-color: var(--primary);
  border: 0;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 48;
  top: 0;
  border-radius: 0;
}
@media only screen and (max-width: 1200px) {
  .menu-aside .menu-btn {
    height: 80px;
    width: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .menu-aside .menu-btn {
    height: 70px;
    width: 70px;
  }
}

.menu-aside .menu-icon-in {
  width: 30px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
}
.menu-aside .menu-icon-in span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  background-color: #fff;
  border-radius: 10px;
}
.menu-aside .menu-icon-in span:nth-child(1) {
  top: 0;
  width: 60%;
}
.menu-aside .menu-icon-in span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.menu-aside .menu-icon-in span:nth-child(3) {
  bottom: 0;
  top: auto;
  width: 85%;
}

.header-nav.full-sidenav .nav li > .sub-menu,
.header-nav.full-sidenav .nav li:hover > .sub-menu,
.header-nav.full-sidenav .nav li > .mega-menu,
.header-nav.full-sidenav .nav li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  position: unset;
}

@media only screen and (max-width: 991px) {
  .header-sidenav .header-nav.full-sidenav .nav {
    padding: 0;
  }
}

.dlab-bnr-inr {
  background: #f8f8f8;
  height: 490px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .dlab-bnr-inr {
    height: 400px;
  }
  .dlab-bnr-inr .car-search-box .form-control .btn,
  .dlab-bnr-inr .car-search-box .wp-block-categories-dropdown select .btn,
  .wp-block-categories-dropdown .dlab-bnr-inr .car-search-box select .btn,
  .dlab-bnr-inr .car-search-box .wp-block-archives-dropdown select .btn,
  .wp-block-archives-dropdown .dlab-bnr-inr .car-search-box select .btn,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-author
    input
    .btn,
  .comment-respond
    .comment-form
    p.comment-form-author
    .dlab-bnr-inr
    .car-search-box
    input
    .btn,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-email
    input
    .btn,
  .comment-respond
    .comment-form
    p.comment-form-email
    .dlab-bnr-inr
    .car-search-box
    input
    .btn,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-url
    input
    .btn,
  .comment-respond
    .comment-form
    p.comment-form-url
    .dlab-bnr-inr
    .car-search-box
    input
    .btn,
  .dlab-bnr-inr .car-search-box .comment-respond .comment-form p textarea .btn,
  .comment-respond .comment-form p .dlab-bnr-inr .car-search-box textarea .btn,
  .dlab-bnr-inr .car-search-box .form-control .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .wp-block-categories-dropdown
    select
    .wp-block-button__link,
  .wp-block-categories-dropdown
    .dlab-bnr-inr
    .car-search-box
    select
    .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .wp-block-archives-dropdown
    select
    .wp-block-button__link,
  .wp-block-archives-dropdown
    .dlab-bnr-inr
    .car-search-box
    select
    .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-author
    input
    .wp-block-button__link,
  .comment-respond
    .comment-form
    p.comment-form-author
    .dlab-bnr-inr
    .car-search-box
    input
    .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-email
    input
    .wp-block-button__link,
  .comment-respond
    .comment-form
    p.comment-form-email
    .dlab-bnr-inr
    .car-search-box
    input
    .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p.comment-form-url
    input
    .wp-block-button__link,
  .comment-respond
    .comment-form
    p.comment-form-url
    .dlab-bnr-inr
    .car-search-box
    input
    .wp-block-button__link,
  .dlab-bnr-inr
    .car-search-box
    .comment-respond
    .comment-form
    p
    textarea
    .wp-block-button__link,
  .comment-respond
    .comment-form
    p
    .dlab-bnr-inr
    .car-search-box
    textarea
    .wp-block-button__link {
    padding: 15px 10px;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-bnr-inr {
    height: 320px;
  }
}
.dlab-bnr-inr .container {
  display: table;
  height: 100%;
}
.dlab-bnr-inr .dlab-bnr-inr-entry {
  height: 400px;
  vertical-align: bottom;
  display: table-cell;
}
@media only screen and (max-width: 991px) {
  .dlab-bnr-inr .dlab-bnr-inr-entry {
    height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-bnr-inr .dlab-bnr-inr-entry {
    text-align: center;
  }
  .dlab-bnr-inr .dlab-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}
@media only screen and (max-width: 575px) {
  .dlab-bnr-inr .dlab-bnr-inr-entry {
    height: 340px;
  }
}
.dlab-bnr-inr .dlab-separator {
  height: 10px;
  width: 128px;
  background-color: #fff;
  border-radius: 20px;
}
@media only screen and (max-width: 991px) {
  .dlab-bnr-inr .dlab-separator {
    height: 7px;
  }
}
.dlab-bnr-inr h1,
.dlab-bnr-inr .h1 {
  font-weight: 700;
  font-size: 60px;
  margin-bottom: 10px;
  text-transform: capitalize;
  line-height: 1.1;
}
@media only screen and (max-width: 991px) {
  .dlab-bnr-inr h1,
  .dlab-bnr-inr .h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-bnr-inr h1,
  .dlab-bnr-inr .h1 {
    font-size: 36px;
  }
}
.dlab-bnr-inr .car-search-box {
  margin: 30px auto;
  max-width: 900px;
}
.dlab-bnr-inr.dlab-bnr-inr-xl {
  height: 620px;
}
.dlab-bnr-inr.dlab-bnr-inr-xl .dlab-bnr-inr-entry {
  height: 620px;
}
.dlab-bnr-inr.dlab-bnr-inr-lg {
  height: 575px;
}
.dlab-bnr-inr.dlab-bnr-inr-lg .dlab-bnr-inr-entry {
  height: 575px;
}
.dlab-bnr-inr.dlab-bnr-inr-sm {
  height: 300px;
}
.dlab-bnr-inr.dlab-bnr-inr-sm .dlab-bnr-inr-entry {
  height: 300px;
}
.dlab-bnr-inr.bnr-no-img:before {
  content: none;
}

.breadcrumb-row ul {
  background: transparent;
  padding: 0;
}
.breadcrumb-row ul li {
  padding: 0;
  margin-right: 3px;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.breadcrumb-row ul li.active {
  color: #fff;
}
.breadcrumb-row ul li a {
  color: #fff;
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
  content: "|";
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  padding-right: 10px;
}

.dlab-bnr-inr.style-1 {
  overflow: visible;
  /* .breadcrumb-row{
			display: inline-table;
			ul{
				margin: 0;
				justify-content: center;
				li{
					position: relative;
					color: $secondary;
					text-transform: capitalize;
					font-size: 18px;
					font-weight: 500;
					padding-left: 25px;
					padding-right: 25px;
					margin: 0;
					@include respond('phone-land'){
						font-size: 15px;
						padding-left: 10px;
						padding-right: 10px;
					}
					a{
						color: $secondary;
					}
					&::before{
						content: "";
						height: 8px;
						width: 8px;
						background-color: var(--primary);
						padding: 0;
						position: absolute;
						top: 50%;
						left: 0;
						border-radius: 8px;
						transform: translateY(-50%);
						@include respond('phone-land'){
							height: 5px;
							width: 5px;
							left: -1px;
						}
					}
					&:first-child{
						&::before{
							content: none;
						}
					}
				}
			}
		} */
}
.dlab-bnr-inr.style-1 h1,
.dlab-bnr-inr.style-1 .h1 {
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .dlab-bnr-inr.style-1 h1,
  .dlab-bnr-inr.style-1 .h1 {
    letter-spacing: 0;
  }
}
.dlab-bnr-inr.style-1 .dlab-bnr-inr-entry {
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .dlab-bnr-inr.style-1 .dlab-media {
    display: none;
  }
  .dlab-bnr-inr.style-1 .dlab-bnr-inr-entry {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .dlab-bnr-inr.style-1 h1,
  .dlab-bnr-inr.style-1 .h1 {
    margin-bottom: 10px;
  }
}

.dlab-bnr-inr.style-2 {
  overflow: unset;
  margin-bottom: 25px;
}
.dlab-bnr-inr.style-2 .dlab-bnr-inr-entry {
  padding-top: 0;
  text-align: center;
}
.dlab-bnr-inr.style-2 h1,
.dlab-bnr-inr.style-2 .h1 {
  font-size: 80px;
}
.dlab-bnr-inr.style-2 p {
  width: 600px;
  margin: auto;
}
.dlab-bnr-inr.style-2:after {
  content: "";
  background-size: cover;
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.dlab-bnr-inr.style-2 .breadcrumb-row {
  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  display: inline-table;
  padding: 0 15px;
}
.dlab-bnr-inr.style-2 .breadcrumb-row ul {
  margin: 0;
  background-color: var(--primary);
  padding: 15px 30px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-bottom: 3px solid;
  border-color: #000 !important;
  border-radius: 0;
}
@media only screen and (max-width: 767px) {
  .dlab-bnr-inr.style-2 p {
    width: 100%;
  }
}

.dlab-bnr-inr.style-3:after {
  content: "";
  background-position: center 35%;
  background-size: cover;
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.dlab-bnr-inr.style-3 .dlab-bnr-inr-entry {
  padding-top: 0;
  text-align: center;
}

.dlab-bnr-inr.style-3 h1,
.dlab-bnr-inr.style-3 .h1 {
  font-size: 80px;
}

.dlab-bnr-inr.style-3 .breadcrumb-row ul {
  justify-content: center;
  margin-bottom: 0;
}
.dlab-bnr-inr.style-3 .breadcrumb-row ul li:last-child {
  color: var(--primary);
}

.square-bx {
  position: absolute;
  width: 170px;
  height: 130px;
  top: 50%;
  transform: translate(-25%, -50%);
  right: 25%;
  z-index: -1;
}
.square-bx:before,
.square-bx:after {
  content: "";
  position: absolute;
  background-color: var(--primary);
  border-radius: 10px;
}
.square-bx:before {
  height: 95px;
  width: 95px;
  left: 0;
  top: 0;
}
.square-bx:after {
  height: 55px;
  width: 55px;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 991px) {
  .square-bx {
    width: 100px;
    height: 80px;
    top: 40%;
    transform: translate(-25%, -40%);
  }
  .square-bx:before {
    height: 60px;
    width: 60px;
  }
  .square-bx:after {
    height: 30px;
    width: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .square-bx {
    display: none;
  }
}

.page-title {
  padding: 30px 0;
}
.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}
.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}
.breadcrumbs li a {
  color: #aeaed5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.breadcrumbs li:first-child a {
  margin-left: 0px;
}
.breadcrumbs li.active a {
  color: var(--primary);
}

.breadcrumb-row {
  padding: 40px 0;
}
.breadcrumb-row ul {
  margin-bottom: 0;
}
.breadcrumb-row ul li {
  font-size: 20px;
  color: #575757;
}
.breadcrumb-row ul li a {
  color: var(--primary);
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
  font-size: 20px;
  color: #c4c4c4;
}
@media only screen and (max-width: 575px) {
  .breadcrumb-row {
    padding: 25px 0;
  }
  .breadcrumb-row ul li {
    font-size: 18px;
  }
}

.bottom {
  background: #1b2a4e;
}
@media only screen and (max-width: 575px) {
  .bottom {
    padding-bottom: 0;
  }
}
.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
  color: #505d50;
}
@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}
.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.bottom .bottom-widget ul li a {
  color: #505d50;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}

footer {
  font-size: 18px;
  background-image: url();
}
@media only screen and (max-width: 1600px) {
  footer {
    font-size: 16px;
  }
}
footer .widget {
  margin-bottom: 30px;
}
footer strong {
  color: #fff;
}
footer .footer-logo {
  margin-bottom: 25px;
}
footer .footer-logo img {
  max-width: 100%;
}
footer .widget-logo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .widget-logo ul li {
  display: inline-block;
  flex: 50%;
  padding: 0 15px 15px 0px;
}
footer .widget_about p {
  margin-bottom: 20px;
}
footer .footer-title {
  margin: 0;
  font-weight: 700;
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
  color: #fff;
  line-height: 1.2;
}
footer .footer-title .dlab-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}
footer .widget:hover .footer-title span:after {
  width: 5px;
}
footer .widget:hover .footer-title span:before {
  width: 55px;
}
footer .footer-top {
  padding: 70px 0 20px;
}
@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding: 50px 0 0;
  }
}
footer .footer-bottom {
  background-color: #36404a;
  font-size: 15px;
  padding: 15px 0;
}
footer .footer-bottom .copyright-text a {
  color: #fff;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  footer .footer-bottom {
    text-align: center !important;
  }
  footer .footer-bottom .text-left,
  footer .footer-bottom .text-right {
    text-align: center !important;
  }
  footer .footer-bottom .text-right {
    margin-top: 10px;
  }
}
footer .widget-link li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  footer .widget-link li {
    margin-left: 8px;
    margin-right: 7px;
  }
}
footer .widget-link a {
  color: #fff;
}
footer .widget_services ul li a {
  color: #fff;
  display: block;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
footer .widget_services.style-1 ul li a {
  padding: 5px 0 5px 15px;
}
footer .widget_services.style-1 ul li a:before {
  content: "\f105";
  font-family: fontawesome;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primary);
}
footer .wp-block-latest-posts li,
footer .wp-block-categories-list li,
footer .wp-block-archives-list li,
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  left: 0;
}
footer .wp-block-latest-posts li:hover,
footer .wp-block-categories-list li:hover,
footer .wp-block-archives-list li:hover,
footer .widget_categories ul li:hover,
footer .widget_archive ul li:hover,
footer .widget_meta ul li:hover,
footer .widget_pages ul li:hover,
footer .widget_recent_comments ul li:hover,
footer .widget_nav_menu li:hover,
footer .widget_recent_entries ul li:hover,
footer .widget_services ul li:hover {
  left: 10px;
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 60px;
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li {
    margin-bottom: 20px;
    padding-left: 50px;
  }
}
.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.33;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li h5,
  .widget_getintuch ul li .h5 {
    font-size: 18px;
    line-height: 1.2;
  }
}
.widget_getintuch ul li i {
  position: absolute;
  font-size: 42px;
  line-height: 1;
  left: 0;
  top: 8px;
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li i {
    font-size: 35px;
    top: 6px;
  }
}

.contact-ft-1 {
  text-align: center;
  margin-bottom: 40px;
}
.contact-ft-1 i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
.contact-ft-1 h4,
.contact-ft-1 .h4 {
  color: #fff;
  text-decoration: underline;
}
.contact-ft-1 h5,
.contact-ft-1 .h5 {
  color: #fff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}
.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fb-link li {
  display: inline-block;
}
.fb-link li a {
  color: #fff;
  position: relative;
}
.fb-link li a:after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link li {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 0 25px 0 20px;
}
.footer-link li a {
  color: inherit;
}
.footer-link li:before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 4px;
  transform: translateY(-50%);
}

.widget_time ul li {
  display: block;
  margin-bottom: 5px;
}
.widget_time ul li label {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  margin-right: 3px;
}

.ft-clients {
  background-color: #fff;
  border-bottom: 10px solid var(--primary);
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 1;
  position: relative;
}

.ft-clients-area {
  position: relative;
}
.ft-clients-area:before {
  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
}
.ft-clients-area.bg-gray {
  background-color: transparent;
}
.ft-clients-area.bg-gray:before {
  background-color: #f7f9fb;
}

.footer-logo-head {
  border-bottom: 1px dashed #959595;
  margin-bottom: 50px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .footer-logo-head {
    margin-bottom: 30px;
    padding-bottom: 40px;
  }
}

.site-footer.style-1 {
  background-color: #fff;
}
.site-footer.style-1 .widget_categories ul li {
  padding: 8px 0 !important;
}
.site-footer.style-1 .widget_categories ul li a {
  font-size: 16px;
}
.site-footer.style-1 .widget_categories ul li a:before {
  content: none;
}
.site-footer.style-1 .widget_categories ul li:hover {
  left: 0;
}
.site-footer.style-1 .footer-logo-head {
  border-bottom: 1px solid #e1e1f0;
}
.site-footer.style-1 .footer-logo-head .icon-bx-wraper {
  float: right;
}
@media only screen and (max-width: 991px) {
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper {
    float: unset;
    justify-content: center;
  }
  .site-footer.style-1 .footer-logo-head .footer-logo {
    margin-bottom: 35px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper .icon-bx {
    line-height: 58px;
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper .icon-bx i {
    font-size: 26px;
  }
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper .title {
    font-size: 18px;
  }
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .site-footer.style-1 .footer-logo-head {
    padding-bottom: 10px;
  }
  .site-footer.style-1 .footer-logo-head .footer-logo {
    max-width: 200px;
  }
  .site-footer.style-1 .footer-logo-head .icon-bx-wraper {
    justify-content: start;
    margin-bottom: 20px;
  }
}
.site-footer.style-1 .footer-top {
  padding: 100px 0 50px;
  border-top: 1px solid #e5e5e5;
}
.site-footer.style-1 .footer-top .widget_about p {
  margin-bottom: 30px;
  font-size: 16px;
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-1 .footer-top {
    padding: 80px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-1 .footer-top .widget_categories.p-l50 {
    padding-left: 0;
  }
  .site-footer.style-1 .footer-top .widget_about p {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .site-footer.style-1 .footer-top {
    padding: 50px 0 20px;
  }
}
.site-footer.style-1 .footer-bottom {
  background-color: #fff;
  padding: 20px 0;
}
.site-footer.style-1 .footer-bottom .copyright-text {
  color: #595959;
}
.site-footer.style-1 .footer-bottom .footer-link li a {
  color: #595959;
}
@media only screen and (max-width: 991px) {
  .site-footer.style-1 .footer-bottom .text-start {
    margin-bottom: 5px;
  }
  .site-footer.style-1 .footer-bottom .text-start,
  .site-footer.style-1 .footer-bottom .text-end {
    text-align: center !important;
  }
}

.ft-subscribe .form-control,
.ft-subscribe .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .ft-subscribe select,
.ft-subscribe .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .ft-subscribe select,
.ft-subscribe .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .ft-subscribe textarea {
  height: 60px;
  background-color: #fff;
  border-radius: var(--border-radius-base) !important;
  z-index: 1 !important;
  padding: 15px 60px 15px 30px;
  color: #212621;
  border-color: #212621;
}
.ft-subscribe .form-control::-webkit-input-placeholder,
.ft-subscribe .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .ft-subscribe select::-webkit-input-placeholder,
.ft-subscribe .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .ft-subscribe select::-webkit-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .ft-subscribe
  input::-webkit-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .ft-subscribe
  input::-webkit-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .ft-subscribe
  input::-webkit-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p
  textarea::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p
  .ft-subscribe
  textarea::-webkit-input-placeholder {
  font-family: var(--font-family-title);
  color: #212621;
}
.ft-subscribe .form-control:-ms-input-placeholder,
.ft-subscribe .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .ft-subscribe select:-ms-input-placeholder,
.ft-subscribe .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .ft-subscribe select:-ms-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-author
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .ft-subscribe
  input:-ms-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-email
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .ft-subscribe
  input:-ms-input-placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-url
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .ft-subscribe
  input:-ms-input-placeholder,
.ft-subscribe .comment-respond .comment-form p textarea:-ms-input-placeholder,
.comment-respond .comment-form p .ft-subscribe textarea:-ms-input-placeholder {
  font-family: var(--font-family-title);
  color: #212621;
}
.ft-subscribe .form-control::placeholder,
.ft-subscribe .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .ft-subscribe select::placeholder,
.ft-subscribe .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .ft-subscribe select::placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .ft-subscribe
  input::placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .ft-subscribe
  input::placeholder,
.ft-subscribe
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .ft-subscribe
  input::placeholder,
.ft-subscribe .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .ft-subscribe textarea::placeholder {
  font-family: var(--font-family-title);
  color: #212621;
}

.ft-subscribe button {
  right: 5px;
  top: 5px;
  border-radius: var(--border-radius-base) !important;
  position: absolute !important;
  height: 50px;
  width: 50px;
  padding: 0;
  justify-content: center;
  line-height: 50px;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: unset;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 17, 77, 0.2);
}

.boxed .footer-fixed .site-footer {
  left: 50%;
  width: 1200px;
  margin: 0 -600px;
}

.boxed .is-fixed .main-bar {
  transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: unset;
}

.boxed .site-header.style-1 .extra-cell > a,
.boxed .site-header.style-1 .extra-cell > div {
  margin-left: 15px;
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-1 .extra-icon-box {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn i {
    margin: 0;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .extra-cell .login-btn {
    margin-left: 20px;
    width: 45px;
    height: 45px;
    padding: 12px;
  }
  .boxed .site-header.style-2 .extra-cell .login-btn span {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .site-header.style-2 .social-list.style-2 {
    display: none;
  }
}

.boxed .site-header.style-2.center .header-nav .nav.navbar-left {
  margin-right: 120px;
}

.boxed .site-header.style-2.center .header-nav .nav.navbar-right {
  margin-left: 120px;
}

.boxed .site-header.style-2.center .extra-icon-box {
  padding-left: 0;
  width: 50px;
}
.boxed .site-header.style-2.center .extra-icon-box .title,
.boxed .site-header.style-2.center .extra-icon-box span {
  display: none;
}

.boxed .site-header.style-2.center .extra-nav-left,
.boxed .site-header.style-2.center .extra-nav {
  width: 120px;
  padding: 0;
}

.boxed .site-header.style-2.center .extra-cell .login-btn {
  display: none;
}

.boxed .site-header.style-2.center .extra-cell > a,
.boxed .site-header.style-2.center .extra-cell > div {
  margin-left: 15px;
}

@media only screen and (min-width: 1200px) {
  .boxed h2,
  .boxed .h2,
  .boxed .h2 {
    font-size: 2.6rem;
  }
}

.boxed .no-of-item-dtl h2,
.boxed .no-of-item-dtl .h2,
.boxed .no-of-item-dtl .h2 {
  font-size: 80px;
}

.boxed .no-of-item-dtl h3,
.boxed .no-of-item-dtl .h3,
.boxed .no-of-item-dtl .h3 {
  font-size: 24px;
}

.boxed .form-head h2,
.boxed .form-head .h2,
.boxed .form-head .h2 {
  font-size: 24px;
}

@media only screen and (min-width: 1680px) {
  .boxed .header-nav .nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.boxed .banner-one > .row {
  margin-left: 0;
  margin-right: 0;
}

.boxed .banner-one .banner-content .title {
  font-size: 56px;
}
@media only screen and (max-width: 575px) {
  .boxed .banner-one .banner-content .title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .container-fluid,
  .boxed .container-sm,
  .boxed .container-md,
  .boxed .container-lg,
  .boxed .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct {
    padding: 50px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dlab-content .dlab-title {
    font-size: 70px;
  }
}

@media only screen and (min-width: 1680px) {
  .boxed .under-construct .dlab-content p {
    font-size: 28px;
  }
}

.boxed .about-bx1 .dlab-media {
  width: calc(100% - 20px);
}

.boxed .car-list-box.box-sm .feature-list > div:not(:last-child) {
  margin-right: 13px;
}

.boxed .side-bar {
  padding-left: 0;
}

.boxed .newsletter-bx {
  padding: 40px;
}

.boxed .portfolio-area2 .setResizeMargin {
  padding-right: 15px;
  margin-left: 0 !important;
  padding-left: 15px;
}

.boxed .sidenav-menu {
  position: absolute;
}

.boxed .dlab-coming-soon .dlab-title {
  font-size: 42px;
}

.boxed .dlab-coming-soon .countdown .date span {
  font-size: 14px;
}

.boxed .dlab-coming-soon .countdown .date .time {
  font-size: 70px;
  line-height: 70px;
}

.boxed .dlab-coming-soon .dlab-coming-bx {
  padding-left: 30px;
  padding-right: 30px;
}

/* Frame */
.frame {
  padding: 30px;
}
.frame .page-wraper {
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 30px rgba(0, 17, 77, 0.2);
  height: auto !important;
}
@media only screen and (max-width: 1680px) {
  .frame .site-header.style-1 .extra-icon-box {
    display: none;
  }
}
.frame .is-fixed .main-bar {
  left: 0;
  width: 100%;
  margin: 0;
  position: unset;
}
.frame button.scroltop {
  right: 50px;
  bottom: 50px;
}
.frame button.chatBot {
  right: 50px;
  bottom: 50px;
}

.frame .sidenav-menu {
  position: absolute;
}
@media only screen and (max-width: 1280px) {
  .frame .banner-slider .dlab-slide-item .inner-content {
    padding: 50px 35px;
  }
}
@media only screen and (max-width: 1280px) {
  .frame .banner-slider .dlab-slide-item .inner-content .car-name,
  .frame .banner-slider .dlab-slide-item .inner-content .car-price {
    font-size: 26px;
  }
}
@media only screen and (max-width: 1280px) {
  .frame .banner-slider .slider-one-pagination {
    right: 10px;
    bottom: 60px;
  }
}
@media only screen and (max-width: 1280px) {
  .frame .banner-slider .dlab-slide-item .discover-link {
    left: 30px;
  }
}
@media only screen and (max-width: 1280px) {
  .frame .header-nav .nav > li > a {
    padding-left: 7px;
    padding-right: 7px;
  }
}
@media only screen and (max-width: 1680px) {
  .frame .dlab-coming-bx {
    padding-left: 30px;
    padding-right: 30px;
  }
  .frame .countdown .date .time {
    font-size: 70px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0;
  }
  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }

  .frame button.chatBot {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-rounded {
  --border-radius-base: 7px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

[data-theme-color="color_1"] {
  --primary: #ee3131;
  --secondary: #ff9446;
  --primary-hover: #de2929;
  --primary-dark: #db2020;
  --rgba-primary-1: rgba(238, 49, 49, 0.1);
  --rgba-primary-2: rgba(238, 49, 49, 0.2);
  --rgba-primary-3: rgba(238, 49, 49, 0.3);
  --rgba-primary-4: rgba(238, 49, 49, 0.4);
  --rgba-primary-5: rgba(238, 49, 49, 0.5);
  --rgba-primary-6: rgba(238, 49, 49, 0.6);
  --rgba-primary-7: rgba(238, 49, 49, 0.7);
  --rgba-primary-8: rgba(238, 49, 49, 0.8);
  --rgba-primary-9: rgba(238, 49, 49, 0.9);
  --title: #000;
}

[data-theme-color="color_2"] {
  --primary: #0d3de5;
  --secondary: #ff9446;
  --primary-hover: #0c38d2;
  --primary-dark: #0d3d90;
  --rgba-primary-1: rgba(13, 61, 229, 0.1);
  --rgba-primary-2: rgba(13, 61, 229, 0.2);
  --rgba-primary-3: rgba(13, 61, 229, 0.3);
  --rgba-primary-4: rgba(13, 61, 229, 0.4);
  --rgba-primary-5: rgba(13, 61, 229, 0.5);
  --rgba-primary-6: rgba(13, 61, 229, 0.6);
  --rgba-primary-7: rgba(13, 61, 229, 0.7);
  --rgba-primary-8: rgba(13, 61, 229, 0.8);
  --rgba-primary-9: rgba(13, 61, 229, 0.9);
  --title: #000;
}

[data-theme-color="color_3"] {
  --primary: #2a7e19;
  --secondary: #323232;
  --primary-hover: #227d10;
  --primary-dark: #226b13;
  --rgba-primary-1: rgba(42, 126, 25, 0.1);
  --rgba-primary-2: rgba(42, 126, 25, 0.2);
  --rgba-primary-3: rgba(42, 126, 25, 0.3);
  --rgba-primary-4: rgba(42, 126, 25, 0.4);
  --rgba-primary-5: rgba(42, 126, 25, 0.5);
  --rgba-primary-6: rgba(42, 126, 25, 0.6);
  --rgba-primary-7: rgba(42, 126, 25, 0.7);
  --rgba-primary-8: rgba(42, 126, 25, 0.8);
  --rgba-primary-9: rgba(42, 126, 25, 0.9);
  --title: #212529;
}

[data-theme-color="color_4"] {
  --primary: #a10559;
  --secondary: #212529;
  --primary-hover: #bb2375;
  --primary-dark: #9c1a60;
  --rgba-primary-1: rgba(161, 5, 89, 0.1);
  --rgba-primary-2: rgba(161, 5, 89, 0.2);
  --rgba-primary-3: rgba(161, 5, 89, 0.3);
  --rgba-primary-4: rgba(161, 5, 89, 0.4);
  --rgba-primary-5: rgba(161, 5, 89, 0.5);
  --rgba-primary-6: rgba(161, 5, 89, 0.6);
  --rgba-primary-7: rgba(161, 5, 89, 0.7);
  --rgba-primary-8: rgba(161, 5, 89, 0.8);
  --rgba-primary-9: rgba(161, 5, 89, 0.9);
  --title: #212529;
}

[data-theme-color="color_5"] {
  --primary: #f77900;
  --secondary: #212529;
  --primary-hover: #e2740b;
  --primary-dark: #cc6c12;
  --rgba-primary-1: rgba(226, 116, 11, 0.1);
  --rgba-primary-2: rgba(226, 116, 11, 0.2);
  --rgba-primary-3: rgba(226, 116, 11, 0.3);
  --rgba-primary-4: rgba(226, 116, 11, 0.4);
  --rgba-primary-5: rgba(226, 116, 11, 0.5);
  --rgba-primary-6: rgba(226, 116, 11, 0.6);
  --rgba-primary-7: rgba(226, 116, 11, 0.7);
  --rgba-primary-8: rgba(226, 116, 11, 0.8);
  --rgba-primary-9: rgba(226, 116, 11, 0.9);
  --title: #212529;
}

[data-theme-color="color_6"] {
  --primary: #d7b65d;
  --secondary: #212529;
  --primary-hover: #cca744;
  --primary-dark: #9c8033;
  --rgba-primary-1: rgba(215, 182, 93, 0.1);
  --rgba-primary-2: rgba(215, 182, 93, 0.2);
  --rgba-primary-3: rgba(215, 182, 93, 0.3);
  --rgba-primary-4: rgba(215, 182, 93, 0.4);
  --rgba-primary-5: rgba(215, 182, 93, 0.5);
  --rgba-primary-6: rgba(215, 182, 93, 0.6);
  --rgba-primary-7: rgba(215, 182, 93, 0.7);
  --rgba-primary-8: rgba(215, 182, 93, 0.8);
  --rgba-primary-9: rgba(215, 182, 93, 0.9);
  --title: #212529;
}

[data-theme-color="color_7"] {
  --primary: #22abc3;
  --secondary: #212529;
  --primary-hover: #129eb7;
  --primary-dark: #21889a;
  --rgba-primary-1: rgba(34, 171, 195, 0.1);
  --rgba-primary-2: rgba(34, 171, 195, 0.2);
  --rgba-primary-3: rgba(34, 171, 195, 0.3);
  --rgba-primary-4: rgba(34, 171, 195, 0.4);
  --rgba-primary-5: rgba(34, 171, 195, 0.5);
  --rgba-primary-6: rgba(34, 171, 195, 0.6);
  --rgba-primary-7: rgba(34, 171, 195, 0.7);
  --rgba-primary-8: rgba(34, 171, 195, 0.8);
  --rgba-primary-9: rgba(34, 171, 195, 0.9);
  --title: #212529;
}

[data-theme-color="color_8"] {
  --primary: #8669d4;
  --secondary: #212529;
  --primary-hover: #7559be;
  --primary-dark: #533a97;
  --rgba-primary-1: rgba(134, 105, 212, 0.1);
  --rgba-primary-2: rgba(134, 105, 212, 0.2);
  --rgba-primary-3: rgba(134, 105, 212, 0.3);
  --rgba-primary-4: rgba(134, 105, 212, 0.4);
  --rgba-primary-5: rgba(134, 105, 212, 0.5);
  --rgba-primary-6: rgba(134, 105, 212, 0.6);
  --rgba-primary-7: rgba(134, 105, 212, 0.7);
  --rgba-primary-8: rgba(134, 105, 212, 0.8);
  --rgba-primary-9: rgba(134, 105, 212, 0.9);
  --title: #212529;
}



.content-preaprobacion {
  padding: 32px;
}
/*Formulario las cajas se les quito el fondo */
.contact-area-preaprobacion {
  padding: 15px 10px;
  border-radius: 16px;
  position: relative;
  z-index: 1;
  background: #fff;
}
.contact-area-preaprobacion label {
  margin-bottom: 0px;
  margin-top: 0;
  margin-left: 19px;
}
.contact-area-preaprobacion .form-group {
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .contact-area-preaprobacion {
    padding: 40px 15px;
  }
}


.preaprobacion {
  height: 50px;
  border: 1px solid #ebebeb;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: 400;
  color: #212621;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-radius: var(--border-radius-base);
}

.form-control.sm-preaprobacion{
  font-size: 18px;
  background-color: #fff;
  font-weight: 500;
  color: #595c59;
  cursor: pointer !important;
  /*Tamaño de la caja */
  height: 50px;
  font-size: 18px;
  padding-left: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.car-list-box-preaprobacion {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.car-list-box-preaprobacion.list-view-preaprobacion {
  display: flex;
}

.car-list-box-preaprobacion.list-view-preaprobacion .media-box {
  width: 360px;
  float: left;
}
.car-list-box-preaprobacion.list-view-preaprobacion .media-box img {
  height: 100%;
  object-fit: cover;
}
.car-list-box-preaprobacion.list-view-preaprobacion .list-info {
  padding: 30px 30px;
  flex: 1;
}

@media only screen and (max-width: 767px) {
  .car-list-box-preaprobacion.list-view-preaprobacion {
    display: block;
  }
  .car-list-box-preaprobacion.list-view-preaprobacion .media-box {
    width: 100%;
    float: unset;
  }
}

@media only screen and (max-width: 575px) {
  .car-list-box-preaprobacion.list-view-preaprobacion .list-info {
    padding: 20px 20px;
  }
}

.resumen-padding-titles{
 padding-left: 11px;
 padding-top: 40px;
}

.resumen-padding-title-2{
  padding-top: 50px;
  margin-left: -5px;
  font-size: 1.25rem;
 }

.resumen-padding-labels{
 padding-top: 15px;
}

.resumen-title-label{
  padding-top: 30px;
  font-size: 1.75rem;
 }
.resumen-padding-label{
  padding-top: 50px;
  padding-bottom: 20px;
  font-size: 1.25rem;
 }
.container-resumen-border {
  border-bottom: 2px solid black; /* Ajusta el grosor y el color según tus preferencias */
  padding-bottom: 20px; /* Ajusta según sea necesario para que el borde no quede pegado al contenido */
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3); 
}

.resumen-head {
  background-color: #000;
}
.resumen-label-color {
  color: #ffffff;
}
.resumen-row {
  margin-top: 15px;
  margin-bottom: 20px;
}
.resumen-row-1 {
  margin-top: -5px;
  margin-bottom: -5px;
}
.resumen-row-space-20{
  margin-top: 20px;
}
.resumen-row-space-30{
  margin-top: 30px;
}
.resumen-row-space{
  margin-top: 50px;
}
.resumen-padding-label-2 {
  line-height: 2; /* Ajusta el espaciado entre líneas según tus preferencias */
  width: 100%;
  font-size: 0.90rem;
  padding-bottom: 15px;
}
.resumen-padding-label-3 {
  line-height: 2; /* Ajusta el espaciado entre líneas según tus preferencias */
  width: 100%;
  font-size: 0.90rem;
  padding-top: 20px;
}
.resumen-label-formulario{
  font-size: 1.25rem;
}
.resumen-btn{
  font-size: 1.50rem;
}

.without-border{
  border: none !important;
  box-shadow: none !important;

}

.paragraph-alert-preaprobacion{
  font-size: 1rem;
  padding-top: 20px;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}


@media (max-width: 1275px) {
  .break-for-desktop {
    display: none;
  }
}

.img-focus:hover .img-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(172deg) brightness(103%) contrast(101%);
}

.img-red {
  filter: invert(11%) sepia(88%) saturate(7411%) hue-rotate(22deg) brightness(107%) contrast(129%);
}

.row-flex {
  display: flex;
  align-items: stretch;
}

.size-logo-profeco{
  width: 70%;
}

@media (max-width: 576px) {
  .size-logo-profeco {
    width: 100%;
  }
}

.background-black {
  background: #000;
}

.background-black > * {
  color: #fff;
}

.overlay-bx-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: -1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.overlay-bx-3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

